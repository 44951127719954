<template>
  <span class="branded-icon">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 61.2 (89653) - https://sketch.com -->
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Dashboard-_-Personal-_-Recordings"
          transform="translate(-905.000000, -432.000000)"
        >
          <g
            id="Content"
            transform="translate(260.000000, 108.000000)"
          >
            <g
              id="My-recordings"
              transform="translate(0.000000, 130.000000)"
            >
              <g
                id="Table"
                transform="translate(25.000000, 87.000000)"
              >
                <g
                  id="Row-2"
                  transform="translate(0.000000, 103.000000)"
                >
                  <g
                    id="Recording-type"
                    transform="translate(620.000000, 1.000000)"
                  >
                    <path
                      id="ic_download"
                      :fill="fillColor"
                      d="M15,12 C15.5128358,12 15.9355072,12.3860402 15.9932723,12.8833789 L16,13 L16,16.3333333 C16,17.7351893 14.9569582,18.9066516 13.6077694,18.9946904 L13.4444444,19 L2.55555556,19 C1.18305405,19 0.0872237498,17.8824552 0.00495943927,16.5003956 L0,16.3333333 L0,13 C0,12.4477153 0.44771525,12 1,12 C1.51283584,12 1.93550716,12.3860402 1.99327227,12.8833789 L2,13 L2,16.3333333 C2,16.6780504 2.2144377,16.9422767 2.46933131,16.9916748 L2.55555556,17 L13.4444444,17 C13.7061803,17 13.9467916,16.7702453 13.9922965,16.4450736 L14,16.3333333 L14,13 C14,12.4477153 14.4477153,12 15,12 Z M7.88337887,3.00672773 L8,3 C8.51283584,3 8.93550716,3.38604019 8.99327227,3.88337887 L9,4 L9,10.585 L11.2928932,8.29289322 C11.6533772,7.93240926 12.2206082,7.90467972 12.6128994,8.20970461 L12.7071068,8.29289322 C13.0675907,8.65337718 13.0953203,9.22060824 12.7902954,9.61289944 L12.7071068,9.70710678 L8.70710678,13.7071068 C8.34662282,14.0675907 7.77939176,14.0953203 7.38710056,13.7902954 L7.29289322,13.7071068 L3.29289322,9.70710678 C2.90236893,9.31658249 2.90236893,8.68341751 3.29289322,8.29289322 C3.65337718,7.93240926 4.22060824,7.90467972 4.61289944,8.20970461 L4.70710678,8.29289322 L7,10.585 L7,4 C7,3.48716416 7.38604019,3.06449284 7.88337887,3.00672773 L8,3 L7.88337887,3.00672773 Z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
import Props from './propsMixin';
export default {
  mixins: [Props],
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
</style>
