<template>
  <div class="spinner-container">
    <div class="spinner">
      <span />
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner {
    position: relative;
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: -5px -5px 5px rgba(255, 255, 255, 0.1),
    inset -5px -5px 5px rgba(255, 255, 255, 0.2),
    inset 10px 10px 10px rgba(0, 0, 0, 0.4);
}

.spinner:before {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    z-index: 10;
    background: white;
    border-radius: 50%;
    box-shadow: inset -2px -2px 5px rgba(255, 255, 255, 0.2);
}

.spinner span {
    left: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: linear-gradient(
        -225deg,
        #fd695a 0%,
        #665ec7 100%
    );

    -webkit-filter: blur(10px);
    filter: blur(10px);
    z-index: 1;
    animation: animate 1.5s linear infinite;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
