
import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import AddToCallContainer from '@/components_v2/intelligent/call/__COMP_AddToCallContainer.vue';

@Component({
  components: {
    AddToCallContainer,
  },
})
/**
 *
 */
export default class AddtoCallContainer extends Vue {
  @Prop({default: null})webrtcAlias: string;

  /**
   * Emit the emailList
   * @param {string} emailList - emails array
   */
  sendEmails(emailList) {
    this.$emit('send-emails', emailList);
  }

  /**
   * Emit the dial-endpoint
   * @param {string} details - dial object
   */
  dialEndpoint(details) {
    this.$emit('dial-endpoint', details);
  }

  /**
   * Emit the close invite window
   */
  closeInvite() {
    this.$emit('close-invite');
  }
}
