<template>
  <span>
    <svg
      id="Layer_1"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 108 71"
      style="enable-background:new 0 0 108 71;"
      xml:space="preserve"
    >
      <title>streaming_icon off</title>
      <g id="streaming_icon-off">
        <g
          id="Layer_2_2_"
          transform="translate(47.800000, 29.464858)"
        >
          <circle
            id="Oval"
            cx="6"
            cy="6"
            r="6"
          />
        </g>
        <g
          id="Group"
          transform="translate(0.000000, 0.000000)"
        >
          <path
            id="Path_1_"
            d="M72.7,17.1L72.7,17.1c0.2,0,0.3,0,0.5,0c1,0.1,1.9,0.6,2.5,1.4l0.3,0.4c3.6,4.9,5.6,10.7,5.6,16.7
            c0,6.1-2,11.9-5.8,16.8c-0.6,0.8-1.5,1.4-2.5,1.5c-0.2,0-0.3,0-0.5,0c-0.8,0-1.6-0.3-2.3-0.8c-0.8-0.7-1.3-1.6-1.5-2.6
            c-0.1-1,0.1-1.9,0.8-2.7c2.8-3.8,4.2-8,4.2-12.4c0-4.5-1.5-8.9-4.2-12.4c-0.6-0.9-0.8-1.9-0.7-2.8c0.1-1,0.6-1.8,1.4-2.4
            C71.1,17.4,71.9,17.1,72.7,17.1 M72.7,15.1c-1.2,0-2.4,0.4-3.5,1.2c-1.2,0.9-2,2.3-2.1,3.7c-0.2,1.4,0.2,3,1.1,4.3
            c2.4,3,3.9,7,3.9,11.2c0,4.1-1.3,7.8-3.8,11.2c-1,1.2-1.3,2.8-1.1,4.2c0.2,1.5,0.9,2.8,2.2,3.9c1,0.8,2.3,1.2,3.5,1.2
            c0.3,0,0.5,0,0.8-0.1c1.5-0.2,2.8-1,3.8-2.3c3.9-5.1,6.2-11.3,6.2-18c0-6.7-2.3-12.9-5.9-17.8l-0.3-0.4c-1-1.2-2.3-2-3.8-2.2
            C73.2,15.1,73,15.1,72.7,15.1L72.7,15.1z"
          />
          <path
            id="Path_2_"
            d="M35,17L35,17c0.8,0,1.7,0.3,2.4,0.8c0.8,0.6,1.2,1.4,1.4,2.4c0.1,1-0.2,2-0.8,2.8c-2.8,3.6-4.3,7.9-4.3,12.4
            c0,4.6,1.5,8.9,4.3,12.5c0.6,0.8,0.9,1.7,0.7,2.7c-0.1,1-0.6,1.8-1.3,2.4c-0.7,0.5-1.5,0.8-2.4,0.8c-0.2,0-0.3,0-0.5,0
            c-1-0.1-1.8-0.6-2.5-1.4c-3.7-4.7-5.8-10.7-5.8-16.9c0-6.3,2-12.2,5.8-17c0.6-0.8,1.4-1.2,2.4-1.4C34.7,17.1,34.8,17,35,17 M35,15
            c-0.2,0-0.5,0-0.7,0c-1.5,0.2-2.8,0.9-3.8,2.1c-4,5.1-6.2,11.3-6.2,18.2c0,6.8,2.3,13.2,6.2,18.1c1,1.2,2.3,2,3.8,2.1
            c0.2,0,0.5,0,0.7,0c1.3,0,2.5-0.4,3.6-1.2c1.2-0.9,1.9-2.3,2.1-3.7c0.2-1.4-0.2-2.9-1.1-4.2c-2.4-3.1-3.9-7-3.9-11.3
            c0-4.2,1.4-8,3.9-11.2c1-1.3,1.3-2.8,1.2-4.3c-0.2-1.5-0.9-2.8-2.1-3.8C37.5,15.4,36.2,15,35,15L35,15z"
          />
          <path
            id="Path_3_"
            d="M16.2,2.2L16.2,2.2c0.8,0,1.7,0.3,2.4,0.8c0.8,0.6,1.2,1.4,1.4,2.4c0.1,1-0.2,2-0.7,2.8l-0.4,0.6l0,0l0,0
            c-5.8,7.7-8.9,16.9-8.9,26.6c0,9.8,3.3,19.4,9.2,27.1c0.6,0.9,0.9,1.9,0.7,2.8c-0.1,0.9-0.6,1.8-1.4,2.4c-0.7,0.5-1.5,0.8-2.4,0.8
            c-0.2,0-0.3,0-0.5,0c-1-0.1-1.8-0.6-2.4-1.4l-0.4-0.5C5.9,57.6,2.2,46.8,2.2,35.5c0-11.6,3.8-22.6,11-31.8
            c0.7-0.8,1.6-1.3,2.5-1.4C15.9,2.3,16,2.3,16.2,2.2 M16.2,0.2c-0.2,0-0.4,0-0.6,0c-1.4,0.1-2.8,0.8-3.9,2.1
            C4.4,11.7,0.3,23,0.3,35.5c0,12.2,4,23.4,10.9,32.5l0.4,0.5c1,1.2,2.3,2,3.8,2.1c0.2,0,0.5,0,0.7,0c1.3,0,2.5-0.4,3.6-1.2
            c1.2-0.9,1.9-2.3,2.2-3.6c0.2-1.4-0.1-2.9-1.1-4.3c-5.5-7.1-8.8-16.2-8.8-25.9c0-9.6,3.2-18.3,8.5-25.4l0.4-0.6
            c1-1.2,1.3-2.8,1.2-4.3c-0.2-1.5-0.9-2.8-2.1-3.8C18.7,0.6,17.4,0.2,16.2,0.2L16.2,0.2z"
          />
          <path
            id="Path_4_"
            d="M91.7,2.4L91.7,2.4c0.2,0,0.3,0,0.5,0c1,0.1,1.9,0.6,2.5,1.4l0.4,0.5c6.8,9,10.5,19.8,10.5,31.2
            c0,11.4-3.7,22.3-10.9,31.5c-0.6,0.8-1.5,1.4-2.5,1.5c-0.2,0-0.3,0-0.5,0c-0.8,0-1.6-0.3-2.3-0.8c-0.8-0.7-1.3-1.6-1.5-2.6
            c-0.1-1,0.1-1.9,0.7-2.7l0.3-0.4c5.9-7.7,9-16.9,9-26.7c0-9.9-3.2-19.2-9.2-27c-0.6-0.9-0.9-1.9-0.7-2.9c0.1-1,0.6-1.8,1.4-2.4
            C90.1,2.7,90.9,2.4,91.7,2.4 M91.7,0.4c-1.2,0-2.4,0.4-3.5,1.2c-1.2,0.9-2,2.3-2.1,3.7c-0.2,1.4,0.2,3,1.1,4.3
            c5.5,7.1,8.8,16.1,8.8,25.8c0,9.6-3.2,18.4-8.6,25.5l-0.3,0.4c-1,1.2-1.3,2.7-1.1,4.2c0.2,1.5,0.9,2.8,2.2,3.9
            c1,0.8,2.3,1.2,3.5,1.2c0.3,0,0.5,0,0.8-0.1c1.5-0.2,2.8-1,3.8-2.3c7-9.1,11.2-20.4,11.2-32.7c0-12.2-4-23.5-10.9-32.5l-0.4-0.5
            c-1-1.2-2.3-2-3.8-2.2C92.2,0.4,92,0.4,91.7,0.4L91.7,0.4z"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
import Props from './propsMixin';
export default {
  mixins: [Props],
};
</script>
