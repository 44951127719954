<template>
  <b-container
    fluid
    class="keypad"
  >
    <b-row>
      <b-col class="p-0">
        <div class="dropup w-100">
          <b-button
            variant="secondary"
            class="dropdown-toggle w-100"
            data-toggle="dropdown"
          >
            <span class="target-participant">{{ targetParticipantName }}</span>
          </b-button>
          <div class="dropdown-menu">
            <option
              value=""
              :selected="targetParticipantId === null"
              @click="changeSelectedParticipant({ display_name : 'Conference Room' })"
            >
              Conference
            </option>
            <option
              v-for="(participant,index) in participants"
              :key="index"
              @click="changeSelectedParticipant(participant)"
            >
              {{ 'User' }}: {{ participant.display_name }}
            </option>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="row-cols-3">
      <b-col
        v-for="n in 9"
        :key="n"
        :ref="`key-`+n"
        class="p-1"
        @click="sendDTMF(`${n}`, targetParticipantId);"
      >
        <div class="btn btn-primary w-100 one-pad">
          {{ n }}
        </div>
      </b-col>
      <b-col
        ref="key-*"
        class="m-0 p-1"
        @click="sendDTMF('*', targetParticipantId);"
      >
        <span class="btn btn-primary w-100 one-pad ">*</span>
      </b-col>
      <b-col
        ref="key-0"
        class="m-0 p-1"
        @click="sendDTMF('0', targetParticipantId)"
      >
        <span class="btn btn-primary w-100 one-pad ">0</span>
      </b-col>
      <b-col
        ref="key-#"
        class="m-0 p-1"
        @click="sendDTMF('#', targetParticipantId)"
      >
        <span class="btn btn-primary w-100 one-pad ">#</span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {AudioFile} from '@/libs/audio/AudioFile';
export default {

  props: {
    participants: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      targetParticipantId: null,
      targetParticipantName: '',
      sound: new AudioFile({playThroughSpeakers: true}),
    };
  },
  async mounted() {
    this.changeSelectedParticipant({display_name: 'Conference'});
    await this.sound.load('/beep.mp3');
    this.sound.setVolume(0.63);
  },
  methods: {
    async sendDTMF(digit, uuid) {
      if (uuid) {
        this.$emit('send-dtmf', digit, uuid);
      } else {
        this.$emit('send-dtmf', digit);
      }
      if (!this.sound.isLoaded) {
        await this.sound.load('/beep.mp3');
      }
      this.sound.play();
    },

    changeSelectedParticipant(participant) {
      this.targetParticipantId = participant.uuid;
      this.targetParticipantName = participant.display_name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "@/assets/scss/__variables";
@import "~bootstrap/scss/mixins";

option {
  cursor: pointer;
}

.dropdown-menu {
  font-size: 1.6rem;
}

.keypad {
  background: white;
  border-radius: 13px;
  bottom: 100px;
  left: calc(50% - 135px);
  margin: 0 auto;
  padding: 40px;
  position: absolute;
  width: 275px;

  @media all and (max-width: $mobileLandscapeMax) {
      position: fixed;
  }
}

.one-pad {
    cursor: pointer;

    &:active {
      opacity: 0.5;
      transition: all 0.3s ease-in-out;
    }
}

.target-participant {
  overflow: auto;
  text-overflow: ellipsis;
  width: 100%;
}
</style>
