<template>
  <div :class="dashboardBot ? 'chat-msg-dashboard' : 'chat-msg'">
    <div class="msger">
      <div class="msger-header">
        <div class="msger-header-title">
          AI Bot
          <div
            v-if="dashboardBot"
            class="fa fa-times cursor-pointer msger-header-dashboard"
            @click="$emit('close')"
          />
        </div>
      </div>
      <main
        ref="chatContainer"
        class="msger-chat"
      >
        <div>
          <div
            v-for="voiceMessage in voiceMessages"
            :key="voiceMessage.id"
            class="msg"
            :class="{
              'right-msg': voiceMessage.isUser,
              'left-msg': !voiceMessage.isUser,
            }"
          >
            <div class="msg-img">
              <img
                v-if="!voiceMessage.isUser"
                class="msg-img left-msg-margin-top"
                src="@/assets/img/SV-chat-logo.png"
              >
              <img
                v-else
                class="msg-img"
                src="@/assets/img/icons/icon-user.svg"
              >
            </div>

            <div class="msg-bubble">
              <div class="msg-info">
                <div
                  v-if="!voiceMessage.isUser"
                  class="msg-info-name"
                >
                  AI Bot
                </div>
                <div
                  v-else
                  class="msg-info-name"
                >
                  You
                </div>
                <div class="msg-info-time">
                  {{ voiceMessage.time }}
                </div>
              </div>
              <div class="msg-text">
                <!-- <p v-html="replaceNewLines(voiceMessage.text)" /> -->
                <p>
                  {{ voiceMessage.text.split('IMPORTANT:')[0] }}
                  <br>
                  <span
                    v-if="voiceMessage.id === 1"
                    style="font-weight: bold;"
                  >IMPORTANT:</span>
                  {{ voiceMessage.text.split('IMPORTANT:')[1] }}
                </p>
              </div>
              <div v-if="voiceMessage.url">
                <audio controls>
                  <source
                    :src="voiceMessage.url"
                    type="audio/wav"
                  >
                  Your browser does not support the audio element.
                </audio>
              </div>
            </div>
          </div>
          <div
            v-if="isLoading"
            class="d-flex justify-content-center"
          >
            <small-spinner class="spinnerClass" />
          </div>
        </div>
      </main>

      <div class="msger-inputarea row m-0">
        <div class="col-12 d-flex align-items-center justify-content-center p-0">
          <button
            :disabled="isLoading"
            :class="{ 'disabled-overlay': isLoading }"
            class="recording-btn recording-btn-submit"
            @click="toggleRecording"
          >
            <span v-if="isRecording">
              <button class="btn btn-primary stop-button">Stop</button>
            </span>
            <div
              v-else
              v-tooltip="isLoading ? 'Wait for Bot Answer' : 'Start Chat'"
            >
              <img
                src="@/assets/img/icons/ic_mic.svg"
              >
            </div>
          </button>
          <p
            v-if="isRecording"
            class="m-0"
          >
            Recording... {{ timer }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallSpinner from '@/components_v2/generic/misc/__COMP_SmallSpinner.vue';
import axios from 'axios';


export default {
  name: 'OpenAIChatBot',
  components: {
    // PulseLoader,
    SmallSpinner,
  },
  props: {
    dashboardBot: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      messages: [],
      voiceMessages: [
        {
          id: 1,
          type: 'audio',
          text: 'Hi, Welcome to Chat Bot! You can ask your question by clicking on the microphone icon below.IMPORTANT: This is a beta version powered by GPT. It can make mistakes - please consider checking important information.',
          url: '',
          time: this.getCurrentTime(),
          isUser: false,
        },
      ],
      isRecording: false,
      recorder: null,
      audioChunks: [],
      timerInterval: null,
      timer: '00:00', // Initial timer value
      isLoading: false,
    };
  },
  created() {
    // Load voiceMessages from local storage when the component is created
    const storedVoiceMessages = JSON.parse(localStorage.getItem('voiceMessages'));
    const storedDashboardVoiceMessages = JSON.parse(localStorage.getItem('dashboardVoiceMessages'));
    if (this.dashboardBot && storedDashboardVoiceMessages) {
      this.voiceMessages = storedDashboardVoiceMessages;
      this.scrollChatContainerToBottom();
    }
    if (storedVoiceMessages) {
      this.voiceMessages = storedVoiceMessages;
      this.scrollChatContainerToBottom();
    }
  },
  destroyed() {
    // Clear the interval when the component is destroyed to avoid memory leaks
    clearInterval(this.timerInterval);
  },
  methods: {
    scrollChatContainerToBottom() {
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },
    getCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    replaceNewLines(text) {
      const replacedArrayText = text.replace(/\[\]/g, '');
      const replacedNewLineText = replacedArrayText.replace(/\n/g, '<br>');
      return replacedNewLineText;
    },

    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    async startRecording() {
      // this.$emit('toggle');
      this.isRecording = true;
      this.audioChunks = [];
      const stream = await navigator.mediaDevices.getUserMedia({audio: true});
      this.recorder = new MediaRecorder(stream);

      this.recorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };

      this.recorder.onstop = async () => {
        clearInterval(this.timerInterval); // Stop the timer when recording is stopped
        const audioBlob = new Blob(this.audioChunks, {type: 'audio/wav'});
        const fileName = 'voicerecording.wav';
        const audioFile = new File([audioBlob], fileName, {
          type: 'audio/wav',
        });
        this.isLoading = true;
        this.scrollChatContainerToBottom();
        const formData = new FormData();
        formData.append('file', audioFile);
        formData.append('model', 'whisper-1');

        try {
          const translationResponse = await axios.post('/translate-audio', formData);

          const translationResult = translationResponse.data;
          const translatedText = translationResult.text;
          const botVoiceMessage = {
            id: this.voiceMessages.length + 1,
            type: 'audio',
            text: translatedText,
            url: URL.createObjectURL(audioBlob),
            time: this.getCurrentTime(),
            isUser: true,
          };
          this.voiceMessages.push(botVoiceMessage);
          this.scrollChatContainerToBottom();


          // Creates a model response for the given chat conversation.
          this.chartConversation(translatedText);
        } catch (error) {
          console.error('Error:', error);
        }
      };

      this.recorder.start();
      // Start the timer
      let seconds = 0;
      this.timerInterval = setInterval(() => {
        seconds++;
        this.timer = this.formatTimer(seconds);
      }, 1000);
    },

    stopRecording() {
      if (this.recorder && this.recorder.state === 'recording' && this.isRecording) {
        this.recorder.stop();
        this.isRecording = false;
      }
    },
    formatTimer(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(remainingSeconds).padStart(2, '0');
      return `${formattedMinutes}:${formattedSeconds}`;
    },
    async chartConversation(chartConversation) {
      this.isLoading = true;
      // Append user message to messages array
      this.messages.push({role: 'user', content: chartConversation});

      // Request payload according to OpenAI API documentation
      const payload = {
        model: 'gpt-3.5-turbo',
        messages: this.messages.map((msg, index) => ({
          role: msg.role,
          content: msg.content,
        })),
      };

      try {
        const response = await axios.post('/chat-completions', payload);
        const data = response.data; // Parse response JSON
        const botMessage = data.choices[0].message.content;

        // Convert translated text to speech and play it
        this.playTranslatedSpeech(botMessage);

        // Append bot's response to messages array
        this.messages.push({role: 'system', content: botMessage});
      } catch (error) {
        console.error('Error:', error); // Handle errors
      }
    },

    async playTranslatedSpeech(botMessage) {
      this.isLoading = true;
      this.scrollChatContainerToBottom();
      const requestBody = {
        model: 'tts-1',
        input: botMessage, // Your audio data (replace with the actual audio binary or blob)
        voice: 'alloy',
        config: {
          encoding: 'LINEAR16', // Replace with the appropriate audio encoding
          sampleRateHertz: 16000, // Replace with the sample rate of your audio
          languageCode: 'en-US', // Replace with the language code if needed
        },
      };
      try {
        const response = await axios.post('generate-speech', requestBody, {
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
        });
        const data = await response.data;
        const botVoiceMessage = {
          id: this.voiceMessages.length + 1,
          type: 'audio',
          text: botMessage,
          url: URL.createObjectURL(data),
          time: this.getCurrentTime(),
          isUser: false,
        };
        this.voiceMessages.push(botVoiceMessage);
        if (this.dashboardBot) {
          localStorage.setItem('dashboardVoiceMessages', JSON.stringify(this.voiceMessages));
        } else {
          localStorage.setItem('voiceMessages', JSON.stringify(this.voiceMessages));
        }
        this.scrollChatContainerToBottom();
      } catch (error) {
        console.error('Error:', error); // Handle errors
      } finally {
        this.isLoading = false;
      }
    },

  },
};
</script>
<style  lang="scss" scoped>
@import "@/assets/scss/__variables";


.chat-msg {
  background-color: $white;
  border-radius: 10px;
  bottom: 75px;
  padding: 0;
  position: absolute;
  right: 5px;
  width: 500px;

  @media all and (max-width: $mobileLandscapeMin) {
    overflow-y: scroll;
    right: 0;
    width: 220px;
  }

  @media all and (max-width: $settings_tab-breakpoint) {
    height: 40vh;
    position: absolute;
    top: 30px;
  }

  &:before {
    border-bottom: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    bottom: -10px;
    content: "";
    height: 0;
    position: absolute;
    right: 20px;
    transform: rotate(180deg);
    width: 0;

    @media all and (max-width: $settings_tab-breakpoint) {
      right: 10px;
      top: -10px;
      transform: rotate(0deg);
    }
  }
}
.msger {
  /* display: flex; */
  flex-flow: column wrap;
  justify-content: space-between;
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.msger-header {
  padding: 10px;
  text-align: center;
  background: #eee;
  color: #666;
   border-radius: 10px;
   border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.msg-img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  // background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background-color: #f2f2f2;
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  border-bottom-right-radius: 0;
}

.msger-inputarea {
  display: flex;
  // padding: 10px;
  height: 55px;
  border-top: var(--border);
  background: #eee;
   border-radius: 10px;
    border-top-right-radius: 0;
     border-top-left-radius: 0;
}
.msger-inputarea * {
  padding: 5px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}

.msger-chat {
  background-color: #fcfcfe;
  height: 300px;
  overflow-y: scroll;
}
.spinnerClass {
  height: 30px !important;
  width: 30px !important;
}
.stop-button {
  border-radius: 50%;
}

.disabled-overlay {
  cursor: not-allowed;  /* Allow clicks to go through to the content */
}

.chat-msg-dashboard {
    background-color: white;
    border-radius: 10px;
    padding: 0;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
}
.msger-header-dashboard {
  float: right;
  padding: 2px;
}
.left-msg-margin-top{
  margin-top: 5px;
}

</style>
