<template>
  <div class="settings">
    <div class="row">
      <div class="col-12 text-center">
        <button
          class="btn btn-sm"
          :class="deviceSettingsTabClasses"
          @click="selectDeviceSettingsTab"
        >
          <span class="d-none d-md-inline">Device settings</span>
          <span class="d-md-none">Devices</span>
        </button>

        <button
          class="btn btn-sm ml-2"
          :class="roomSettingsTabClasses"
          @click="selectRoomSettingsTab"
        >
          <span class="d-none d-md-inline">Room settings</span>
          <span class="d-md-none">Room</span>
        </button>
      </div>
    </div>

    <div
      v-if="isDeviceSettingsTabSelected"
      class="settings_tab_content"
    >
      <DeviceSettings
        class="mt-1"
        :initial-call-type="initialCallType"
        :preselected-camera-device-id="preselectedCameraDeviceId"
        :preselected-microphone-device-id="preselectedMicrophoneDeviceId"
        :preselected-speaker-device-id="preselectedSpeakerDeviceId"
        @renegotiate="$emit('renegotiate', $event)"
      />
    </div>

    <div
      v-if="isRoomSettingsTabSelected"
      class="settings_tab_content"
    >
      <RoomSettings
        class="mt-1"
        :is-host="isHost"
        :initial-layout="initialLayout"
        :initial-maximum-bandwidth-kbps="initialMaximumBandwidthKbps"
        @apply-room-settings="$emit('apply-room-settings', $event)"
      />
    </div>
  </div>
</template>

<script>
import DeviceSettings from '@/components/call/settings/DeviceSettings.vue';
import RoomSettings from '@/components/call/settings/RoomSettings.vue';

export default {
  components: {
    DeviceSettings,
    RoomSettings,
  },
  props: {
    isHost: Boolean,
    initialCallType: {
      type: String,
      default: null,
    },
    initialLayout: {
      type: String,
      default: null,
    },
    initialMaximumBandwidthKbps: {
      type: Number,
      default: null,
    },
    preselectedCameraDeviceId: {
      type: [String, Boolean],
      default: null,
    },
    preselectedMicrophoneDeviceId: {
      type: [String, Boolean],
      default: null,
    },
    preselectedSpeakerDeviceId: {
      type: [String, Boolean],
      default: null,
    },
  },
  data() {
    return {
      selectedTab: 'device-settings',
    };
  },
  computed: {
    deviceSettingsTabClasses() {
      return {
        'settings_tab_active': this.isDeviceSettingsTabSelected,
        'settings_tab_inactive': !this.isDeviceSettingsTabSelected,
      };
    },
    roomSettingsTabClasses() {
      return {
        'settings_tab_active': this.isRoomSettingsTabSelected,
        'settings_tab_inactive': !this.isRoomSettingsTabSelected,
      };
    },
    isDeviceSettingsTabSelected() {
      return this.selectedTab === 'device-settings';
    },
    isRoomSettingsTabSelected() {
      return this.selectedTab === 'room-settings';
    },
  },
  methods: {
    selectDeviceSettingsTab() {
      this.selectedTab = 'device-settings';
    },
    selectRoomSettingsTab() {
      this.selectedTab = 'room-settings';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/__variables";
@import '~bootstrap/scss/mixins';
@import "@/assets/scss/__modals";
@import "@/assets/scss/__multiselect-overrides";
.settings {
  background-color: $white;
  border-radius: 10px;
  bottom: 90px;
  min-width: 300px;
  padding: 2rem;
  position: absolute;
  @include media-breakpoint-down(sm) {
    bottom: -183px;
    left: 100px;
    min-width: 200px;
    max-height: 300px;
    overflow: scroll;
  }
  >>> .form-group label {
    font-weight: bold;
  }

  &:before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $white;
    bottom: -10px;
    content: "";
    height: 0;
    position: absolute;
    transform: rotate(180deg);
    width: 0;
  }
}
</style>
