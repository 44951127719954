<template>
  <div class="action-cover">
    <div
      v-if="mutedRemotely"
      v-tooltip="'A host has muted you'"
      :class="{'circular': circular, 'meeting-action bg-white': !circular, 'mute': !enabled}"
    >
      <img
        src="@/assets/img/icons/ic_mic_mute.svg"
      >
    </div>

    <div
      v-else-if="allowed && selectedMicrophoneDeviceId"
      v-tooltip="enabled ? 'Disable mic' : 'Enable mic'"
      :class="{'circular': circular, 'meeting-action bg-white': !circular, 'mute': !enabled}"
      @click="$emit('toggle')"
    >
      <img
        v-if="enabled && !mutedRemotely"
        src="@/assets/img/icons/ic_mic.svg"
      >
      <img
        v-else
        src="@/assets/img/icons/ic_mic_mute.svg"
      >
    </div>

    <div
      v-else
      :class="{'circular disabled': circular, 'meeting-action bg-white disabled': !circular}"
      @click="$emit('open-settings')"
    >
      <img src="@/assets/img/icons/ic_mic_mute.svg">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedMicrophoneDeviceId: {
      type: [String, Boolean],
      default: null,
    },
    allowed: {
      type: Boolean,
      required: true,
    },
    enabled: {
      type: Boolean,
      required: true,
    },
    mutedRemotely: {
      type: Boolean,
      required: true,
    },
    circular: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/__meeting_action";

  .circular {
    background: url('../../../assets/img/icons/ic_mic_white.svg') center center no-repeat transparent;
    background-size: auto 20px;
    border-radius: 50%;
    border: 1px solid white;
    height: 40px;
    width: 40px;

    &.mute,
    &.disabled {
      background-color: rgba(0,0,0,0.5);
      background-image: url('../../../assets/img/icons/ic_mic_mute_white.svg');
    }

    &.mute {
      border-color: var(--primary-color);
    }

    img {
      display: none;
    }
  }
</style>
