import axios from 'axios';
import {Module, Action, Mutation, VuexModule} from 'vuex-class-modules';
import {mainStore} from './__STORE_main';
import store from './';

/**
 *
 */
@Module
class BillingStore extends VuexModule {
  billingDetails = {}
  stripe = {}
  plans = []
  currencies = {}
  addons = []
  roles = [
    {id: 1, display_name: 'Admin', role_name: 'admin'},
    {id: 2, display_name: 'Regular user', role_name: 'regular_user'},
  ]
  paymentMethods = []
  invoices = []
  businessProfile = {}

  /**
   *
   * @param {Object} data
   */
  @Mutation
  private __setBusinessProfile(data: Object) {
    this.businessProfile = data;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setInvoices(data: any) {
    this.invoices = data;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setBillingDetails(data: any) {
    this.billingDetails = data;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setStripeData(data: any) {
    this.stripe = data;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setPlanData(data: any) {
    this.plans = data;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setCurrencyData(data: any) {
    this.currencies = data;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setAddonData(data: any) {
    this.addons = data;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setPaymentMethods(data: any) {
    this.paymentMethods = data;
  }

  // Actions

  /**
   * Get user account billing details
   * @return {Promise} - api response
   */
  @Action
  async getBillingDetails(): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: '/billing-details', method: 'GET'})
          .then((resp) => {
            this.__setBillingDetails(resp.data);
            mainStore.setStatus('success');
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Set the user billing details
   * @param {any} billingDetails - billing details object to set
   */
  @Action
  async setBillingDetails(billingDetails: any) {
    this.__setBillingDetails({...billingDetails});
  }

  /**
   * Update the account billing details on the api
   * @param {any} payload - billing details to send for update
   * @return {Promise} - api response
   */
  @Action
  async updateBillingDetails(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      const modifiedPayload = {...payload};
      modifiedPayload.country_id = modifiedPayload.country.id;
      delete modifiedPayload.country;
      axios({url: '/billing-details', data: modifiedPayload, method: 'PATCH'})
          .then((resp) => {
            mainStore.setStatus('success');
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Get the stripe data from the api
   * @return {Promise} - stripe form data
   */
  @Action
  async getStripeData(): Promise<any> {
    return new Promise((resolve, reject) => {
      // @todo for some reason "loading" here causes maximum call stack exception
      // commit('set_status', 'loading')
      axios({url: '/payments/form', method: 'GET'})
          .then((resp) => {
            this.__setStripeData(resp.data);
            mainStore.setStatus('success');
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Get all billing plan data from the api
   * @return {Promise} - api response
   */
  @Action
  async getPlanData(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get('/billing-plans')
          .then((response) => {
            if (response.data) {
              this.__setPlanData(response.data);
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }

  /**
   * Get all the currency options from the api
   * @return {Promise} - api response
   */
  @Action
  async getCurrencyData(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get('/currencies')
          .then((response) => {
            if (response.data) {
              this.__setCurrencyData(response.data);
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }

  /**
   * Get all the addon options from the api
   * @return {Promise} - api response
   */
  @Action
  async getAddonData(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get('/addons')
          .then((response) => {
            if (response.data) {
              this.__setAddonData( response.data);
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
    });
  }

  /**
   * Get the user account payment methods
   * @return {Promise} - api response
   */
  @Action
  async getPaymentMethods(): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: '/payment-methods', method: 'GET'})
          .then((resp) => {
            this.__setPaymentMethods( resp.data);
            mainStore.setStatus('success');
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Save a payment method to the api
   * @param {Object} payload - payment method to save
   * @return {Promise} - api response
   */
  @Action
  async savePaymentMethods(payload: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: '/payment-methods', data: payload, method: 'POST'})
          .then((resp) => {
            mainStore.setStatus('success');
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Delete a given payment method from the user account
   * @param {number} paymentID - payment method ID
   * @return {Promise} - api response
   */
  @Action
  async deletePaymentMethods(paymentID: number): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus( 'loading');
      axios({url: `/payment-methods/${paymentID}`, method: 'DELETE'})
          .then((resp) => {
            mainStore.setStatus( 'success');
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus( 'error');
            reject(err);
          });
    });
  }

  /**
   * Set a given payment method on the user account as default
   * @param {number} paymentID - given payment method ID
   * @return {Promise} - api response
   */
  @Action
  async setDefaultPayment(paymentID: number): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: '/payment-methods/set-default', data: {payment_method: paymentID}, method: 'POST'})
          .then((resp) => {
            mainStore.setStatus( 'success');
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus( 'error');
            reject(err);
          });
    });
  }

  /**
   * Get the user account invoices on given page or on first page
   * @param {string} url - pagination url of page for invoices to get
   * @return {Promise} - api response
   */
  @Action
  async getInvoices(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: `/invoices${url}`, method: 'GET'})
          .then((resp) => {
            this.__setInvoices( resp.data);
            mainStore.setStatus( 'success');
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus( 'error');
            reject(err);
          });
    });
  }

  /**
   * Get user account business profile data
   * @return {Promise} - api response
   */
  @Action
  async getBusinessProfile(): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus( 'loading');
      axios({url: '/business-profile', method: 'GET'})
          .then((resp) => {
            this.__setBusinessProfile(resp.data);
            mainStore.setStatus( 'success');
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Update the user account business profile data
   * @param {Object} payload - business profile details to send for update
   * @return {Promise} - api response
   */
  @Action
  async updateBusinessProfile(payload: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus( 'loading');
      axios({url: '/business-profile', data: payload, method: 'PATCH'})
          .then((resp) => {
            mainStore.setStatus( 'success');
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus( 'error');
            reject(err);
          });
    });
  }
}

export const billingStore = new BillingStore({store, name: 'billingStore'});
