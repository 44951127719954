
import Vue from 'vue';
import Component from 'vue-class-component';

import {IconLocked, IconMicSmall, IconMicSmallMute} from '@/components/icons';

import {pexipStore} from '@/store/__STORE_pexip';

@Component({
  components: {
    IconLocked,
    IconMicSmall,
    IconMicSmallMute,
  },
})
/**
 * Intelligent component for controlling the settings area for participants
 * Allows for disconnecting and muting all participants as well as interacting
 * with the room lock
 */
export default class ParticipantsSettings extends Vue {
  name: 'Participants Settings'

  /**
   * Method to call the pexip store function to toggle the meeting room
   * lock
   */
  toggleRoomLock() {
    pexipStore.toggleMeetingLock();
    pexipStore.setIsShowParticipantsSettings(false);
  }

  /**
   * Calls the pexip store method to mute all participants
   */
  muteAll() {
    pexipStore.muteAllParticipants();
    pexipStore.setIsShowParticipantsSettings(false);
  }

  /**
   * Disconnects all participants from the call including the current user
   */
  disconnectAll() {
    pexipStore.realDisconnectAllParticipants();
    pexipStore.setIsShowParticipantsSettings(false);
  }

  /**
   * Getter to get whether the toggle lock should be shown
   * @return {boolean} - whether to show the toggle lock
   */
  get isToggleLockVisible(): boolean {
    return pexipStore.call.isHost && pexipStore.call.isAlwaysLocked;
  }

  /**
   * Getter to get mute status from the pexip store
   * @return {boolean} - mute status
   */
  get muteStatus(): boolean {
    return pexipStore.call.muteStatus;
  }
}
