import Vue from 'vue';
import Component from 'vue-class-component';

@Component
/**
 * Responsive Mixin for methods to enable responsive behaviour
 */
export class Responsive extends Vue {
  /**
   * Whether the device is a mobile device or not.
   *
   * Chosen based off of the Bootstrap "sm" breakpoint.
   * https://getbootstrap.com/docs/4.1/layout/overview/
   * @return {boolean}
   */
  __MIXINisMobileDevice(): boolean {
    return ( window.innerWidth <= 813 );
  }

  /**
   * Setting up secondary check to help determine moving the setting buttons
   * @return {boolean}
   */
  __MIXINthinDesktop(): boolean {
    return ( window.innerWidth <= 1120 );
  }

  /**
   * Setting up secondary check to help separate out when we hide the chat in ongoing meeting
   * @return {boolean}
   */
  __MIXINisMobile(): boolean {
    return ( window.innerWidth <= 576 );
  }

  /**
   * Calculates the orientation of the device.
   * @return {boolean} whether the user's device is landscape
   */
  __MIXINisLandscape(): boolean {
    return window.innerWidth > window.innerHeight;
  }
}
