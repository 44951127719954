<template>
  <header>
    <nav class="navbar navbar-expand-md">
      <div class="container">
        <router-link
          class="navbar-brand"
          :to="{name: 'dashboard'}"
        >
          <img
            alt="SimplyVideo"
            src="@/assets/img/sv-primary-logo-col.png"
          >
        </router-link>
        <ul class="navbar-nav list-group-horizontal ml-auto">
          <li
            v-if="!isLoggedIn"
            class="nav-item"
          >
            <router-link
              class="nav nav-link"
              to="/login"
            >
              Log In
            </router-link>
          </li>
          <li
            v-if="!isLoggedIn"
            class="nav-item ml-1 ml-3"
          >
            <router-link
              class="nav nav-link"
              to="/register"
            >
              Register
            </router-link>
          </li>
          <li
            v-if="isLoggedIn"
            class="nav-item ml-1"
          >
            <a
              class="nav nav-link"
              href="#"
              @click="logout"
            >Logout</a>
          </li>
          <li class="nav-item ml-3">
            <router-link :to="{name: 'join-meeting'}">
              <button
                id="join-meeting"
                class="btn btn-primary"
              >
                Join meeting
              </button>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>
<script>
import {authStore} from '@/store/__STORE_auth';
export default {
  computed: {
    isLoggedIn() {
      return authStore.__GETTERisUserLoggedIn;
    },
  },
  methods: {
    logout() {
      authStore.logout()
          .then(() => {
            this.$router.push('/login');
          });
    },
  },
};
</script>
