<template>
  <span>
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 44 39"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 61.2 (89653) - https://sketch.com -->
      <title>icon</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Dashboard-_-Personal-_-Recordings-_-Expand-row-_-Popup"
          transform="translate(-708.000000, -80.000000)"
        >
          <g
            id="Popup"
            transform="translate(480.000000, 0.000000)"
          >
            <g
              id="Icon"
              transform="translate(200.000000, 50.000000)"
            >
              <g
                id="ic_alert_warning"
                transform="translate(27.000000, 30.000000)"
              >
                <path
                  id="icon"
                  d="M23,0.794511991 C24.7477154,0.794511991 26.3685858,1.7070753 27.2778674,3.20612892 L44.2259905,31.4999396 C45.1143865,33.0384387 45.1196907,34.9328069 44.2399242,36.4762568 C43.3601578,38.0197068 41.7274605,38.9804141 39.94,39 L6.04900947,39 C4.27253951,38.9804141 2.63984224,38.0197068 1.76007577,36.4762568 C0.880309304,34.9328069 0.885613539,33.0384387 1.78213258,31.4861289 L18.725,3.201372 C19.6314142,1.7070753 21.2522846,0.794511991 23,0.794511991 Z M23,2.79451199 C21.9513707,2.79451199 20.9788485,3.34204998 20.4378674,4.23387108 L3.50599053,32.5000604 C2.97295297,33.4231598 2.96977043,34.5597808 3.49763031,35.4858507 C4.02549019,36.4119207 5.00510856,36.9883451 6.06,37.0000604 L39.9290095,37.0000604 C40.9948914,36.9883451 41.9745098,36.4119207 42.5023697,35.4858507 C43.0302296,34.5597808 43.027047,33.4231598 42.5021326,32.5138711 L25.565,4.238628 C25.0211515,3.34204998 24.0486293,2.79451199 23,2.79451199 Z M23,29 L23.1366211,29.0067277 C23.6339598,29.0644928 24.02,29.4871642 24.02,30 C24.02,30.5128358 23.6339598,30.9355072 23.1366211,30.9932723 L23,31 C22.4477153,31 22,30.5522847 22,30 C22,29.4871642 22.3860402,29.0644928 22.8833789,29.0067277 L23,29 Z M23,13 C23.5128358,13 23.9355072,13.3860402 23.9932723,13.8833789 L24,14 L24,22 C24,22.5522847 23.5522847,23 23,23 C22.4871642,23 22.0644928,22.6139598 22.0067277,22.1166211 L22,22 L22,14 C22,13.4477153 22.4477153,13 23,13 Z"
                  :fill="fillColor"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
import Props from './propsMixin';
export default {
  mixins: [Props],
};
</script>
