export default {
  props: {
    fillColor: {
      type: String,
    },
    height: {
      default: 512,
    },
    width: {
      default: 512,
    },
  },
};
