import { render, staticRenderFns } from "./IconLocked.vue?vue&type=template&id=4321d9c0"
import script from "./IconLocked.vue?vue&type=script&lang=js"
export * from "./IconLocked.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports