<template>
  <div v-if="password.length">
    Password strength:
    <span
      id="password-strength"
      :style="{color: strengths[score].color}"
    >
      {{ strengths[score].label }}
    </span>
  </div>
</template>
<script>
import zxcvbn from 'zxcvbn';

export default {
  props: {
    password: {
      type: String,
      default: '',
    },
    userInputs: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      score: 0,
      strengths: {
        0: {label: 'Very weak', color: 'red'},
        1: {label: 'Weak', color: 'orange'},
        2: {label: 'Good', color: 'green'},
        3: {label: 'Strong', color: 'green'},
        4: {label: 'Very strong', color: 'green'},
      },
    };
  },
  watch: {
    password: {
      handler: function() {
        this.score = zxcvbn(this.password, this.userInputs).score;
        // score # Integer from 0-4 (useful for implementing a strength bar)
        // 0 # too guessable: risky password. (guesses < 10^3)
        // 1 # very guessable: protection from throttled online attacks. (guesses < 10^6)
        // 2 # somewhat guessable: protection from unthrottled online attacks. (guesses < 10^8)
        // 3 # safely unguessable: moderate protection from offline slow-hash scenario. (guesses < 10^10)
        // 4 # very unguessable: strong protection from offline slow-hash scenario. (guesses >= 10^10)

        const sufficientPassword = this.score >= 2;
        this.$emit('update-password-strength', sufficientPassword);
      },
      immediate: true,
    },
  },
};
</script>
