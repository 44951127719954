
import Vue from 'vue';
import Component from 'vue-class-component';

import {messageStore} from '@/store/__STORE_message';
import {callStore} from '@/store/__STORE_call';

import IconDownload from '@/components/icons/IconDownload.vue';

import {Compact} from 'vue-color';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/material.css';

import paper from 'paper';

@Component({
  components: {
    'compact-picker': Compact,
    VueSlider,
    IconDownload,
  },
})
/**
 * Intelligent component for image Markup, component manages the drawings
 */
export default class MarkupCanvas extends Vue {
  name: 'Markup Canvas';

  modalWidth = null
  modalHeight = null
  percentageChange = 1;

  path = null;
  history = [];
  scope = null;
  selectedTool = 'line';
  showPicker = false;
  showSlider = false;
  first = true;
  colors = {
    hex: '000000' as any,
  };
  toolWidth = 5.0;
  firstPoint = null;
  secondPoint = null;

  /**
   * Called when the component is mounted to the DOM
   */
  mounted() {
    this.scope = new paper.PaperScope();
    const canvas = document.getElementById('exampleCanvas') as HTMLCanvasElement;
    this.scope.setup(canvas);
  }

  /**
   * Called when the component is unmounted
   */
  destroyed() {
    this.scope.clear();
    this.scope.remove();
  }

  /**
   * Stops the annotation session
   */
  stopMarkup(): void {
    messageStore.cancelImageMarkup();
  }

  /**
   * Resets all the drawing on the screen
   */
  reset() {
    if (this.showPicker) {
      this.closePicker();
    }

    this.scope.project.activeLayer.removeChildren();
    this.history = [];
    this.first = true;
  }

  /**
   * Saves the drawing on the screen to the image
   */
  upload() {
    this.scope.activate();
    const raster = new paper.Raster({
      crossOrigin: 'anonymous',
      source: this.background,
      position: this.scope.view.center,
    });
    raster.onLoad = () => {
      raster.sendToBack();
      raster.scale(this.percentageChange);
      this.scope.view.update();
      const canvas = document.getElementById('exampleCanvas') as HTMLCanvasElement;
      canvas.toBlob((blob: Blob) => {
        messageStore.uploadFile(blob)
            .then(() => {
              this.stopMarkup();
            })
            .catch((err) => {
              if (err.response.status === 413) {
                this.$toast.error('The file you tried to upload was too large. Files must be less than 50mb.');
              } else if (err.response.status === 422) {
                this.$toast.error(`Something went wrong. Please contact support with error code 'Gravity'`);
              } else {
                this.$toast.error('Something went wrong while uploading your file. Please try again.');
              }
              messageStore.deleteUploadedFile();
            });
      });
    };
  }

  /**
   * Saves the markup image to the user's computer
   */
  save() {
    this.scope.activate();
    const raster = new paper.Raster({
      crossOrigin: 'anonymous',
      source: this.background,
      position: this.scope.view.center,
    });
    raster.onLoad = () => {
      raster.sendToBack();
      raster.scale(this.percentageChange);
      this.scope.view.update();
      const link = document.createElement('a') as any;
      const date = new Date();
      const name = date.toISOString().split('T')[0] + '-' + callStore.__GETTERcallAlias;
      link.download = name;
      const canvas = document.getElementById('exampleCanvas') as HTMLCanvasElement;
      link.href = canvas.toDataURL();
      link.click();
      link.remove();
    };
  }

  /**
   * Undoes the last action drawn to screen
   */
  undo() {
    const lastChild = this.scope.project.activeLayer.lastChild;
    if (lastChild != null) {
      this.history.push(lastChild);
      lastChild.remove();
    }
  }

  /**
   * Redoes the last action that was undone
   */
  redo() {
    if (this.history.length > 0) {
      const toAdd = this.history.pop();
      this.scope.project.activeLayer.addChild(toAdd);
    }
  }

  /**
   * Toggles whether the colour picker is shown
   */
  toggleColourPicker() {
    this.closeSlider();
    this.showPicker = !this.showPicker;
  }

  /**
   * Toggles whether the thickness slider is shown
   */
  toggleThicknessSlider() {
    this.closePicker();
    this.showSlider = !this.showSlider;
  }

  /**
   * Closes the size slider selector
   */
  closeSlider() {
    this.showSlider = false;
  }

  /**
   * Closes the colour picker
   */
  closePicker() {
    this.showPicker = false;
  }

  /**
   * Allows the user to select a tool
   * @param {string} toolType - the selected tool string
   */
  selectTool(toolType: string) {
    this.first = true;
    this.selectedTool = toolType;
  }

  /**
   * Draws a line to the screen
   * @param {any} scope - paper library scope
   * @return {paper.Path} - the line path from the package 'paper'
   */
  createLine(scope: any): paper.Path {
    scope.activate();
    return new paper.Path({
      strokeColor: this.colors.hex,
      strokeJoin: 'round',
      strokeWidth: this.toolWidth,
    });
  }

  /**
   * Draws a square to the screen
   * @param {any} scope - paper library scope
   * @param {any} start - x and y coords for the start location
   * @param {any} end - x and y coords for the end location
   */
  createSquare(scope: any, start: any, end: any) {
    scope.activate();
    if (!this.first) {
      const lastChild = this.scope.project.activeLayer.lastChild;
      if (lastChild != null) {
        lastChild.remove();
      }
    }
    this.first = false;
    const width = end.x - start.x;
    const height = end.y - start.y;
    const size = new paper.Size(width, height);
    const rect = new paper.Rectangle(start, size);
    const path = new paper.Path.Rectangle(rect);
    path.strokeColor = this.colors.hex;
    path.strokeWidth = this.toolWidth;
  }

  /**
   * Draws a circle to the screen
   * @param {any} scope - paper library scope
   * @param {any} start - x and y coords for the start location
   * @param {any} end - x and y coords for the end location
   */
  createCircle(scope: any, start: any, end: any) {
    scope.activate();
    if (!this.first) {
      const lastChild = this.scope.project.activeLayer.lastChild;
      if (lastChild != null) {
        lastChild.remove();
      }
    }
    this.first = false;
    let a = 0;
    let b = 0;
    if (end.y >= start.y) {
      a = end.y - end.y;
    } else {
      a = start.y - end.y;
    }
    if (end.x >= start.x) {
      b = end.x - start.x;
    } else {
      b = start.x - end.x;
    }
    const a2 = Math.pow(a, 2);
    const b2 = Math.pow(b, 2);
    const total = a2 + b2;
    const radius = Math.sqrt(total);
    const path = new paper.Path.Circle(start, radius);
    path.strokeColor = this.colors.hex;
    path.strokeWidth = this.toolWidth;
  }

  /**
   * Draws an arrow to the screen
   * @param {any} scope - paper library scope
   * @param {any} start - x and y coords for the start location
   * @param {any} end - x and y coords for the end location
   */
  createArrow(scope: any, start: any, end: any) {
    scope.activate();
    if (!this.first) {
      const lastChild = this.scope.project.activeLayer.lastChild;
      if (lastChild != null) {
        lastChild.remove();
      }
    }
    this.first = false;
    const vector = new paper.Point(end.x - start.x, end.y - start.y);
    const centre = new paper.Point(0, 0);
    const negVec = vector.rotate(-135, centre);
    negVec.length = 4*this.toolWidth;
    const posVec = vector.rotate(135, centre);
    posVec.length = 4*this.toolWidth;
    const path = new paper.Path([
      new paper.Point(end.x, end.y),
      new paper.Point(start.x, start.y),
      new paper.Point(start.x-negVec.x, start.y-negVec.y),
      start,
      new paper.Point(start.x-posVec.x, start.y-posVec.y),
    ]);
    path.strokeColor = this.colors.hex;
    path.strokeWidth = this.toolWidth;
    path.strokeJoin = 'round';
    path.strokeCap = 'round';
  }

  /**
   * Creates a new tool for the canvas
   * @param {any} scope - the paper library scope
   * @return {paper.Tool} - new paper tool for this scope
   */
  createTool(scope: any): paper.Tool {
    scope.activate();
    return new paper.Tool();
  }

  /**
   * Method handles the mouse down event
   */
  mouseDown() {
    if (this.showPicker) {
      this.closePicker();
      return;
    }
    if (this.showSlider) {
      this.closeSlider();
      return;
    }
    const self = this;
    const tool = this.createTool(this.scope);
    tool.onMouseDown = (event: any) => {
      if (this.showPicker) {
        this.closePicker();
        return;
      }
      const point = event.point;
      if (this.selectedTool === 'line') {
        self.path = self.createLine(self.scope);
        self.path.add(point);
      } else {
        this.firstPoint = point;
      }
    };
    tool.onMouseDrag = (event: any) => {
      if (this.showPicker) {
        return;
      }
      const point = event.point;
      if (this.selectedTool === 'line') {
        self.path.add(event);
      }
      if (this.selectedTool === 'circle') {
        this.secondPoint = point;
        self.path = this.createCircle(self.scope, this.firstPoint, this.secondPoint);
      }
      if (this.selectedTool === 'square') {
        this.secondPoint = point;
        self.path = this.createSquare(self.scope, this.firstPoint, this.secondPoint);
      }
      if (this.selectedTool === 'arrow') {
        this.secondPoint = point;
        self.path = this.createArrow(self.scope, this.firstPoint, this.secondPoint);
      }
    };
    tool.onMouseUp = (event: any) => {
      if (this.showPicker) {
        return;
      }
      const point = event.point;
      if (this.selectedTool === 'line') {
        self.path.add(point);
      }
      if (self.selectedTool === 'square') {
        this.secondPoint = point;
        self.path = this.createSquare(self.scope, this.firstPoint, this.secondPoint);
        this.resetPoints();
      }
      if (this.selectedTool === 'circle') {
        this.secondPoint = point;
        self.path = this.createCircle(self.scope, this.firstPoint, this.secondPoint);
        this.resetPoints();
      }
      if (this.selectedTool === 'arrow') {
        this.secondPoint = point;
        // Added to stop a bug where a single click would draw a short line
        if (Math.abs(point.x-this.firstPoint.x) > 1 && Math.abs(point.y-this.firstPoint.y) > 1) {
          self.path = this.createArrow(self.scope, this.firstPoint, this.secondPoint);
        }
        this.resetPoints();
      }
    };
  }

  /**
   * Resets all the line points
   */
  resetPoints() {
    this.firstPoint = null;
    this.secondPoint = null;
    this.first = true;
  }

  /**
   * Getter for the fill colour currently selected
   */
  get fillColour() {
    return {
      'color': this.colors.hex,
    };
  }

  /**
   * Getter for selected style for button
   */
  get selected() {
    return {
      'border-width': '2px',
      'border-style': 'solid',
      'border-color': this.colors.hex,
      'border-radius': '15px',
    };
  }

  /**
   * Getter for background url for canvas
   */
  get background() {
    return this.selectedFile.original_url;
    // return pexipStore.actions.annotation.url;
  }

  /**
   * Getter for the selected file image from the local state
   */
  get selectedFile(): any {
    const file = messageStore.__GETTERselectedFile;
    const fHeight = this.fileHeight;
    let height = this.fileHeight;
    const fWidth = this.fileWidth;
    let width = this.fileWidth;

    if (width > window.innerWidth - 330) {
      width = window.innerWidth - 480;
      const percentageChange = (width / fWidth);
      this.percentageChange = percentageChange;
      this.modalWidth = width;
      this.modalHeight = height * percentageChange;
    } else if (height > window.innerHeight) {
      height = window.innerHeight - 50;
      const percentageChange = (height / fHeight);
      this.percentageChange = percentageChange;
      this.modalHeight = height;
      this.modalWidth = width * percentageChange;
    } else {
      this.modalHeight = this.fileHeight;
      this.modalWidth = this.fileWidth;
    }
    return file;
  }
  /**
   * Getter for the selected file image from the local state
   */
  get fileHeight(): number {
    return messageStore.__GETTERselectedFileHeight;
  }
  /**
   * Getter for the selected file image from the local state
   */
  get fileWidth(): number {
    return messageStore.__GETTERselectedFileWidth;
  }
}
