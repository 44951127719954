import store from './index';
import {VuexModule, Module, Mutation, Action} from 'vuex-class-modules';

/**
 *
 */
@Module
class MainStore extends VuexModule {
  // State
  status = '';
  previousRoute = {} as any;
  protectedRoutes = [] as any;
  notificationsGranted = false;

  // Mutations

  /**
   *
   * @param {string} status
   */
  @Mutation
  private __setStatusState(status: string) {
    this.status = status;
  }

  /**
   *
   * @param {any} prevRoute
   */
  @Mutation
  private __setPreviousRoute(prevRoute: any) {
    this.previousRoute = prevRoute;
  }

  /**
   *
   * @param {any[]} protectedRoutes
   */
  @Mutation
  private __setProtectedRoutes(protectedRoutes: any[]) {
    this.previousRoute = protectedRoutes;
  }

  /**
   * Mutation sets the state for the notificationsGranted value
   * @param {boolean} state - the value to set the state to
   */
  @Mutation
  private __setNotificationsGrantedState(state: boolean) {
    this.notificationsGranted = state;
  }

  // Actions
  /**
   * Set the status string for the application allows for global events
   * @param {string} status - status string to set
   */
  @Action
  async setStatus(status: string) {
    this.__setStatusState(status);
  }

  /**
   * Set the global status to loading
   */
  @Action
  async setLoading() {
    this.__setStatusState('loading');
  }

  /**
   * Set the global status to success
   */
  @Action
  async setSuccess() {
    this.__setStatusState('success');
  }

  /**
   * Set the global status to error
   */
  @Action
  async setError() {
    this.__setStatusState('error');
  }

  /**
   * Clear the global status
   */
  @Action
  async clearStatus() {
    this.__setStatusState('');
  }

  /**
   * Set the previous route globally to enable back navigation
   * @param {any} route - the previous route object to set
   */
  @Action
  async setPrevRoute(route: any) {
    this.__setPreviousRoute(route);
  }

  /**
   * Protect given routes in the platform to stop users being able to set them as alias and break the platform
   * @param {any[]} routes - array of routes that are protected and cannot be used
   */
  @Action
  async protectRoutes(routes: any[]) {
    this.__setProtectedRoutes(routes);
  }

  /**
   * Action to set the notifications permissions granted state
   * @param {boolean} value - value to set the state to
   */
  @Action
  async setNotificationsGranted(value: boolean) {
    this.__setNotificationsGrantedState(value);
  }

  /**
   * Action to request the notification API permission
   */
  @Action
  async requestNotificationPermissions() {
    if (Notification.permission === 'granted') {
      this.setNotificationsGranted(true);
    } else {
      Notification.requestPermission()
          .then((permission) => {
            if (permission === 'granted') {
              this.setNotificationsGranted(true);
            }
          })
          .catch((error) => {
            console.error(error);
          });
    }
  }

  // Getters

  /**
   *
   */
  get __GETTERstatus() {
    return this.status;
  }

  /**
   *
   */
  get __GETTERpreviousRoute() {
    return this.previousRoute;
  }

  /**
   *
   */
  get __GETTERprotectedRoutes() {
    return this.protectedRoutes;
  }

  /**
   *
   */
  get __GETTERnotificationGrantedState() {
    return this.notificationsGranted;
  }
}

export const mainStore = new MainStore({store, name: 'MainStore'});
