import Vue from 'vue';
import VueRouter from 'vue-router';
import {userStore} from '@/store/__STORE_user';
import {authStore} from '@/store/__STORE_auth';
import {mainStore} from '@/store/__STORE_main';
// import Home from '../views/Home'

const Login = () => import('../views/Login.vue');
import Register from '../views/Register.vue';
const ForgotPassword = () => import( '../views/ForgotPassword');
const ChangePassword = () => import( '../views/ChangePassword');
const Dashboard = () => import( '../views/Dashboard.vue');
const OperationalDashboard = () => import( '../views/OperationalDashboard/OperationalDashboard.vue');
const EmailVerify = () => import( '../views/EmailVerify.vue');
import RegisterViaInvite from '../views/RegisterViaInvite';
const NeedToVerifyEmail = () => import( '../views/NeedToVerifyEmail');
const SSOConfirm = () => import('../views/__VIEW_SSOConfirm.vue');

// Drilldowns
const EndpointsDrilldown = () => import( '../views/OperationalDashboard/Endpoints-Drilldown.vue');
const RoomUsageDrilldown = () => import( '../views/OperationalDashboard/RoomUsage-Drilldown.vue');
const MeetingsByDateDrilldown = () => import( '../views/OperationalDashboard/MeetingsByDate-Drilldown.vue');
const JoinMeeting = () => import( '../views_v2/JoinMeeting/JoinMeeting/__VIEW_JoinMeeting');
const WelcomeGuest = () => import( '../views/JoinMeeting/Welcome/WelcomeGuest');
const Admin = () => import( '../views/Admin/Index');
const UserManagement = () => import( '../views/Admin/UserManagement');
const EditUser = () => import( '../views/Admin/EditUser');
const DeviceSetup = () => import( '../views/Admin/DeviceSetup');
const CreateUser = () => import( '../views/Admin/CreateUser');
const PlansRole = () => import( '../views/Admin/PlansRole');
const PlansRolePP = () => import('../views/Admin/PlansRolePP');
const BusinessBranding = () => import( '../views/Admin/BusinessBranding');

const Personal = () => import( '../views/Personal/Index');
const Meetings = () => import( '../views/Personal/Meetings');
const Settings = () => import( '@/views_v2/Personal/__VIEW_Settings');
const PersonalBranding = () => import( '../views/Personal/PersonalBranding');
const ExpertSeat = () => import( '../views/ExpertSeat');

const CreateMeeting = () => import( '@/views_v2/Meetings/__VIEW_CreateMeeting');
const Recordings = () => import( '../views_v2/Personal/__VIEW_RecordingsTranscripts');

const WelcomeAuthorised = () => import( '../views/JoinMeeting/Welcome/WelcomeAuthorised');
const MeetingInvites = () => import( '../views/Personal/MeetingInvites');
const Account = () => import( '../views/Account/Index');
import BusinessProfile from '../views/Account/BusinessProfile';
const Billing = () => import( '../views/Account/Billing');

const Invoices = () => import( '../views/Account/Invoices');
import OngoingMeeting from '../views/JoinMeeting/OngoingMeeting/OngoingMeeting';
const AfterCall = () => import( '../views_v2/JoinMeeting/AfterCall/__VIEW_AfterCall');
const AlternateDialInPage = () => import( '@/views/AlternateDialInMethods_GUEST');

const Notifications = () => import('@/views/Notifications');

Vue.use(VueRouter);

export const routes = [
  {
    path: '/sso-confirm',
    name: 'sso-confirm',
    component: SSOConfirm,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/',
    name: 'home',
    // component: Home,
    redirect: {name: 'login'},
  },
  {
    path: '/alternate_dial_in/:client?',
    name: 'alternate',
    component: AlternateDialInPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/verify-email',
    name: 'need-to-verify',
    props: true,
    component: NeedToVerifyEmail,
    beforeEnter: (to, from, next) => {
      checkVerify(from, next);
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/email/verify/:code',
    name: 'email-verify',
    props: true,
    component: EmailVerify,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/reset-password/:code',
    name: 'reset-password',
    props: true,
    component: ChangePassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/register-via-invite/:code',
    name: 'register-via-invite',
    props: true,
    component: RegisterViaInvite,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/expert-seat',
    name: 'expert-seat',
    component: ExpertSeat,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/join-meeting',
    name: 'join-meeting',
    component: JoinMeeting,
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/host/:webrtcAlias',
    name: 'host',
    component: WelcomeAuthorised,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/host-one-time-meeting',
    name: 'host-one-time-meeting',
    component: WelcomeAuthorised,
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      blockNonProvisioned(next, '/dashboard');
    },
  },
  {
    path: '/admin',
    component: Admin,
    meta: {
      requiresAuth: true,
    },
    children: [

      {
        path: '',
        name: 'admin',
        redirect: {name: 'user-management'},
      },
      {
        path: 'user-management',
        name: 'user-management',
        component: UserManagement,
      },
      {
        path: 'edit-user/:id',
        name: 'edit-user',
        component: EditUser,
      },
      {
        path: 'device-setup',
        name: 'device-setup',
        component: DeviceSetup,
      },
      {
        path: 'create-user',
        name: 'create-user',
        component: CreateUser,
      },
      {
        path: 'plans-role/:id',
        name: 'plans-role',
        component: PlansRole,
        props: true,
        beforeEnter: (to, from, next) => {
          blockProvisioned(next, '/admin');
        },
      },
      {
        path: 'account-plan',
        name: 'account-plan',
        component: PlansRole,
        props: true,
        beforeEnter: (to, from, next) => {
          blockProvisioned(next, '/admin');
        },
      },
      {
        path: 'user-edit/:id',
        name: 'pp-user-edit',
        component: PlansRolePP,
        props: true,
        beforeEnter: (to, from, next) => {
          blockNonProvisioned(next, '/admin');
        },
      },
      {
        path: 'self-edit/',
        name: 'pp-self-edit',
        component: PlansRolePP,
        props: true,
        beforeEnter: (to, from, next) => {
          blockNonProvisioned(next, '/admin');
        },
      },
      {
        path: 'business-branding',
        name: 'business-branding',
        component: BusinessBranding,
      },
      {
        path: 'operational-dashboard',
        name: 'operational-dashboard',
        component: OperationalDashboard,
        meta: {
          requiresAuthAdmin: true,
        },
        beforeEnter: (to, from, next) => {
          checkForRole(next);
        },
      },
      {
        path: 'room-usage',
        name: 'room-usage',
        component: RoomUsageDrilldown,
        meta: {
          requiresAuthAdmin: true,
        },
        beforeEnter: (to, from, next) => {
          checkForRole(next);
        },
      },
      {
        path: 'meetings-by-date',
        name: 'meetings-by-date',
        component: MeetingsByDateDrilldown,
        meta: {
          requiresAuthAdmin: true,
        },
        beforeEnter: (to, from, next) => {
          checkForRole(next);
        },
      },
      {
        path: 'endpoints',
        name: 'endpoints',
        component: EndpointsDrilldown,
        meta: {
          requiresAuthAdmin: true,
        },
        beforeEnter: (to, from, next) => {
          checkForRole(next);
        },
      },
    ],
  },
  {
    path: '/meetings',
    component: Personal,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'meetings',
        component: Meetings,
      },
      {
        path: 'meeting/:id?',
        name: 'meeting',
        component: CreateMeeting,
        props: true,
        beforeEnter: (to, from, next) => {
          checkUserHasLicense(next, '/meetings');
        },
      },
      {
        path: 'meeting-invites/:id',
        name: 'meeting-invites',
        component: MeetingInvites,
        props: true,
      },
      {
        path: 'meeting-updated/:id',
        name: 'meeting-updated',
        component: MeetingInvites,
        props: true,
      },
    ],
  },
  {
    path: '/storage',
    name: 'recordings',
    component: Recordings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/branding',
    name: 'personal-branding',
    component: PersonalBranding,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/personal',
    component: Personal,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'personal',
        redirect: {name: 'profile'},
      },
    ],
  },
  {
    path: '/account',
    component: Account,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'account',
        redirect: {name: 'business-profile'},
      },
      {
        path: 'business-profile',
        name: 'business-profile',
        component: BusinessProfile,
      },
      {
        path: 'billing',
        name: 'billing',
        component: Billing,
        beforeEnter: (to, from, next) => {
          blockProvisioned(next, '/account');
        },
      },
      {
        path: 'invoices',
        name: 'invoices',
        component: Invoices,
        beforeEnter: (to, from, next) => {
          blockProvisioned(next, '/account');
        },
      },
    ],
  },
  {
    path: '/ongoing-meeting/:webrtcAlias',
    name: 'ongoing-meeting',
    component: OngoingMeeting,
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/after-call/:webrtcAlias',
    name: 'after-call',
    component: AfterCall,
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/:webrtcAlias',
    name: 'welcome-guest',
    component: WelcomeGuest,
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
];

const checkForRole = (next) => {
  if (typeof userStore.__GETTERuserAccount === undefined) {
    userStore.getUserProfile().then((response) => {
      const allowedAccounts = ['Trial', 'Business', 'Pro'];
      const isAllowedAccount = allowedAccounts.includes(userStore.__GETTERuserAccount);
      if (isAllowedAccount && (userStore.__GETTERisUserRoleAdmin || userStore.__GETTERisUserOnTrial) ? next() : next('/dashboard'));
    }, (error) => {

    });
  } else {
    next();
  }
};

const blockProvisioned = (next, route) => {
  if (typeof userStore.__GETTERisUserProvisioned === undefined) {
    userStore.getUserProfile().then((response) => {
      if (userStore.__GETTERisUserProvisioned ? next(route) : next());
    }, (error) => {

    });
  } else {
    if (userStore.__GETTERisUserProvisioned ? next(route) : next());
  }
};

const blockNonProvisioned = (next, route) => {
  if (typeof userStore.__GETTERisUserProvisioned === undefined) {
    userStore.getUserProfile().then((response) => {
      if (userStore.__GETTERisUserProvisioned ? next() : next(route));
    }, (error) => {

    });
  } else {
    if (userStore.__GETTERisUserProvisioned ? next() : next(route));
  }
};

const checkUserHasLicense = (next, route) => {
  if (typeof userStore.__GETTERisUserProvisioned === undefined) {
    userStore.getUserProfile().then((response) => {
      if (userStore.__GETTERisUserOnNoLicense ? next(route) : next());
    }, (error) => {

    });
  } else {
    if (userStore.__GETTERisUserOnNoLicense ? next(route) : next());
  }
};

// Stop users being able to navigate to /verify-email
const checkVerify = (from, next) => {
  if ((from.name != 'login' && from.name != 'register' && from.name != 'email-verify') || from.path == '/') {
    if (from.path == '/') {
      next('/');
    }
    next(false);
  }
  next();
};

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL ? process.env.BASE_URL : '/dist',
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (authStore.__GETTERisUserLoggedIn) {
      next();
      return;
    }
    next('/login');
  } else {
    if (to.name === 'login' && authStore.__GETTERisUserLoggedIn) {
      next('/dashboard');
      return;
    }

    if (to.params && to.params.previousRoute) {
      mainStore.setPrevRoute(to.params.previousRoute);
    } else {
      mainStore.setPrevRoute({});
    }

    if (to.query.userId && !authStore.__GETTERisUserLoggedIn) {
      authStore.loginAs(to.query.userId).then(() => next('/dashboard'));
      return;
    }
    next();
  }
});

export default router;
