<template>
  <div
    class="auto-dial-container"
  >
    <label>Address</label>
    <input
      :value="value"
      type="text"
      class="form-control"
      :readonly="!editing"
      @input="$emit('input', $event.target.value)"
    >
  </div>
</template>

<script>
export default {
  name: 'CallGroupAutoDialAddress',
  props: {
    value: {
      type: String,
      required: true,
    },
    editing: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.auto-dial-container input.form-control {
  font-size: 1.8rem;
  height: 5.4rem;
  line-height: 2.5rem;
  padding: 1.6rem 2.5rem;
}
</style>
