
import Component, {mixins} from 'vue-class-component';
import {PropSync} from 'vue-property-decorator';
import {Responsive} from '@/mixins/Responsive';
import {Participant} from '@/mixins/Participant';
import {pexipStore} from '@/store/__STORE_pexip';

import IconStreamingOff from '@/components/icons/IconStreamingOff.vue';
import {IconLightbulb, IconMicSmall, IconMicSmallMute} from '@/components/icons';

@Component({
  components: {
    IconStreamingOff,
    IconLightbulb,
    IconMicSmall,
    IconMicSmallMute,
  },
})
/**
 * Intelligent component to control the actions that can be performed on a participant within a conference
 */
export default class ParticipantActions extends mixins(Responsive, Participant) {
  name: 'Participant Actions'

  @PropSync('participant', {type: Object, default: () => {}}) readonly __PROPparticipant: any // participant that the actions are shown for

  /**
   *
   */
  get isPresentationFeedActive() {
    return pexipStore.actions.screenShare.stream;
  }
  /**
   * Returns whether the user is a host or not for control purposes
   */
  get isHost() {
    return pexipStore.call.isHost;
  }

  /**
   * Gets the user's uuid from the pexip store
   */
  get myUuid() {
    return pexipStore.__GETTERmyUuid;
  }

  /**
   * Gets whether annotation is allowed from the pexip store
   */
  get annotationAllowed() {
    return pexipStore.actions.annotation.allowed;
  }

  /**
   * Gets whether the current user is the room owner or not
   */
  get isRoomOwner() {
    return pexipStore.__GETTERisRoomOwner;
  }

  /**
   * Unlock the participant if they are in the waiting room
   */
  unlockParticipant() {
    pexipStore.unlockParticipant(this.__PROPparticipant);
  }

  /**
   * Toggle the role of the participant
   */
  toggleParticipantRole() {
    if (this.__PROPparticipant.role === 'guest') {
      pexipStore.setParticipantToHost(this.__PROPparticipant);
    } else {
      pexipStore.setParticipantToGuest(this.__PROPparticipant);
    }
  }

  /**
   * Toggle the mute state of the participant
   */
  toggleParticipantMute() {
    pexipStore.toggleParticipantMute(this.__PROPparticipant);
  }

  /**
   * Toggle the spotlight state of the participant
   */
  toggleParticipantSpotlight() {
    pexipStore.toggleParticipantSpotlight(this.__PROPparticipant);
  }

  /**
   * Start the annotation for the selected participant
   */
  startAnnotation() {
    if (!this.isPresentationFeedActive) {
      pexipStore.startAnnotation(this.__PROPparticipant);
    }
  }

  /**
   * Disconnect the selected participant
   */
  disconnectParticipant() {
    pexipStore.disconnectParticipant(this.__PROPparticipant);
  }

  /**
   * Stop the recording if active
   */
  stopRecording() {
    pexipStore.stopRecording();
  }

  /**
   * Stops the stream
   */
  async stopStreaming() {
    await pexipStore.stopLiveStream();
  }
}
