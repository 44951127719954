
import Component, {mixins} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

import IconStreaming from '@/components/icons/IconStreaming.vue';
import IconStreamingOff from '@/components/icons/IconStreamingOff.vue';
import IconInfo from '@/components/icons/IconInfo.vue';
import IconTrashPrimary from '@/components/icons/IconTrashPrimary.vue';
import CallGroupAutoDialProtocol from '@/components/CallGroupAutoDialProtocol.vue';
import CallGroupAutoDialAddress from '@/components/CallGroupAutoDialAddress.vue';
import LiveStreamForm from '@/components_v2/generic/form/__COMP_LiveStream.vue';
import {CopyToClipboard} from '@/mixins/CopyToClipboard.ts';

import {pexipStore} from '@/store/__STORE_pexip';
import {callStore} from '@/store/__STORE_call';

@Component({
  components: {
    IconStreaming,
    IconStreamingOff,
    IconInfo,
    IconTrashPrimary,
    CallGroupAutoDialProtocol,
    CallGroupAutoDialAddress,
    LiveStreamForm,
  },
})
/**
 *
 */
export default class AddtoCallContainer extends mixins(CopyToClipboard) {
  @Prop({default: null}) webrtcAlias: string;

  selectedTab: string = 'invite';
  txtEmail: string = '';
  emailList: Array<any> = [];
  protocol: string = 'auto';
  address: string = '';
  error: boolean = false;
  // eslint-disable-next-line
  emailRegex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
  );

  /**
   * Check if the curent user owns the room
   */
  get isRoomOwner() {
    return pexipStore.__GETTERisRoomOwner;
  }

  /**
   * Feedback on if the VMR currently has a live stream enabled
   */
  get isLiveStreamActive() {
    return pexipStore.__GETTERisLiveStreamingActive;
  }

  /**
   * Returns whether there are emails to send
   * @return {boolean}
   */
  get hasEmails(): boolean {
    return this.emailList.length < 1;
  }

  /**
   * Returns whether the dial out fields are filled
   * @return {boolean}
   */
  get hasDialOutDetails(): boolean {
    return this.address.length > 0;
  }

  /**
   * Returns whether the VMR has a video device gateway license
   * @return {boolean}
   */
  get hasVideoLicense(): boolean {
    return callStore.__GETTERhasVideoLicense;
  }

  /**
   * Returns if the Invites Tab is selected
   * @param {string} tab - selected tab
   * @return {boolean}
   */
  isTabSelected(tab: string): boolean {
    return this.selectedTab === tab;
  }

  /**
   * Set the selected Tab
   * @param {string} tab
   */
  selectTab(tab) {
    this.selectedTab = tab;
  }

  /**
   * Copy the url to the clipboard
   */
  copyUrl() {
    this.__MIXINcopyToClipboard(this.$refs.txtUrl);
    this.$emit('close-invite');
  }

  /**
   * Directly link to the Guest Join Call page
   * @return {string} link URL
   */
  directLink() {
    const a = document.createElement('a');
    a.href = this.$router.resolve({
      name: 'welcome-guest',
      params: {
        webrtcAlias: this.webrtcAlias,
      },
    }).href;
    return a.href;
  }

  /**
   * Check whether the email is valid
   * @return {boolean}
   */
  isEmailValid() {
    return this.emailRegex.test(this.txtEmail);
  }

  /**
   * Triggered by a coma being entered
   * Add email to the emailList if it doesn't already exist
   * @param {any} event - event object
   */
  addEmail(event) {
    if (event.key === ',') {
      this.error = false;
      const curEmail = this.txtEmail.slice(0, -1);
      if (this.emailRegex.test(curEmail)) {
        if (this.emailList.filter((e) => e.email == curEmail).length == 0) {
          this.emailList.push({
            email: curEmail,
            isSelected: false,
          });
        }
        this.txtEmail = '';
      } else {
        if (this.txtEmail != '') {
          this.error = true;
        }
        this.txtEmail = curEmail;
      }
    }
  }

  /**
   * Add email to the emailList if it doesn't already exist
   */
  checkEmail() {
    if (this.emailRegex.test(this.txtEmail)) {
      if (this.emailList.filter((e) => e.email == this.txtEmail).length == 0) {
        this.emailList.push({
          email: this.txtEmail,
          isSelected: false,
        });
      }
      this.txtEmail = '';
      this.error = false;
    } else {
      if (this.txtEmail != '') {
        this.error = true;
      }
    }
  }

  /**
   * Delete selected email from the emailList
   */
  deleteSelectedItem() {
    if (this.emailList.filter((item) => item.isSelected).length > 0) {
      this.emailList = this.emailList.filter((item) => !item.isSelected);
    }
  }

  /**
   * If emailList has emails, emit 'send-emails'
   */
  sendEmails() {
    const emails = this.emailList.map((e) => e.email);
    if (emails.length > 0) {
      this.$emit('send-emails', emails);
    }
  }

  /**
   * Dial Endpoint
   */
  dialEndpoint() {
    if (this.address.length > 0) {
      this.$emit('dial-endpoint', {
        protocol: this.protocol,
        address: this.address,
      });
    }
  }
  /**
   * Start a live stream
   *
   * @param {string} name - name fo the participant
   * @param {string} url - the stream url
   * @param {string} key - the stream key
   * @return {*}
   */
  async startStreaming(name: string, url: string, key: string) {
    this.resetErrors();

    if (pexipStore.findParticipantByName(name)) {
      return this.$toast.error(
          `There Is A Participant Named ${name} Already In The Meeting`,
      );
    }
    pexipStore
        .startLiveStream({
          name,
          url,
          key,
          ack: true,
        })
        .then(() => {
          this.$toast.success('Live Stream Started');
        })
        .catch((err: any) => {
          this.$toast.error('Failed To Start The Live Stream');
          this.error = true;
        });
  }

  /**
   * Stop live streaming
   */
  async stopStreaming() {
    await pexipStore.stopLiveStream();
    this.$emit('close-invite');
  }
  /**
   * Reset all error flags
   */
  resetErrors() {
    this.error = false;
  }
}
