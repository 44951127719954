<template>
  <header>
    <nav
      class="navbar navbar-expand-md z-index"
    >
      <ul class="container-fluid navbar-nav row mx-0">
        <li
          class="nav-item col-2 mx-0"
          @click="removeGuestpin"
        >
          <router-link
            :to="{name: innerPreviousRoute.name, params: innerPreviousRoute.params}"
            class="nav-link nav-icon d-none d-lg-block go-back"
          >
            <img
              v-svg-inline
              src="@/assets/img/icons/ic_go-back.svg"
              class="mr-1"
            >
            Go Back
          </router-link>
        </li>

        <li
          class="nav-item col-12 col-md-8 text-center mx-0"
        >
          <router-link
            class="navbar-brand pt-2"
            :to="{
              name: 'dashboard',
              params: {
                'reloadRequired': true,
              },
            }"
          >
            <img
              v-if="logoFetched"
              alt="Brand Logo"
              :src="logo"
            >
          </router-link>
          <div class="py-2">
            <h5>
              {{ guestWelcomeMessage }}
            </h5>
          </div>
        </li>
        <li class="nav-item col-2 text-right mx-0" />
      </ul>
    </nav>
  </header>
</template>
<script>
import {brandingStore} from '@/store/__STORE_branding';
import {mainStore} from '@/store/__STORE_main';
import {callStore} from '@/store/__STORE_call';
export default {
  data() {
    return {
      innerPreviousRoute: {
        name: '',
        params: {},
      },
      branding: {},
      logoFetched: false,
    };
  },
  computed: {
    // logo: (state) => state.auth.logo,
    businessLogo: () => brandingStore.businessLogo,
    guestWelcomeMessage: () => brandingStore.guestWelcomeMessage,
    isLoading() {
      return mainStore.status === 'loading';
    },
    brand() {
      return callStore.vmr.brand;
    },
    logo() {
      if (this.branding.logo) {
        // set logo
        return this.branding.logo;
      } else {
        // render stnard logo
        return '/img/sv-primary-logo-col.png';
      }
    },
  },
  watch: {
    $route() {
      this.updatePreviousRoute();
    },
  },
  mounted() {
    this.updatePreviousRoute();
    this.refresh();
  },
  methods: {
    getVMR: callStore.getVMR,
    async refresh() {
      this.logoFetched = false;
      if (!this.$route.params.webrtcAlias) {
        this.$toast.error('No Alias Provided In URL');
      }
      const alias = this.$route.params.webrtcAlias;
      await this.getVMR(alias).catch((err) => {
        // silently fail
      });
      this.branding = this.brand;
      this.logoFetched = true;
    },
    removeGuestpin() {
      localStorage.removeItem('guest_pin');
    },
    updatePreviousRoute() {
      const previousRoute = {name: 'welcome-guest'};

      if (
        this.$router.currentRoute.name === 'video-device-instructions' ||
        this.$router.currentRoute.name === 'microsoft-instructions' ||
        this.$router.currentRoute.name === 'phone-instructions'
      ) {
        previousRoute.name = 'other-ways-to-join';
      }

      if (
        this.$router.currentRoute.name === 'alpha-numeric-instructions' ||
        this.$router.currentRoute.name === 'numeric-instructions'
      ) {
        previousRoute.name = 'video-device-instructions';
      }

      if (
        this.$router.currentRoute.name === 'teams-instructions' ||
        this.$router.currentRoute.name === 'skype-instructions'
      ) {
        previousRoute.name = 'microsoft-instructions';
      }

      if (this.$router.currentRoute.name === 'host') {
        previousRoute.name = 'dashboard';
        previousRoute.params = {
          reloadRequired: true,
        };
      }

      if (this.$router.currentRoute.name === 'welcome-guest') {
        // localStorage.removeItem('guest_pin');
        previousRoute.name = 'join-meeting';
        previousRoute.params = {
          reloadRequired: true,
        };
      } else {
        previousRoute.params = {
          webrtcAlias: this.$router.currentRoute.params.webrtcAlias,
          reloadRequired: true,
        };
      }

      this.innerPreviousRoute = previousRoute;
    },
  },
};
</script>

<style lang="scss" scoped>
  .go-back ::v-deep svg {
    fill: #c8c9cc;
    vertical-align: top;
  }

  .z-index {
    z-index: 9999;
  }
</style>
