import Vue from 'vue';
import Component from 'vue-class-component';
import {pexipStore} from '@/store/__STORE_pexip';

@Component
/**
 * Mixin for participant actions
 */
export class Participant extends Vue {
  /**
   * Returns the name of the participant
   * It returns the participant name to display
   * @param {any} participant - The participant object to get the name for
   * @return {string} displayName - The display name to show
   */
  __MIXINshowParticipantName(participant:any): string {
    if (participant.display_name && (participant.display_name === pexipStore.actions.recording.participantName)) {
      return 'Recorder';
    }

    // Twilio participants do not have display_name. They only have overlay_text, e.g. "sip:+37124336554@pstn.twilio.com"
    let displayName = participant.display_name || participant.overlay_text;
    displayName = displayName.replace('sip:', '').replace('@pstn.twilio.com', '');

    return displayName;
  }

  /**
   * When room is "locked by default", all guests in the lobby will have service_type === 'waiting_room' until host
   * lets them in. We can use this property to show "Allow in the room" or "Deny access" options for each guest.
   *
   * However, if room is not "locked by default", there is a small caveat with this approach.
   * When host joins the room, even if the room was not locked, FOR A FEW SECONDS all guest participants
   * will have service_type === 'waiting_room'. This causes a bug where we show "Allow in the room" / "Deny access"
   * options for a few seconds even though they are not relevant - room isn't locked.
   *
   * @param {any} participant
   * @return {boolean} - whether the participant should have lock options shown on them
   */
  __MIXINshouldShowLockOptions(participant: any): boolean {
    // The solution is to check for 'waiting_room' only if room is actually locked:
    if (participant.display_name === pexipStore.actions.recording.participantName && !pexipStore.__GETTERisRoomOwner) {
      return false;
    }
    return pexipStore.call.isLocked && (participant.service_type === 'waiting_room');
  }

  /**
   * Format the user's time string into a given string format of 00:00:00
   * @param {string} time - the participant time string
   * @return {string} - formatted string
   */
  __MIXINparticipantTime(time: string): string {
    if (time) {
      let overHour = false;
      const splitTime = time.split(':');
      let timeTemp = '';
      if (parseInt(splitTime[0], 10) < 60) {
        timeTemp = '0:' + time;
      } else {
        overHour = true;
        const str = time + '';
        const splitTime = str.split(':');
        let s = 0; let m = 1;

        while (splitTime.length > 0) {
          s += m * parseInt(splitTime.pop(), 10);
          m *= 60;
        }

        if (s >= 3600) {
          const hours = Math.floor(s / 3600);
          const strHours = (hours).toLocaleString('en-US', {minimumIntegerDigits: 1, useGrouping: false});
          const minutes = Math.floor(s % 3600 / 60);
          const strMinutes = (minutes).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
          const seconds = Math.floor(s % 3600 % 60);
          const strSeconds = (seconds).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});

          timeTemp = strHours + ':' + strMinutes + ':' + strSeconds;
        }
      }
      if (Date.parse('01/01/2000 '+timeTemp) >= Date.parse('01/01/2000 0:0:00')) {
        return (overHour) ? timeTemp : time;
      }
      return '0:00';
    }
  }

  /**
   * Returns whether the participant is screensharing or not
   * @param {any} participant - participant object
   * @return {boolean} - whether the participant is screen sharing or not
   */
  __MIXINisParticipantShared(participant: any): boolean {
    return (participant.is_presenting === 'YES');
  }

  /**
   * Returns whether the given participant is spotlighted or not
   * @param {any} participant - participant object
   * @return {boolean} - whether the participant is spotlighted or not
   */
  __MIXINisParticipantSpotlighted(participant: any): boolean {
    return participant.spotlight > 0;
  }

  /**
   * Returns whether the given participant is a host or not
   * @param {any} participant - participant object
   * @return {boolean} - whether the participant is a host
   */
  __MIXINisParticipantHost(participant: any): boolean {
    return participant.role === 'chair';
  }

  /**
   * Returns whether the given participant is muted or not
   * @param {any} participant - participant object
   * @return {boolean} - whether the participant is muted
   */
  __MIXINisParticipantMuted(participant: any): boolean {
    return participant.is_muted === 'YES';
  }

  /**
   * Returns whether the given participant is a recorder or not
   * @param {any} participant - participant object
   * @return {boolean} - whether the given participant is the recorder or not
   */
  __MIXINisParticipantRecorder(participant:any): boolean {
    return participant.display_name && (participant.display_name === pexipStore.actions.recording.participantName);
  }

  /**
   * Function to check whether a participant is connected via rtmp protocol and thus is streaming
   * @param {any} participant - pexip participant object to evaluate
   * @return {boolean} - whether the participant is connected via rtmp protocol
   */
  __MIXINisParticipantStreaming(participant: any): boolean {
    return participant.protocol && participant.protocol === 'rtmp';
  }
}
