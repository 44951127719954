import axios from 'axios';
axios.defaults.headers = {
  ...axios.defaults.headers,
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};
import store from './';
import {VuexModule, Module, Mutation, Action} from 'vuex-class-modules';
import {mainStore} from './__STORE_main';
import {callStore} from './__STORE_call';

/**
 *
 */
@Module
class BrandingStore extends VuexModule {
  defaultPrimaryColor = '#029191'; // SV Branding
  defaultSecondaryColor = '#bd1987'; // SV Branding

  personalBrand = {} as any;
  businessBrand = {
    primary_color_hex: '#029191',
    secondary_color_hex: '#bd1987',
  } as any;
  guestWelcomeMessage = '';
  logo = '';
  businessLogo = '';

  /**
   *
   * @param {Object} data
   */
  @Mutation
  private __setPersonalBrand(data: Object) {
    this.personalBrand = data;
  }

  /**
   *
   * @param {Object} data
   */
  @Mutation
  private __setBusinessBrand(data: Object) {
    this.businessBrand = data;
  }

  /**
   *
   * @param {Object} data
   */
  @Mutation
  private __updateBusinessBrandInfo(data: any) {
    this.businessBrand.guestWelcomeMessage = data.guestWelcomeMessage;
    this.businessBrand.business_name = data.business_name;
    this.businessBrand.subdomain = data.subdomain;
  }

  /**
   *
   * @param {Obejct} data
   */
  @Mutation
  private __updateBusinessBrandColors(data: any) {
    this.businessBrand.primary_color_hex = data.primary_color_hex;
    this.businessBrand.secondary_color_hex = data.secondary_color_hex;
  }

  /**
   *
   * @param {string} guestWelcomeMessage
   */
  @Mutation
  private __setGuestWelcomeMessage(guestWelcomeMessage: string) {
    this.guestWelcomeMessage = guestWelcomeMessage;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setLogo(data: any) {
    if (data.logo) {
      if (data.level === 'business') {
        this.logo = data.logo;
        this.businessLogo = data.logo;
      } else {
        this.logo = data.logo;
        this.businessLogo = '/img/sv-primary-logo-col.png';
      }
    } else {
      this.logo = '/img/sv-primary-logo-col.png';
      this.businessLogo = '/img/sv-primary-logo-col.png';
    }
  }

  /**
   *
   * @return {Promise} api call response
   */
  @Action
  async getPersonalBrand(): Promise<any> {
    callStore.clearVMR();
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: '/personal-brand', method: 'GET'})
          .then((resp) => {
            this.__setPersonalBrand( resp.data);
            mainStore.setStatus('success');
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   *
   * @return {Promise} api call response
   */
  @Action
  async getBusinessBrand(): Promise<any> {
    callStore.clearVMR();
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: '/business-brand', method: 'GET'})
          .then((resp) => {
            this.__setBusinessBrand(resp.data);
            mainStore.setStatus('success');
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   *
   * @param {Object} payload - personal brand update payload
   * @return {Promise} api call response
   */
  @Action
  async updatePersonalBrand(payload: Object): Promise<any> {
    callStore.clearVMR();
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
      axios({url: '/personal-brand', data: payload, method: 'POST'})
          .then((resp) => {
            mainStore.setStatus('success');
            axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
            this.getPersonalBrand();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
            reject(err);
          });
    });
  }

  /**
   *
   * @param {Object} payload - brand update payload
   * @return {Promise} api call response
   */
  @Action
  async updateBrandInfo(payload: Object): Promise<any> {
    callStore.clearVMR();
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: '/business-brand', data: payload, method: 'POST'})
          .then((resp) => {
            this.__updateBusinessBrandInfo(payload);
            mainStore.setStatus('success');
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   *
   * @param {Object} payload - brand colors update payload
   * @return {Promise} api call response
   */
  @Action
  async updateBrandColors(payload: Object): Promise<any> {
    callStore.clearVMR();
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: '/business-brand', data: payload, method: 'POST'})
          .then((resp) => {
            this.__updateBusinessBrandColors( payload);
            mainStore.setStatus('success');
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   *
   * @param {Object} payload - brand image update payload
   * @return {Promise} api call response
   */
  @Action
  async updateBrandImages(payload: Object): Promise<any> {
    callStore.clearVMR();
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
      axios({url: '/business-brand', data: payload, method: 'POST'})
          .then((resp) => {
            mainStore.setStatus('success');
            axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
            this.getBusinessBrand();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
            reject(err);
          });
    });
  }

  /**
   *
   * @param {string} guestWelcomeMessage - welcome message to set
   */
  @Action
  async setGuestWelcomeMessage(guestWelcomeMessage: string) {
    this.__setGuestWelcomeMessage(guestWelcomeMessage);
  }

  /**
   *
   * @param {any} payload - logo payload
   */
  @Action
  async setLogo(payload: any) {
    this.__setLogo(payload);
  }

  // Getters
  /**
   * @return {string} - default primary colour
   */
  get __GETTERdefaultPrimary(): string {
    return this.defaultPrimaryColor;
  }
  /**
   * @return {string} - default secondary colour
   */
  get __GETTERdefaultSecondary(): string {
    return this.defaultSecondaryColor;
  }
  /**
   * @return {string} - brand primary colour
   */
  get __GETTERPrimary(): string {
    return this.businessBrand.primary_color_hex;
  }
  /**
   * @return {string} - brand secondary colour
   */
  get __GETTERSecondary(): string {
    return this.businessBrand.secondary_color_hex;
  }
}


export const brandingStore = new BrandingStore({store, name: 'BrandingStore'});
