
import Component, {mixins} from 'vue-class-component';
import {Responsive} from '@/mixins/Responsive';

import SidebarLogo from '@/components_v2/intelligent/branding/__COMP_SidebarLogo.vue';
import ChatBox from '@/components_v2/intelligent/call/__COMP_ChatBox.vue';
import ParticipantsList from '@/components_v2/intelligent/call/__COMP_ParticipantsList.vue';
import {IconCog} from '@/components/icons';

import {pexipStore} from '@/store/__STORE_pexip';
import {callStore} from '@/store/__STORE_call';

@Component({
  components: {
    IconCog,
    ChatBox,
    SidebarLogo,
    ParticipantsList,
  },
})
/**
 * Intelligent component for managing participants and chat within a conference
 */
export default class MeetingSideBar extends mixins(Responsive) {
  name: 'Meeting Side Bar'

  /**
   * Getter for whether current user is host from pexip store
   */
  get isHost() {
    return pexipStore.call.isHost;
  }

  /**
   * Gets the current meeting room alias from the call store
   */
  get alias() {
    if (callStore.vmr) {
      return callStore.vmr.webrtc.alias;
    }
    return null;
  }

  /**
   * Gets whether settings should be shown from the store
   */
  get isShowParticipantsSettings() {
    return pexipStore.call.isShowParticipantsSettings;
  }

  /**
   * Function to toggle seeing the participant settings
   */
  toggleShowSettings() {
    pexipStore.setIsShowParticipantsSettings(!this.isShowParticipantsSettings);
  }

  /**
   * Function to close the sidebar
   */
  closeSidebar() {
    pexipStore.closeSidebar();
  }
}
