
import Component, {mixins} from 'vue-class-component';
import {PropSync} from 'vue-property-decorator';
import {Chat} from '@/mixins/Chat';
import Message from '@/types/Message';
import ChatFile from '@/types/ChatFile';
import Reactions from '@/components_v2/generic/call/__COMP_Reactions.vue';
import MessageMenu from '@/components_v2/generic/call/__COMP_MessageMenu.vue';

import LinkPreview from '@/components_v2/generic/call/__COMP_LinkPreview.vue';

import * as linkify from 'linkifyjs';
import linkifyStr from 'linkifyjs/string';

import {pexipStore} from '@/store/__STORE_pexip';
import {messageStore} from '@/store/__STORE_message';

@Component({
  components: {
    LinkPreview,
    Reactions,
    MessageMenu,
  },
})
/**
 * Generic component to display a chat message
 */
export default class ChatMessage extends mixins(Chat) {
  name: 'Chat Message'
  isHovered = false;
  hasURL = false;
  linkURL = '';

  @PropSync('message', {type: Object, required: true}) readonly __PROPmessage: Message

  /**
   * Check if the string has a URL.
   * @param {string} message - the message to search
   */
  checkforURL(message: string) {
    const links = linkify.find(message, 'url');

    if (links.length) {
      // Look through the message and find the first url for link previewing
      this.linkURL = links[0].href.replace('http://', 'https://');
      this.hasURL = true;
    }
  }

  /**
   * Method that renders the message into a link and email containing HTML
   * @param {string} message - the message string to encode
   * @return {string} - encoded string to display
   */
  renderMessage(message: string): string {
    this.checkforURL(message);
    // Escapes HTML tags
    let str = linkifyStr(message, {
      defaultProtocol: 'https',
      target: {
        url: '_blank',
        email: '_blank',
      },
    });
    this.chatHistoricParticipants.length ? str = this.styleMentions(str) : null;
    return str;
  }

  /**
   * @param {string} message
   * @return {string}
   */
  styleMentions(message: string): string {
    if (this.__PROPmessage.mention_uuids.length && message.includes('@')) {
      const namesToStyle = [];
      this.__PROPmessage.mention_uuids.forEach((mentionUuid) => {
        const participant = this.chatHistoricParticipants.find((participant) => participant.participant_uuid === mentionUuid);
        namesToStyle.push('@' + participant.display_name);
      });
      namesToStyle.forEach((name) => {
        const styling = '<span style="color: #bd1987;">'+name+'</span>';
        message = message.replaceAll(name, styling);
      });
    }
    return message;
  }

  /**
   * Method to open the modal and dynamically set the url
   * @param {ChatFile} chatFile - the uuid of the message we want to fetch the associated file for.
   */
  openModal(chatFile: ChatFile) {
    if (!this.markupActive) {
      messageStore.setSelectedFile(chatFile);
      messageStore.setShowImageModal(true);
    }
  }

  /**
   * Check whether the message came from this user
   * @return {boolean}
   */
  isSender(): boolean {
    return this.__PROPmessage.participant_uuid === pexipStore.__GETTERmyUuid;
  }

  /**
   * Download the viewed image
   */
  quickDownload() {
    const link = document.createElement('a') as any;
    const filename = this.__PROPmessage.file.filename === 'blob' ? 'download.png': this.__PROPmessage.file.filename;
    link.setAttribute('download', filename);
    link.href = this.__PROPmessage.file.original_url;
    link.target = '_blank';
    link.click();
    link.remove();
  }

  /**
   *
   */
  get chatParticipants() {
    return messageStore.participants;
  }

  /**
   *
   */
  get markupActive() {
    return messageStore.__GETTERmarkupActive;
  }

  /**
   * Calculates the chat name from the participants array
   */
  get chatName(): string {
    const part = this.chatParticipants.find((e: any) => {
      return e.participant_uuid === this.__PROPmessage.participant_uuid;
    });
    return part.display_name;
  }

  /**
   * Calculates what the file name display should be
   */
  get fileName(): string {
    return this.__PROPmessage.file.filename === 'blob' ? 'Marked Up Image' : this.__PROPmessage.file.filename;
  }

  /**
   * Get list of mentionListParticipants.
   */
  get chatHistoricParticipants() {
    return messageStore.__GETTERchatHistoricParticipants;
  }

  /**
   *
   */
  get timeStamp() {
    const date = new Date(this.__PROPmessage.timestamp * 1000);

    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return hours + ':' + minutes;
  }
}
