
import Vue from 'vue';

import IconStreaming from '@/components/icons/IconStreaming.vue';

import {Component, Emit} from 'vue-property-decorator';

@Component({
  components: {
    IconStreaming,
  },
})
/**
 * Basic form that obtains infromation for outbound streaming
 */
export default class LiveStreamForm extends Vue {
  streamKey: string = '';
  streamName: string = '';
  streamURL: string = '';
  streamTerms: boolean = false;

  errors = {
    key: false,
    name: false,
    url: false,
  }

  /**
   * Returns whether the stream fields are filled
   * @return {boolean}
   */
  get hasStreamDetails(): boolean {
    return (
      this.streamName.length > 0 &&
      this.streamURL.length > 0 &&
      this.streamKey.length > 0 &&
      this.streamTerms
    );
  }

  /**
   * Clear the form errors
   */
  clearErrors() {
    this.errors = {
      key: false,
      name: false,
      url: false,
    };
  }

  /**
   * Fires when the form has been submitted
   * @param {string} name The name of the participants
   * @param {string} url The url of the stream
   * @param {string} key The stream key
   * @return {any}
   */
  @Emit()
  submit(name: string, url: string, key: string) {
    if (!name) this.errors.name = true;
    if (!url) this.errors.url = true;
    if (!key) this.errors.key = true;
    return {
      key,
      name,
      url,
    };
  }
}
