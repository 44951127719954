import * as audioLoader from 'audio-loader';

export type Config = {
  playThroughSpeakers?: boolean
}
interface IAudioFile {
  context:AudioContext
  src: string
  buffer: AudioBuffer
  volume: GainNode
  isMuted: boolean
  isLoaded: boolean
  play():AudioBufferSourceNode
  stop():void
  load(url:string):Promise<BufferSource>
  mute():void
  unMute():void
}

type Buffer<T> = {
  node: T
  started?: number
  willEnd: boolean
}
/**
 * A class that handles AudioFile implementation
 */
export class AudioFile implements IAudioFile {
    src: string
    buffer: AudioBuffer
    volume: GainNode
    context:AudioContext
    isMuted: boolean
    isLoaded: boolean
    private lastKnownGainValue: number
    private BufferNodes: Buffer<AudioBufferSourceNode>[]
    /**
     * Creates an instance of AudioFile.
     * @param {Config} [config]
     * @param {AudioContext} [context]
     * @memberof AudioFile
     */
    constructor(config?:Config, context?:AudioContext) {
      try {
        // @ts-ignore
        // eslint-disable-next-line new-cap
        this.context = context || new AudioContext() || new window.webkitAudioContext();
        this.volume = this.context.createGain();
      } catch (e) {
        throw new Error(e);
      }
      const {playThroughSpeakers} = config;

      if (playThroughSpeakers) {
        this.volume.connect(this.context.destination); // plug straight into speakers
      }
      this.isLoaded = false;
      this.BufferNodes = [];
    }
    /**
     * Loads an audio file via fetch request.
     *
     * - File must be hosted statically
     *
     * - Will only read `.mp3` and `.wav` files
     *
     * - All files will be either downsampled/ interpolated into the current machines sample-rate
     *
     * @param {string} url
     * @return {*}  {Promise<BufferSource>} Success callback
     * @memberof AudioFile
     *
     * @example
     * ```js
     * let player = new AudioPlayer()
     * await player.load('/beep.mp3')
     *
     * // Now you can do things with the sound
     * ```
     */
    load(url:string):Promise<BufferSource> {
      this.isLoaded = false;
      this.src = url;

      return audioLoader(url || this.src).then((buffer) => {
        this.buffer = buffer;
        this.preLoadSound();
        this.isLoaded = true;
      });
    }
    /**
     * Plays the loaded file
     *
     * If no file is loaded the buffer list will be empty which should throw an error
     *
     * @memberof AudioFile
     * @return {*}  {AudioBufferSourceNode}
     */
    play():AudioBufferSourceNode {
      if (!this.BufferNodes.length) {
        throw new Error('Buffer Array Is Empty');
      }
      const time = this.context.currentTime;
      const thisBuffer = this.BufferNodes[0];

      thisBuffer.node.start();
      thisBuffer.started = time;
      this.preLoadSound();

      this.updateBuffer(this.context.currentTime);
      return thisBuffer.node;
    }
    /**
     * Check the state of each audio file in the buffer and checks if it has stopped playing
     *
     * If the audio file has finished playing it will be removed from the buffer
     *
     * @private
     * @param {number} now
     * @memberof AudioFile
     */
    private updateBuffer(now:number) {
      for (let index = this.BufferNodes.length-1; index > 0; index--) {
        if (this.BufferNodes[index].started && (this.BufferNodes[index].started + this.BufferNodes[index].node.buffer.duration) <= now) {
          // Audio file has finished playing
          this.BufferNodes[index].node.disconnect();
          this.BufferNodes.splice(index, 1);
        }
      }
    }
    /**
     *
     *
     * @private
     * @memberof AudioFile
     */
    private flushBuffer() {
      this.BufferNodes = [];
      this.preLoadSound();
    }
    /**
    * @throws {Error} Returns an error if the audio buffer hasn't been loaded
    */
    private preLoadSound() {
      if (!this.buffer) {
        throw new Error('Audio Buffer has not been loaded yet');
      }
      const source = this.context.createBufferSource();
      source.buffer = this.buffer;
      source.connect(this.volume);
      this.BufferNodes.unshift({willEnd: true, node: source});
    }
    /**
     * Stop the node from playing the currently playing sound
     *
     * If this funciton has been triggered while the previous sound is still playing, the previous sound will not be stopped but the initially triggered sound will stop
     *
     * @memberof AudioFile
     *
     * @example
     * ```js
     *  var player = new AudioFile()
     *  let sound1 = player.play()
     *  let sound2 = player.play()
     *
     *  player.stop() //Only stops the last sound played `sound2`
     *  sound1.stop() //To stop the first sound
     * ```
     */
    stop() {
      this.BufferNodes[0].node.stop();
      this.BufferNodes[0].node.disconnect();
      this.preLoadSound();
    }
    /**
     *
     *
     * @param {AudioDestinationNode} destination
     * @memberof AudioFile
     * @todo
     */
    connect(destination:AudioDestinationNode) {
      this.volume.connect(destination);
    }
    /**
     * @todo
     *
     * @memberof AudioFile
     */
    disconnect() {
      this.volume.disconnect();
    }
    /**
     * @todo
     *
     * @param {number} vol
     * @memberof AudioFile
     */
    setVolume(vol:number) {
      this.volume.gain.setValueAtTime(vol >= 0.95 ? 0.95 : vol, this.context.currentTime); // volume ceiling
    }
    /**
     * @todo
     *
     * @memberof AudioFile
     */
    mute() {
      this.lastKnownGainValue = this.volume.gain.value;
      this.volume.gain.setValueAtTime(0, this.context.currentTime);
      this.isMuted = true;
    }
    /**
     * @todo
     *
     * @memberof AudioFile
     */
    unMute() {
      this.volume.gain.setValueAtTime(this.lastKnownGainValue, this.context.currentTime);
      this.isMuted = false;
    }
}
