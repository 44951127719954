import {userStore} from '@/store/__STORE_user';

export default {
  computed: {
    authUser() {
      return userStore.profileData;
    },

    accountHasAdminManagement() {
      return (this.authUser && this.authUser.account) ? this.authUser.account.has_admin_management : false;
    },

    isPaidPlan() {
      return this.authUser && this.authUser.billing_plan && (this.authUser.billing_plan.is_paid);
    },

    authUserLoadedAndCannotAccessAdminFeatures() {
      return this.authUser && this.authUser.role && !this.canAuthUserAccessAdminFeatures;
    },

    canAuthUserAccessAdminFeatures() {
      // Business plan has admin management. Some sections/features can only be accessed by admins.
      if (this.accountHasAdminManagement) {
        return this.authUser && this.authUser.role === 'Admin';
      }

      if (this.authUser && this.authUser.is_provisioned) {
        return this.authUser.role === 'Admin';
      }

      if ( this.isAuthUserOnFreeTrialLaunchPlan) {
        return true;
      }

      return false;
    },

    canEditBranding() {
      const billingPlan = this.authUser && this.authUser.billing_plan;
      if (!billingPlan) {
        return false;
      }

      // Anyone on SAAS Trial can edit branding:
      if (!this.authUser.is_provisioned && billingPlan.name === 'Trial') {
        return true;
      }

      // For other plans (including PP), only Admins can edit Business branding:
      return (this.authUser.role === 'Admin') && billingPlan.has_admin_management;
    },

    canEditPersonalBranding() {
      const billingPlan = this.authUser && this.authUser.billing_plan;
      if (!billingPlan) {
        return false;
      }

      const except = ['No License'];
      return !except.includes(billingPlan.name);
    },

    canEditAlias() {
      const billingPlan = this.authUser && this.authUser.billing_plan;
      if (!billingPlan) {
        return false;
      }

      const except = ['Free', 'No License', 'Launch'];
      return !except.includes(billingPlan.name);
    },

    isAuthUserOnFreeTrialLaunchPlan() {
      // Allow free, trial, launch users to access admin features as a regular user
      const allowedAccounts = ['Free', 'Trial', 'Launch'];
      const accountBillingPlan = (this.authUser && this.authUser.account) ? this.authUser.account.billing_plan : '';
      return allowedAccounts.includes(accountBillingPlan.name);
    },

    isAuthUserOnTrialPlan() {
      return this.authUser && this.authUser.billing_plan && this.authUser.billing_plan.name === 'Trial';
    },

    canAuthUserAccessOpsDash() {
      if ( this.isAuthUserOnTrialPlan) {
        return true;
      }
      const billingPlan = this.authUser && this.authUser.billing_plan;
      if (!billingPlan) {
        return false;
      }
      // For other plans (including PP), only Admins can access the Ops Dash:
      return (this.authUser.role === 'Admin') && billingPlan.has_admin_management;
    },

    userCanAccessHailMary() {
      if (this.authUser.billing_plan && this.authUser.billing_plan.name === 'Trial') {
        return true;
      }
      if (this.authUser.addons && this.authUser.addons.some( (e) => e.name === 'XR Gateway')) {
        return true;
      }
      if (this.authUser.billing_plan && this.authUser.billing_plan.included_addons.some( (e) => e.name === 'XR Gateway')) {
        return true;
      }
      return false;
    },

    isUserProvisioned() {
      if (this.authUser) {
        return this.authUser.is_provisioned;
      }
      return false;
    },

    isUserOnNoLicense() {
      return this.authUser && this.authUser.is_provisioned && this.authUser.billing_plan === null;
    },
  },
  methods: {
    isPlanLevelAtLeast(level) {
      if (this.authUser.is_provisioned) {
        return true;
      }
      return this.authUser && this.authUser.billing_plan && (this.authUser.billing_plan.level >= level);
    },
  },
};
