
import Vue from 'vue';
import Component from 'vue-class-component';
import {PropSync, Watch} from 'vue-property-decorator';

import {callStore} from '@/store/__STORE_call';

@Component
/**
 * Self View component to show user's video stream and additional
 * screenshare feed
 */
export default class SelfView extends Vue {
  name: 'Self View'

  @PropSync('videoStream', {type: MediaStream, default: () => {}}) readonly __PROPvideoStream: MediaStream
  @PropSync('showCallControls', {type: Boolean, default: true}) readonly __PROPshowCallControls: boolean
  @PropSync('muted', {type: Boolean, default: true}) readonly __PROPmuted: boolean
  @PropSync('cameraDeviceId', {type: Number, default: null}) readonly __PROPcameraDeviceId: Number
  @PropSync('type', {type: String, default: null}) readonly __PROPtype: 'camera' | 'screen'

  private toggleIsShowing = false;
  private collapsed = false;
  private error = false;

  /**
   * Get the camera mirrored state from the store
   */
  get cameraMirrored() {
    return callStore.cameraMirrored;
  }

  /**
   * Update the media from the props updating
   */
  @Watch('__PROPtype')
  @Watch('__PROPcameraDeviceId')
  @Watch('__PROPvideoStream')
  updateMedia() {
    this.getMedia();
  }

  /**
   * Called when the component is mounted
   */
  mounted() {
    this.getMedia();
  }

  /**
   * Async function to either get the video feed from a given camera or sets the given
   * video stream to the given prop
   */
  async getMedia() {
    const video = this.$el.querySelector('#self-stream') as any;
    try {
      if (this.__PROPvideoStream) {
        video.srcObject = this.__PROPvideoStream;
        video.play();
      } else {
        const constraints = {
          audio: false,
          video: this.__PROPcameraDeviceId ? {deviceId: this.__PROPcameraDeviceId} : true,
        } as any;


        const stream = await navigator.mediaDevices.getUserMedia(constraints);

        video.srcObject = stream;
      }
    } catch (error) {
      this.error = true;
    }
  }

  /**
   * Function to show/hide the self view instance
   */
  toggleSelfView() {
    this.collapsed = !this.collapsed;
  }

  /**
   * Show the toggle button on the component
   */
  showToggle() {
    this.toggleIsShowing = true;
  }


  /**
   * Set the toggle button show status based on whether it is collapsed or not
   */
  hideToggle() {
    this.toggleIsShowing = this.collapsed;
  }
}
