import { render, staticRenderFns } from "./__COMP_SmallSpinner.vue?vue&type=template&id=417d7dc4&scoped=true"
var script = {}
import style0 from "./__COMP_SmallSpinner.vue?vue&type=style&index=0&id=417d7dc4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "417d7dc4",
  null
  
)

export default component.exports