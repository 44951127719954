
import Component, {mixins} from 'vue-class-component';
import {Responsive} from '@/mixins/Responsive';
import {Participant} from '@/mixins/Participant';

import ParticipantsSettings from '@/components_v2/intelligent/call/__COMP_ParticipantsSettings.vue';
import ParticipantActions from '@/components_v2/intelligent/call/__COMP_ParticipantActions.vue';
import {
  IconCog,
  IconLocked,
  IconUnlocked,
  IconAccept,
  IconReject,
  IconScreenShareSmall,
  IconCameraSmall,
  IconUsers,
  IconMicSmallMute,
  IconCameraSmallMute,
  IconMicSmall,
  IconLightbulb,
} from '@/components/icons';

import {pexipStore} from '@/store/__STORE_pexip';
import {callStore} from '@/store/__STORE_call';

@Component({
  components: {
    ParticipantsSettings,
    ParticipantActions,
    IconCog,
    IconLocked,
    IconUnlocked,
    IconAccept,
    IconReject,
    IconScreenShareSmall,
    IconCameraSmall,
    IconUsers,
    IconMicSmallMute,
    IconCameraSmallMute,
    IconMicSmall,
    IconLightbulb,
  },
})
/**
 * Intelligent component for showing list of participants and interacting with them via the pexip store
 */
export default class ParticipantsList extends mixins(Responsive, Participant) {
  name: 'Participants List'

  /**
   * Function fires when the component is created
   */
  created() {
    pexipStore.setParticipantTimesInterval();
  }

  /**
   * Returns the participant times array from the pexip store
   */
  get participantTimes() {
    return pexipStore.call.participantTimes;
  }

  /**
   * Get whether the user is a host from the pexip store
   */
  get isHost() {
    return pexipStore.call.isHost;
  }

  /**
   * Get the participants list from the pexip store
   */
  get participants() {
    return pexipStore.call.participants;
  }

  /**
   * Get the locked state of the VMR
   */
  get isLocked() {
    return pexipStore.call.isLocked;
  }

  /**
   * Get the selected participant uuid from the store
   */
  get selectedParticipantUuid() {
    return pexipStore.call.selectedParticipantUuid;
  }

  /**
   * Get the user's uuid from the pexip store
   */
  get myUuid() {
    return pexipStore.__GETTERmyUuid;
  }

  /**
   * Get settings show state from the pexip store
   */
  get isShowParticipantsSettings() {
    return pexipStore.call.isShowParticipantsSettings;
  }

  /**
   * Gets whether the user is the room owner or not
   */
  get isRoomOwner() {
    return pexipStore.__GETTERisRoomOwner;
  }

  /**
   * Function to toggle the meeting lock to allow guests to join
   */
  toggleLock() {
    pexipStore.toggleMeetingLock();
  }

  /**
   * Toggle whether settings window is visible for participant
   */
  toggleShowSettings() {
    pexipStore.setIsShowParticipantsSettings(!this.isShowParticipantsSettings);
  }

  /**
   * Select a given participant
   * @param {any} participant - the participant object selected
   */
  selectParticipant(participant: any) {
    if (!this.isHost && participant.uuid === this.myUuid) {
      return;
    }
    // Blocks other hosts from being able to disconnect the recorder
    if (this.__MIXINisParticipantRecorder(participant) && !this.isRoomOwner) {
      return;
    }

    pexipStore.selectParticipant(participant);
  }

  /**
   * Unlocks the waiting participant to let them into the conference
   * @param {any} participant - participant object
   */
  unlockParticipant(participant: any) {
    pexipStore.unlockParticipant(participant);
  }

  /**
   * Disconnects the given participant from the conference
   * @param {any} participant - participant object
   */
  disconnectParticipant(participant: any) {
    pexipStore.disconnectParticipant(participant);
  }

  /**
   * Toggles the given participants' mute status
   * @param {any} participant - participant object
   */
  toggleParticipantMute(participant: any) {
    pexipStore.toggleParticipantMute(participant);
  }

  /**
   * Toggles the given participants' spotlight status
   * @param {any} participant - participant object
   */
  toggleParticipantSpotlight(participant: any) {
    pexipStore.toggleParticipantSpotlight(participant);
  }

  /**
   * Return state of expanding the chat as a boolean
   */
  get isChatExpanded() {
    return callStore.__GETTERexpandChat;
  }
}
