<template>
  <span class="branded-icon">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 14 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: sketchtool 59.1 (101010) - https://sketch.com -->
      <title>F699BE9B-AA72-4A08-B069-7E608740B3F4</title>
      <desc>Created with sketchtool.</desc>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        :fill="fillColor"
        fill-rule="evenodd"
      >
        <g
          id="Ongoing-meeting-1"
          transform="translate(-254.000000, -136.000000)"
          :fill="fillColor"
          fill-rule="nonzero"
        >
          <g id="Sidebar">
            <g
              id="Participants"
              transform="translate(0.000000, 69.000000)"
            >
              <g
                id="Rows"
                transform="translate(0.000000, 50.000000)"
              >
                <g
                  id="Row-1"
                  transform="translate(20.000000, 12.000000)"
                >
                  <g
                    id="Options"
                    transform="translate(234.000000, 4.000000)"
                  >
                    <g id="Screen-share">
                      <path
                        id="ic_screen_share_small"
                        d="M6,12 L2,12 C0.8954305,12 0,11.1045695 0,10 L0,3 C0,1.8954305 0.8954305,1 2,1 L12,1 C13.1045695,1 14,1.8954305 14,3 L14,10 C14,11.1045695 13.1045695,12 12,12 L8,12 L8,13 L8.5,13 C8.77614237,13 9,13.2238576 9,13.5 C9,13.7761424 8.77614237,14 8.5,14 L5.5,14 C5.22385763,14 5,13.7761424 5,13.5 C5,13.2238576 5.22385763,13 5.5,13 L6,13 L6,12 Z M12,2 L2,2 C1.44771525,2 1,2.44771525 1,3 L1,10 C1,10.5522847 1.44771525,11 2,11 L12,11 C12.5522847,11 13,10.5522847 13,10 L13,3 C13,2.44771525 12.5522847,2 12,2 Z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
import Props from './propsMixin';
export default {
  mixins: [Props],
};
</script>
