<template>
  <div v-if="notify">
    <div
      id="notification-box"
      class="notification-box"
    >
      <a
        id="notification-close"
        href="#"
        title="close"
        @click.prevent="$emit('close')"
      >x</a>

      <div
        class="notification-data cursor-pointer col-12"
        @click="openWelcomePage"
      >
        <div class="notification-title">
          <p id="title">
            <span
              id="title"
              style="font-size: 12px; font-weight: 600;"
            >{{ pushNotificationData.data.title }}</span>
          </p>
        </div>

        <div class="notification-notice">
          <p id="subject">
            {{ JSON.parse(pushNotificationData.data.body).message }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationBox',

  props: {
    pushNotificationData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      notify: true,
    };
  },
  methods: {
    openWelcomePage() {
      this.webrtcAlias = JSON.parse(this.pushNotificationData.data.body).alias.replaceAll(/\s/g, '');
      this.$router.push({
        name: 'welcome-guest',
        params: {
          webrtcAlias: this.webrtcAlias,
        },
      });
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-box {
    display: flex;
    position: absolute;
    right: 30px;
    bottom: 10px;
    min-width: 30%;
    height: 100px;
    background-color: #fff;
    z-index: 999;
    border-radius: 3px;
    -webkit-box-shadow: 0 5px 10px rgb(0 0 0 / 5%);
    box-shadow: 0 5px 10px rgb(0 0 0 / 5%);
}
#notification-close {
    position: absolute;
    color: #777;
    font: 14px;
    right: 5px;
    text-decoration: none;
    text-shadow: 0 1px 0 #fff;
    top: 2px;
    z-index: 10;
    cursor: pointer;
    font-size: 20px;
}

.notification-data {
    margin: 0 20px;
}
.notification-data p {
    margin: 10px 0;
}
.notification-notice p {
    font-size: 14px;
    font-weight: 400;
}
.notification-title p {
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
}
.notification-time small {
    font-size: 12px;
}

.notification-wall-number small {
    font-size: 8px;
}
img{
    width: 30px;
}
</style>
