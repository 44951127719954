<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      :fill="fillColor"
      d="M4.92777778,5.93300868 C5.3182383,5.54242063 5.95140327,5.54231725 6.34199132,5.93277778 C6.73257937,6.3232383 6.73268275,6.95640327 6.34222222,7.34699132 C3.21877844,10.4714552 3.21932974,15.5363001 6.34345363,18.660084 C9.46757752,21.7838679 14.5324225,21.7838679 17.6565464,18.660084 C20.7806703,15.5363001 20.7812216,10.4714552 17.6577778,7.34699132 C17.2673173,6.95640327 17.2674206,6.3232383 17.6580087,5.93277778 C18.0485967,5.54231725 18.6817617,5.54242063 19.0722222,5.93300868 C22.976527,9.83858854 22.9758378,16.1696447 19.070683,20.0743745 C15.1655281,23.9791043 8.8344719,23.9791043 4.92931703,20.0743745 C1.02416217,16.1696447 1.02347305,9.83858854 4.92777778,5.93300868 Z M12,1 C12.5128358,1 12.9355072,1.38604019 12.9932723,1.88337887 L13,2 L13,12 C13,12.5522847 12.5522847,13 12,13 C11.4871642,13 11.0644928,12.6139598 11.0067277,12.1166211 L11,12 L11,2 C11,1.44771525 11.4477153,1 12,1 Z"
    />
  </svg>
</template>
<script>
import Props from './propsMixin';
export default {
  mixins: [Props],
};
</script>
