var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"canvas-holder"},[_c('canvas',{staticClass:"canvas-style",style:({
      backgroundImage: `url(${_vm.background})`,
      backgroundSize: 'cover',
    }),attrs:{"id":"exampleCanvas"},on:{"mousedown":_vm.mouseDown}}),_c('div',{staticClass:"canvas-tools"},[(_vm.showPicker)?_c('compact-picker',{staticClass:"colour-picker",attrs:{"v-click-outside":{exclude: ['colour-picker'], handler: 'closePicker'}},model:{value:(_vm.colors),callback:function ($$v) {_vm.colors=$$v},expression:"colors"}}):_vm._e(),(_vm.showSlider)?_c('div',{staticClass:"width-slider-container",attrs:{"v-click-outside":{exclude: ['width-slider-container'], handler: 'closeSlider'}}},[(_vm.showSlider)?_c('vue-slider',{staticClass:"width-slider",attrs:{"interval":1,"min":5,"max":20,"marks":true,"direction":'btt',"tooltip":'hover',"contained":true},model:{value:(_vm.toolWidth),callback:function ($$v) {_vm.toolWidth=$$v},expression:"toolWidth"}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"tool-container top"},[_c('i',{staticClass:"far fa-times-circle tool red",attrs:{"title":"Stop Annotation"},on:{"click":function($event){return _vm.stopAnnotation()}}})]),_c('div',{staticClass:"tool-container"},[_c('i',{staticClass:"fas fa-pencil-alt tool",style:([
          _vm.selectedTool === 'line' ? _vm.fillColour : {},
          _vm.selectedTool === 'line' ? _vm.selected : {},
        ]),attrs:{"title":"Freeform Line Tool"},on:{"click":function($event){$event.preventDefault();return _vm.selectTool('line')}}}),_c('i',{staticClass:"far fa-square tool",style:([
          _vm.selectedTool === 'square' ? _vm.fillColour : {},
          _vm.selectedTool === 'square' ? _vm.selected : {}
        ]),attrs:{"title":"Square/Rectangle Tool"},on:{"click":function($event){$event.preventDefault();return _vm.selectTool('square')}}}),_c('i',{staticClass:"far fa-circle tool",style:([
          _vm.selectedTool === 'circle' ? _vm.fillColour : {},
          _vm.selectedTool === 'circle' ? _vm.selected : {}
        ]),attrs:{"title":"Circle Tool"},on:{"click":function($event){$event.preventDefault();return _vm.selectTool('circle')}}}),_c('i',{staticClass:"fas fa-long-arrow-alt-up tool",style:([
          _vm.selectedTool === 'arrow' ? _vm.fillColour : {},
          _vm.selectedTool === 'arrow' ? _vm.selected : {}
        ]),attrs:{"title":"Arrow Tool"},on:{"click":function($event){$event.preventDefault();return _vm.selectTool('arrow')}}}),_c('i',{staticClass:"fas fa-palette tool",style:(_vm.fillColour),attrs:{"title":"Choose Colour"},on:{"click":function($event){return _vm.toggleColourPicker()}}}),_c('i',{staticClass:"fas fa-sliders-h tool",style:(_vm.fillColour),attrs:{"title":"Adjust Line Thickness"},on:{"click":function($event){return _vm.toggleThicknessSlider()}}})]),_c('div',{staticClass:"tool-container"},[_c('i',{staticClass:"fas fa-undo-alt tool",attrs:{"title":"Undo"},on:{"click":function($event){return _vm.undo()}}}),_c('i',{staticClass:"fas fa-redo-alt tool",attrs:{"title":"Redo"},on:{"click":function($event){return _vm.redo()}}}),_c('i',{staticClass:"far fa-trash-alt tool red",attrs:{"title":"Clear Annotations"},on:{"click":function($event){return _vm.reset()}}})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }