<template>
  <div class="action-cover">
    <div
      v-if="callType === 'video'"
      v-tooltip="disabled ? 'Screen share disabled' : enabled ? 'Stop screen share' : 'Start screen share'"
      :class="['meeting-action', 'bg-white', {disabled}]"
      @click="$emit('toggle')"
    >
      <img
        v-show="!enabled"
        src="@/assets/img/icons/ic_screen_share.svg"
      >
      <img
        v-show="enabled"
        src="@/assets/img/icons/ic_screen_share_mute.svg"
      >
    </div>
    <div
      v-else
      v-tooltip="'Screen share disabled'"
      :class="{'circular disabled': circular, 'meeting-action bg-white disabled': !circular, 'enabled': enabled}"
      @click="$emit('open-settings')"
    >
      <img
        v-show="!enabled"
        src="@/assets/img/icons/ic_screen_share_mute.svg"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    callType: {
      type: String,
      default: null,
    },
    enabled: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    circular: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/__meeting_action";

  .circular {
    // background: url('../../../assets/img/icons/ic_screen_share_mute.svg') center center no-repeat transparent;
    // background-size: auto 20px;
    // border-radius: 50%;
    border: 1px solid white;
    height: 40px;
    width: 40px;

    &.enabled {
      background-color: rgba(0,0,0,0.5);
      border-color: var(--primary-color);
    }

    img {
      display: none;
    }
  }
</style>
