
import Vue from 'vue';
import Component from 'vue-class-component';
import SmallSpinner from '@/components_v2/generic/misc/__COMP_SmallSpinner.vue';

// import {pexipStore} from '@/store/__STORE_pexip';
import {messageStore} from '@/store/__STORE_message';

@Component({
  components: {
    SmallSpinner,
  },
})
/**
 * Intelligent component to select a screenshot for the annotation flow
 */
export default class UploadPreview extends Vue {
  name: 'Upload Preview Modal';

  /**
   * Removes the currently uploaded file from the store
   */
  removeFile() {
    messageStore.deleteUploadedFile();
  }

  /**
   * Get the thumbnail
   */
  get thumbnail() {
    return messageStore.__GETTERthumbnail;
  }

  /**
   * Get the thumbnail
   */
  get fileUploading() {
    return messageStore.__GETTERfileUploading;
  }
}
