<template>
  <div class="custom-checkbox-slide">
    <input
      id="check3"
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      @change="change"
    >
    <label for="check3">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    label: {
      type: String,
      default: null,
    },
    checked: Boolean,
    disabled: Boolean,
  },
  methods: {
    change() {
      this.$emit('change');
    },
  },
};
</script>
