<template>
  <span class="branded-icon">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: sketchtool 59.1 (101010) - https://sketch.com -->
      <title>22B80B89-51E8-4EB3-A240-EBAC1BD84175</title>
      <desc>Created with sketchtool.</desc>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        :fill="fillColor"
        fill-rule="evenodd"
      >
        <g
          id="Ongoing-meeting-1"
          transform="translate(-276.000000, -135.000000)"
          :fill="fillColor"
          fill-rule="nonzero"
        >
          <g id="Sidebar">
            <g
              id="Participants"
              transform="translate(0.000000, 69.000000)"
            >
              <g
                id="Rows"
                transform="translate(0.000000, 50.000000)"
              >
                <g
                  id="Row-1"
                  transform="translate(20.000000, 12.000000)"
                >
                  <g
                    id="Options"
                    transform="translate(234.000000, 4.000000)"
                  >
                    <g
                      id="Video"
                      transform="translate(22.000000, 0.000000)"
                    >
                      <path
                        id="ic_camera_small"
                        d="M8,2 C9.1045695,2 10,2.8954305 10,4 L10,5.584 L12.2928932,3.29289322 C12.9228581,2.66292836 14,3.10909515 14,4 L14,10 C14,10.8909049 12.9228581,11.3370716 12.2928932,10.7071068 L10,8.415 L10,10 C10,11.1045695 9.1045695,12 8,12 L2,12 C0.8954305,12 0,11.1045695 0,10 L0,4 C0,2.8954305 0.8954305,2 2,2 L8,2 Z M8,3 L2,3 C1.44771525,3 1,3.44771525 1,4 L1,10 C1,10.5522847 1.44771525,11 2,11 L8,11 C8.55228475,11 9,10.5522847 9,10 L9,4 C9,3.44771525 8.55228475,3 8,3 Z M13,4 L10,7 L13,10 L13,4 Z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
import Props from './propsMixin';
export default {
  mixins: [Props],
};
</script>
