import Vue from 'vue';
import Component from 'vue-class-component';

@Component
/**
 * Responsive Mixin for methods to enable responsive behaviour
 */
export class Chat extends Vue {
  /**
   * Returns the name of the participant as initials
   * @param {string} displayName - The displayName to get the name for
   * @return {string} initials - The initials of the name to show in the minimized name icon
   */
  __MIXINusernameInitials(displayName:string): string {
    const fullName = displayName.split(' ');
    let initials = '';
    if (fullName.length > 1) {
      initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    } else {
      initials = fullName.shift().charAt(0);
    }
    return initials.toUpperCase();
  }
}
