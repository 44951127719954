<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="form-group">
          <label>Call type</label>
          <multiselect
            v-model="callType"
            track-by="id"
            :allow-empty="false"
            :show-labels="false"
            :searchable="false"
            :options="callTypeList()"
            @select="onCallTypeChange"
          >
            <template
              slot="singleLabel"
              slot-scope="props"
            >
              <img
                :src="`/img/icons/${props.option.icon}`"
                class="mr-2"
                alt=""
              >
              <span class="option__desc"><span class="option__title">{{ props.option.name }}</span></span>
            </template>
            <template
              slot="option"
              slot-scope="props"
            >
              <img
                :src="`/img/icons/${props.option.icon}`"
                class="mr-2"
                alt=""
              >
              <span class="option__desc"><span class="option__title">{{ props.option.name }}</span></span>
            </template>
          </multiselect>
        </div>

        <div class="form-group">
          <label>Microphone</label>
          <multiselect
            v-model="selectedMicrophone"
            track-by="id"
            placeholder="Microphone"
            :searchable="false"
            :show-labels="false"
            :allow-empty="false"
            :options="audioTypeList()"
            @select="onMicrophoneChange"
          >
            <template
              slot="singleLabel"
              slot-scope="props"
            >
              <img
                :src="`/img/icons/${props.option.icon}`"
                class="mr-2"
                alt=""
              >
              <span class="option__desc"><span class="option__title">{{ props.option.name }}</span></span>
            </template>
            <template
              slot="option"
              slot-scope="props"
            >
              <img
                :src="`/img/icons/${props.option.icon}`"
                class="mr-2"
                alt=""
              >
              <span class="option__desc"><span class="option__title">{{ props.option.name }}</span></span>
            </template>
          </multiselect>

          <div class="level-container">
            <div
              v-for="volume in 10"
              :key="volume"
              class="one-level-container"
            >
              <div
                class="one-level"
                :class="{'bg-success': volume <= normalizedMicrophoneVolume}"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Speaker</label>
          <multiselect
            v-model="selectedSpeaker"
            track-by="id"
            placeholder="Speaker"
            :searchable="false"
            :show-labels="false"
            :allow-empty="false"
            :options="speakerTypeList()"
            @select="onSpeakerChange"
          >
            <template
              slot="singleLabel"
              slot-scope="props"
            >
              <img
                :src="`/img/icons/${props.option.icon}`"
                class="mr-2"
                alt=""
              >
              <span class="option__desc"><span class="option__title">{{ props.option.name }}</span></span>
            </template>
            <template
              slot="option"
              slot-scope="props"
            >
              <img
                :src="`/img/icons/${props.option.icon}`"
                class="mr-2"
                alt=""
              >
              <span class="option__desc"><span class="option__title">{{ props.option.name }}</span></span>
            </template>
          </multiselect>

          <!-- <div class="level-container">
            <div
              v-for="volume in 10"
              :key="volume"
              class="one-level-container"
            >
              <div
                class="one-level"
                :class="{'bg-success': volume <= normalizedSpeakerVolume}"
              />
            </div>
          </div> -->
        </div>
        <div
          v-if="isCallTypeVideo"
          class="form-group"
        >
          <label>Camera</label>
          <multiselect
            v-model="selectedCamera"
            track-by="id"
            placeholder="Camera"
            :allow-empty="false"
            :show-labels="false"
            :searchable="false"
            :options="videoTypeList()"
            @select="onCameraChange"
          >
            <template
              slot="singleLabel"
              slot-scope="props"
            >
              <img
                :src="`/img/icons/${props.option.icon}`"
                class="mr-2"
                alt=""
              >
              <span class="option__desc"><span class="option__title">{{ props.option.name }}</span></span>
            </template>
            <template
              slot="option"
              slot-scope="props"
            >
              <img
                :src="`/img/icons/${props.option.icon}`"
                class="mr-2"
                alt=""
              >
              <span class="option__desc"><span class="option__title">{{ props.option.name }}</span></span>
            </template>
          </multiselect>
          <Toggle
            class="mt-1"
            :checked="cameraMirrored"
            label="Mirror your camera"
            @change.native="flipCamera"
          />
        </div>
      </div>
      <div
        v-show="isCallTypeVideo && selectedCamera && selectedCamera.id"
        class="col-12 col-md-4"
      >
        <div class="h-100 d-flex justify-content-center">
          <video
            ref="cameraPreview"
            class="mt-auto"
            playsinline
            autoplay
            :class="{
              'mirror': cameraMirrored,
            }"
          />
        </div>
      </div>
    </div>

    <div class="row settings_tab_apply_button">
      <div class="col-12 text-center">
        <button
          class="btn btn-primary btn-sm ml-1 my-1"
          @click.prevent="renegotiate"
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import {callStore} from '@/store/__STORE_call';

import EnumerateDevicesMixin from '@/mixins/EnumerateDevicesMixin';
import MonitorMicrophoneVolumeMixin from '@/mixins/MonitorMicrophoneVolumeMixin';
import MonitorSpeakerVolumeMixin from '@/mixins/MonitorSpeakerVolumeMixin';
import Toggle from '@/components/input/Toggle.vue';

export default {
  name: 'DeviceSettings',
  components: {
    Multiselect,
    Toggle,
  },
  mixins: [
    EnumerateDevicesMixin,
    MonitorMicrophoneVolumeMixin,
    MonitorSpeakerVolumeMixin,
  ],
  props: {
    initialCallType: {
      type: String,
      default: null,
    },
    preselectedCameraDeviceId: {
      type: [String, Boolean],
      default: null,
    },
    preselectedMicrophoneDeviceId: {
      type: [String, Boolean],
      default: null,
    },
    preselectedSpeakerDeviceId: {
      type: [String, Boolean],
      default: null,
    },
  },
  data() {
    const selectedCallType = this.callTypeList().find((callType) => {
      return callType.id === this.initialCallType;
    });

    return {
      selectedMicrophone: null,
      selectedCamera: null,
      selectedSpeaker: null,


      internalMicrophoneStream: null,
      internalSpeakerStream: null,
      internalCameraStream: null,
      callType: selectedCallType,

      microphoneDeviceIdLocalStorageKey: 'microphoneDeviceIdKey',
      speakerDeviceIdLocalStorageKey: 'speakerDeviceIdKey',
      cameraDeviceIdLocalStorageKey: 'cameraDeviceIdKey',
    };
  },
  computed: {
    normalizedMicrophoneVolume() {
      return Math.round(
          this.microphoneVolume / 10,
      );
    },
    normalizedSpeakerVolume() {
      return Math.round(
          this.speakerVolume / 10,
      );
    },
    isCallTypeVideo() {
      return this.callType.id === 'video';
    },
    cameraMirrored: () => callStore.cameraMirrored,
  },
  mounted() {
    this.requestMicrophoneAccess({
      deviceId: this.preselectedMicrophoneDeviceId,
    });
    this.requestSpeakerAccess({
      deviceId: this.preselectedSpeakerDeviceId,
    });

    if (this.isCallTypeVideo) {
      this.requestCameraAccess({
        deviceId: this.preselectedCameraDeviceId,
      });
    }

    this.enumerateDevices(() => {
      const selectedMic = this.microphones.find((microphone) => {
        return microphone.deviceId === this.preselectedMicrophoneDeviceId;
      });

      const selectedSpk = this.speakers.find((speaker) => {
        return speaker.deviceId === this.preselectedSpeakerDeviceId;
      });

      const selectedCam = this.cameras.find((camera) => {
        return camera.deviceId === this.preselectedCameraDeviceId;
      });

      this.selectedMicrophone = {
        id: this.preselectedMicrophoneDeviceId,
        icon: 'ic_volume_small.svg',
        name: selectedMic ? selectedMic.label : 'No microphone',
      };
      this.selectedSpeaker = {
        id: this.preselectedSpeakerDeviceId,
        icon: 'ic_volume_small.svg',
        name: selectedSpk ? selectedSpk.label : 'No speaker',
      };

      this.selectedCamera = {
        id: this.preselectedCameraDeviceId,
        icon: 'ic_camera_small_grey.svg',
        name: selectedCam ? selectedCam.label : 'No camera',
      };
    });
  },
  beforeDestroy() {
    this.stopMicrophone();
    this.stopSpeaker();
    this.stopCamera();
  },
  methods: {
    flipCamera() {
      callStore.mirrorCamera();
    },
    stopMicrophone() {
      if (this.microphoneVolumeCheckInterval) {
        window.clearInterval(this.microphoneVolumeCheckInterval);
        this.microphoneVolume = 0;
      }
    },
    stopSpeaker() {
      if (this.speakerCheckInterval) {
        window.clearInterval(this.speakerCheckInterval);
        this.speakerVolume = 0;
      }
    },
    audioTypeList() {
      const list = this.microphones.map((microphone) => {
        return {
          id: microphone.deviceId,
          icon: 'ic_volume_small.svg',
          name: microphone.label,
        };
      });

      // https://docs.pexip.com/api_client/api_pexrtc.htm
      // null: default sources
      // false: do not request

      list.push({
        id: null, // for some reason Pexip throws error if this is "false", so lets use "null" and then mute it in OngoingMeeting.vue
        icon: 'ic_mic_small_disable.svg',
        name: 'No microphone',
      });

      return list;
    },
    speakerTypeList() {
      const list = this.speakers.map((speaker) => {
        return {
          id: speaker.deviceId,
          icon: 'ic_volume_small.svg',
          name: speaker.label,
        };
      });

      // https://docs.pexip.com/api_client/api_pexrtc.htm
      // null: default sources
      // false: do not request

      // list.push({
      //   id: null, // for some reason Pexip throws error if this is "false", so lets use "null" and then mute it in OngoingMeeting.vue
      //   icon: 'ic_mic_small_disable.svg',
      //   name: 'No speaker',
      // });

      return list;
    },
    videoTypeList() {
      const list = this.cameras.map((camera) => {
        return {
          id: camera.deviceId,
          icon: 'ic_camera_small_grey.svg',
          name: camera.label,
        };
      });

      // https://docs.pexip.com/api_client/api_pexrtc.htm
      // null: default sources
      // false: do not request

      list.push({
        id: false,
        icon: 'ic_camera_small_disable.svg',
        name: 'No camera',
      });

      return list;
    },
    callTypeList() {
      return [
        {
          id: 'video',
          icon: 'ic_camera_small_grey.svg',
          name: 'Video',
        },
        {
          id: 'audioonly',
          icon: 'ic_volume_small.svg',
          name: 'Audio only',
        },
      ];
    },
    requestMicrophoneAccess(audioConstraints) {
      this.stopMicrophone();

      if (!audioConstraints.deviceId) {
        return;
      }

      navigator.mediaDevices.getUserMedia({audio: audioConstraints})
          .then((stream) => {
            this.internalMicrophoneStream = stream;
            this.monitorMicrophoneVolume(stream);
          })
          .catch(() => {

          });
    },
    requestSpeakerAccess(speakerConstraints) {
      this.stopSpeaker();

      if (!speakerConstraints.deviceId) {
        return;
      }

      navigator.mediaDevices.getUserMedia({audio: speakerConstraints})
          .then((stream) => {
            this.internalSpeakerStream = stream;
            this.monitorSpeakerActivity(stream);
          })
          .catch(() => {

          });
    },
    requestCameraAccess(videoConstraints) {
      this.stopCamera();

      if (videoConstraints.deviceId === false) {
        return;
      }

      navigator.mediaDevices.getUserMedia({video: videoConstraints})
          .then((stream) => {
            this.internalCameraStream = stream;
            const video = this.$refs.cameraPreview;
            video.srcObject = stream;
            video.play();
          })
          .catch(() => {

          });
    },
    stopCamera() {
      if (this.internalCameraStream) {
        this.internalCameraStream.getTracks().forEach((track) => track.stop());
      }
    },
    onMicrophoneChange(selectedMicrophone) {
      this.requestMicrophoneAccess({
        deviceId: selectedMicrophone.id,
      });
    },
    onSpeakerChange(selectedSpeaker) {
      this.requestSpeakerAccess({
        deviceId: selectedSpeaker.id,
      });
    },
    onCameraChange(selectedCamera) {
      this.requestCameraAccess({
        deviceId: selectedCamera.id,
      });
    },
    onCallTypeChange(selectedCallType) {
      if (
        selectedCallType.id === 'video' && this.videoTypeList().length
      ) {
        this.stopCamera();
        this.selectedCamera = this.videoTypeList()[0];
        this.onCameraChange(this.videoTypeList()[0]);
      }
    },
    renegotiate() {
      const cameraDeviceId = this.isCallTypeVideo ? this.selectedCamera.id : false;

      localStorage.setItem(this.microphoneDeviceIdLocalStorageKey, this.selectedMicrophone.id);
      localStorage.setItem(this.speakerDeviceIdLocalStorageKey, this.selectedSpeaker.id);
      localStorage.setItem(this.cameraDeviceIdLocalStorageKey, cameraDeviceId);

      this.$emit('renegotiate', {
        microphoneDeviceId: this.selectedMicrophone.id,
        cameraDeviceId: cameraDeviceId,
        speakerDeviceId: this.selectedSpeaker.id,
        callType: this.callType.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/__variables";

video {
  height: 10rem;
}

.level-container {
  display: flex;
  margin-top: 5px;

  .one-level-container {
    padding: 2px;
    width: 10%;
    .one-level {
      background-color: $form-border;
      border-radius: 2px;
      height: 5px;
    }
  }
}
</style>
