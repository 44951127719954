import Vue from 'vue';
import Component from 'vue-class-component';

@Component
/**
 * Mixin for adjusting colours
 */
export default class Colours extends Vue {
  /**
   * Method that lightens the colour by 10%
   * @param {string} color - the hex colour string to lighten
   * @return {string} - the lightened colour hex
   */
  __MIXINlighten(color: string): string {
    return this.__shadeColor(color, 10);
  }

  /**
   * Method that darkens the colour by 10%
   * @param {string} color - the hex colour string to darken
   * @return {string} - the darkened colour hex
   */
  __MIXINdarken(color: string): string {
    return this.__shadeColor(color, -10);
  }

  /**
   * Private method to apply the effect
   * @param {string} color - the colour hex string to change
   * @param {number} percent - the percentage of lightening to apply (negative for darkening)
   * @return {string} - the new hex string for the colour
   */
  private __shadeColor(color: string, percent: number): string {
    const newPercentage = 100 + percent;
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = Math.floor(R * (newPercentage) / 100);
    G = Math.floor(G * (newPercentage) / 100);
    B = Math.floor(B * (newPercentage) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    const RR = ((R.toString(16).length === 1) ? '0' + R.toString(16) : R.toString(16));
    const GG = ((G.toString(16).length === 1) ? '0' + G.toString(16) : G.toString(16));
    const BB = ((B.toString(16).length === 1) ? '0' + B.toString(16) : B.toString(16));

    return '#' + RR + GG + BB;
  }
}
