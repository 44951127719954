
import Vue from 'vue';
import Component from 'vue-class-component';
import {messageStore} from '@/store/__STORE_message';

@Component
/**
 * Generic chat notification that takes a prop of the string to display
 */
export default class ChatNotification extends Vue {
  name: 'Chat Nofification';

  fade = false;

  /**
   *
   */
  mounted() {
    this.fade = this.showNotificationState;
    setTimeout(() => {
      this.fade = false;
    }, 2500);
  }

  /**
   *
   */
  get showNotificationState() {
    return messageStore.__GETTERshowNotificationState;
  }
}
