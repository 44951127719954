<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          v-if="isHost"
          class="form-group"
        >
          <label>Layout</label>
          <multiselect
            v-model="layout"
            track-by="id"
            :allow-empty="false"
            :show-labels="false"
            :searchable="false"
            :options="layoutList()"
          >
            <template
              slot="singleLabel"
              slot-scope="props"
            >
              <span class="option__desc">
                <span class="option__title">
                  {{ props.option.name }}
                </span>
              </span>
            </template>
            <template
              slot="option"
              slot-scope="props"
            >
              <span class="option__desc">
                <span class="option__title">
                  {{ props.option.name }}
                </span>
              </span>
            </template>
          </multiselect>
        </div>

        <div class="form-group">
          <label>Quality</label>
          <multiselect
            v-model="quality"
            track-by="id"
            :allow-empty="false"
            :show-labels="false"
            :searchable="false"
            :options="qualityList()"
          >
            <template
              slot="singleLabel"
              slot-scope="props"
            >
              <span class="option__desc">
                <span class="option__title">
                  {{ props.option.name }}
                </span>
              </span>
            </template>
            <template
              slot="option"
              slot-scope="props"
            >
              <span class="option__desc">
                <span class="option__title">
                  {{ props.option.name }}
                </span>
              </span>
            </template>
          </multiselect>
        </div>
      </div>
    </div>

    <div class="row settings_tab_apply_button">
      <div class="col-12 text-center">
        <button
          class="btn btn-primary btn-sm ml-1 my-1"
          @click.prevent="apply"
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import QualityMixin from '@/mixins/QualityMixin';

export default {
  name: 'RoomSettings',
  components: {
    Multiselect,
  },
  mixins: [
    QualityMixin,
  ],
  props: {
    isHost: Boolean,
    initialLayout: {
      type: String,
      default: null,
    },
    initialMaximumBandwidthKbps: {
      type: Number,
      default: null,
    },
  },
  data() {
    const selectedLayout = this.layoutList().find((layout) => {
      return layout.id === this.initialLayout;
    });

    let selectedQuality = this.qualityList().find((quality) => {
      return quality.bandwidthKbps === null; // Unlimited bandwidth
    });

    if (this.initialMaximumBandwidthKbps && (this.initialMaximumBandwidthKbps <= 1500)) {
      selectedQuality = this.qualityList().reverse().find((quality) => {
        return quality.bandwidthKbps && (quality.bandwidthKbps <= this.initialMaximumBandwidthKbps);
      });
    }

    return {
      layout: selectedLayout,
      quality: selectedQuality,
    };
  },
  computed: {},
  methods: {
    layoutList() {
      return [
        {
          id: '1:0',
          name: '1:0 Main speaker only',
        },
        {
          id: '1:7',
          name: '1:7 Main speaker and up to 7 previous speakers',
        },
        {
          id: '1:21',
          name: '1:21 Main speaker and up to 21 previous speakers',
        },
        {
          id: '2:21',
          name: '2:21 Two main speakers and up to 21 previous speakers',
        },
        {
          id: '4:0',
          name: '4:0 Equal size layout, up to a maximum of 4 speakers',
        },
      ];
    },
    apply() {
      this.$emit('apply-room-settings', {
        layout: this.layout.id ? this.layout.id : null,
        maximumBandwidthKbps: this.quality.bandwidthKbps,
      });
    },
  },
};
</script>
