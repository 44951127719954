import Vue from 'vue';
import Component from 'vue-class-component';

@Component
/**
 * Mixin for copying text to the browser clipboard
 */
export class CopyToClipboard extends Vue {
  /**
   * Copies text from an text input to the browsers clipboard
   * @param {any} domElement - the text input
   */
  __MIXINcopyToClipboard(domElement:any): void {
    domElement.setAttribute('type', 'text');
    domElement.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        this.$toast.success('Copied successfully');
      } else {
        this.$toast.error('Oops, unable to copy');
      }
    } catch (err) {
      this.$toast.error('Oops, unable to copy');
    }

    window.getSelection().removeAllRanges();
  }
}
