export default {
  data() {
    return {
      microphones: [],
      cameras: [],
      speakers: [],
    };
  },
  methods: {
    enumerateDevices(callback) {
      navigator.mediaDevices.enumerateDevices()
          .then((list) => {
            const microphones = list.filter((device) => {
              return device.kind === 'audioinput';
            }).map((device, index) => {
              return {
                kind: device.kind,
                label: device.label || ('Microphone #' + (index + 1)),
                deviceId: device.deviceId,
              };
            });

            const cameras = list.filter((device) => {
              return device.kind === 'videoinput';
            }).map((device, index) => {
              return {
                kind: device.kind,
                label: device.label || ('Camera #' + (index + 1)),
                deviceId: device.deviceId,
              };
            });

            const speakers = list.filter((device) => {
              return device.kind === 'audiooutput';
            }).map((device, index) => {
              return {
                kind: device.kind,
                label: device.label || ('Audio output #' + (index + 1)),
                deviceId: device.deviceId,
              };
            });

            this.microphones = microphones;
            this.cameras = cameras;
            this.speakers = speakers;

            if (callback) {
              callback();
            }
          });
    },
  },
};
