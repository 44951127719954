<template>
  <div
    id="modal-delete-recording"
    class="f-modal"
  >
    <div class="f-modal-table">
      <div class="f-modal-cell align-middle">
        <div class="f-modal-content">
          <div
            class="f-modal-close"
            @click="$emit('close')"
          />
          <div class="modal-popup">
            <div class="modal-popup-content">
              <h6 class="d-flex align-items-center justify-content-center">
                <div class="mr-3">
                  <i class="far fa-bell fa-2x" />
                </div> Notification Permission
              </h6>
              <p>Please click the lock icon in your address bar to fix this and refresh the page.</p>
              <a
                href="#"
                data-dismiss="f-modal"
                class="btn btn-outline"
                @click.prevent="$emit('close')"
              >Close</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
  },
  created() {
    // eslint-disable-next-line
    $('body').addClass('f-modal-is-open')
  },
  destroyed() {
    // eslint-disable-next-line
    $('body').removeClass('f-modal-is-open')
  },
};
</script>

