<template>
  <span class="branded-icon">
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 61.2 (89653) - https://sketch.com -->
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Dashboard-_-Personal-_-Profile"
          transform="translate(-1356.000000, -262.000000)"
          fill="#F15F22"
        >
          <g
            id="Content"
            transform="translate(260.000000, 108.000000)"
          >
            <g
              id="Profile-details"
              transform="translate(0.000000, 130.000000)"
            >
              <g id="Block-title">
                <g
                  id="Link"
                  transform="translate(1096.000000, 20.000000)"
                >
                  <path
                    id="ic_edit"
                    d="M13.0009101,16.0000228 C13.5531949,16.0000228 14.0009101,16.447738 14.0009101,17.0000228 C14.0009101,17.5523075 13.5531949,18.0000228 13.0009101,18.0000228 L13.0009101,18.0000228 L9.0009101,18.0000228 C8.44862535,18.0000228 8.0009101,17.5523075 8.0009101,17.0000228 C8.0009101,16.447738 8.44862535,16.0000228 9.0009101,16.0000228 L9.0009101,16.0000228 Z M11.9854179,4.04511254 L12.1577164,4.08479188 L12.3267709,4.13657779 C13.1033044,4.40494735 13.7015246,5.0422571 13.916141,5.84321649 C14.1460872,6.70138725 13.9007375,7.61704456 13.2725129,8.24526916 L4.51153107,17.006251 C4.38337225,17.1344098 4.22279237,17.2253286 4.04695992,17.2692867 L1.24344573,17.9701653 C0.511065259,18.1532604 -0.152327516,17.4898676 0.0307676004,16.7574872 L0.731646148,13.953973 C0.775604262,13.7781405 0.866523044,13.6175606 0.994681867,13.4894018 L9.75566371,4.72841996 C10.3420067,4.142077 11.1787355,3.88926067 11.9854179,4.04511254 Z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
import Props from './propsMixin';
export default {
  mixins: [Props],
};
</script>
