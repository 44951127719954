
import Vue from 'vue';
import Component from 'vue-class-component';
import {PropSync} from 'vue-property-decorator';

import {messageStore} from '@/store/__STORE_message';

@Component
/**
 * Intelligent component for controlling the settings area for participants
 * Allows for disconnecting and muting all participants as well as interacting
 * with the room lock
 */
export default class Reactions extends Vue {
  name: 'Reactions'

  @PropSync('message_id', {type: String, required: true}) readonly __PROPmessageId: string
  /**
   * Check through list of reactions for the displayed reactions
   * @param {string} reactionUnicode
   * @return {number}
   */
  checkForReaction(reactionUnicode: string): number {
    return this.reactions.filter((reaction) => {
      return reaction.emoji === reactionUnicode;
    }).length;
  }

  /**
   *
   */
  get reactions() {
    return messageStore.reactions.filter((reaction) => {
      return reaction.message_uuid === this.__PROPmessageId;
    });
  }

  /**
   *
   */
  get reactionEmojis() {
    return messageStore.__GETTERreactionEmojis;
  }
}
