/* eslint-disable camelcase */
import store from './index';
import axios from 'axios';
import {VuexModule, Module, Mutation, Action} from 'vuex-class-modules';
import {mainStore} from './__STORE_main';
import {brandingStore} from './__STORE_branding';

export type TProfile = {
  [key: string]: any
  is_recording_available: boolean
}
/**
 *
 */
@Module
class UserStore extends VuexModule {
  // State
  profileData: TProfile | null = null
  personalSettings = {} as any;
  notifications = [] as any;
  licenses = [] as any;
  requestableLicenses = [] as any;
  subUsers = [] as any;
  personalBrand = {} as any;
  userFields = {} as any;

  // Mutations
  /**
   *
   * @param {any} data
   */
  @Mutation
  private __blockUser(data: any) {
    const curUser = this.subUsers.find((item)=> item.id === data.id);
    curUser.is_blocked = !data.status;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private changeUserFields(data: any) {
    this.userFields = data;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setRequestableLicenses(data: any) {
    this.requestableLicenses = data;
  }

  /**
   *
   */
  @Mutation
  private __emptySubUsers() {
    this.subUsers = [];
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setLicenses(data: any) {
    this.licenses = data;
  }

  /**
   *
   * @param {Array<any>} notifications
   */
  @Mutation
  private __setNotificationState(notifications: Array<any>) {
    this.notifications = notifications;
  }

  /**
   *
   * @param {string} uuid
   */
  @Mutation
  private __markNotificationAsRead(uuid: string) {
    const notif = this.notifications.find((n) => {
      if (n.id === uuid) {
        return true;
      }
    });
    notif.read = true;
  }

  /**
   *
   * @param {Object} profileData
   */
  @Mutation
  private __setUserProfileData(profileData: any) {
    this.profileData = profileData;
  }

  /**
   *
   * @param {Array<any>} userSettings
   */
  @Mutation
  private __setUserSettings(userSettings: Array<any>) {
    this.personalSettings = userSettings;
  }

  /**
   *
   * @param {any} subUsers
   */
  @Mutation
  private __setSubUsers(subUsers: any) {
    this.subUsers = subUsers;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setMeetingSettings(data: any) {
    this.personalSettings.forEach((element) => {
      switch (element.key) {
        case 'pin':
          element.value = data.pin;
          break;
        case 'guest_pin':
          element.value = data.guest_pin;
          break;
        case 'set_host_pin':
          element.value = data.set_host_pin;
          break;
        case 'set_guest_pin':
          element.value = data.set_guest_pin;
          break;
        case 'lock_rooms_by_default':
          element.value = data.lock_rooms_by_default;
          break;
        case 'enable_chat_transcripts':
          element.value = data.enable_chat_transcripts;
          break;
      }
    });
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setTimezoneSettings(data: any) {
    this.personalSettings.forEach((element) => {
      switch (element.key) {
        case 'timezone':
          element.value = data.timezone;
          break;
      }
    });
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setEmailSettings(data: any) {
    this.personalSettings.forEach((element) => {
      switch (element.key) {
        case 'notify_host_before_meeting_starts':
          element.value = data.notify_host_before_meeting_starts;
          break;
        case 'notify_guests_before_meeting_starts':
          element.value = data.notify_guests_before_meeting_starts;
          break;
        case 'notify_host_when_guest_enters_room':
          element.value = data.notify_host_when_guest_enters_room;
          break;
      }
    });
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setProfileUpdate(data: any) {
    this.profileData.name = data.name;
    this.profileData.last_name = data.last_name;
    this.profileData.phone = data.phone;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setUpdatedRoomId(data: any) {
    this.profileData.meeting_room.webrtc.alias = data.alias;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setUpdatedCallGroupSettings(data: any) {
    const newEmails = [...data.emails];
    if (newEmails.length < 5) {
      const max = 5 - newEmails.length;
      for (let x = 0; x < max; x++) {
        newEmails.push(null);
      }
    }
    this.profileData.call_group.email_group = newEmails;
    this.profileData.call_group.auto_dial.address = data.dialAddress;
    this.profileData.call_group.auto_dial.protocol = data.protocol;
    this.profileData.call_group.enable_call_group = data.enable_call_group;
    this.profileData.call_group.enable_dial_out = data.enable_auto_dial_out;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setPersonalBrand(data: any) {
    this.personalBrand = data;
  }

  /**
   * Mutation to remove a deleted user from the state
   * @param {any} userId - id of the user that has been deleted
   */
  @Mutation
  private __deleteUser(userId: any) {
    const delIndex = this.subUsers.findIndex((item) => item.id === userId);
    this.subUsers.splice(delIndex, 1);
  }

  // Actions

  /**
   * Get user fields for editting him/her
   * @param {string} id - User id
   * @return {Promise} - api response
   */
  @Action
  async getUserFields(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: `/profile/${id}`, method: 'GET'})
          .then((resp) => {
            this.changeUserFields(resp.data);
            mainStore.setSuccess();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Set user fields for editting him/her
   * @param {any} payload - Object payload to set user fields
   * @return {Promise} - api response
   */
  @Action
  async setUserFields(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      const params = {
        userId: payload.id,
        name: payload.name,
        last_name: payload.last_name,
        phone: payload.phone,
        is_phonebook: payload.is_phonebook,
        is_remote_expert: payload.is_remote_expert,
        workingTimes: payload.workingTimes,
      };
      axios({url: `profile/update/${params.userId}`, data: params, method: 'PATCH'})
          .then((resp) => {
            if (params.userId === this.__GETTERuserProfile.id) this.getUserProfile();
            this.getSubUsers();
            this.changeUserFields(payload);
            mainStore.setSuccess();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Block a user on an account
   * @param {any} payload - Object payload for blocking user including id and status
   * @return {Promise} - api response
   */
  @Action
  async blockUser(payload: any): Promise<any> {
    let url = `/subusers/block/${payload.id}`;
    if (payload.status) url = `/subusers/unblock/${payload.id}`;
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: url, data: {password: payload.password}, method: 'PATCH'})
          .then((response) => {
            if (response.status === 204) {
              this.__blockUser(payload);
              mainStore.setSuccess();
              return resolve(response);
            }
            reject(response);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Get the licenses that a provisioned account can request
   * @return {Promise} - api response
   */
  @Action
  async getRequestableLicenses(): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: '/requestable-licenses', method: 'GET'})
          .then((resp) => {
            this.__setRequestableLicenses(resp.data);
            mainStore.setSuccess();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setError();

            reject(err);
          });
    });
  }

  /**
   * Resend an invitation to a pending user on an account
   * @param {any} payload - payload object containing the id of the invitiation to resend
   * @return {Promise} - api response
   */
  @Action
  async sendAnotherInvitation(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: `/send-another-invitation/${payload.id}`, method: 'POST'})
          .then((response) => {
            if (response.status === 201) {
              mainStore.setSuccess();
              return resolve(response);
            }
            reject(response);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Get the logged in user's profile data
   * @return {Promise} - api response
   */
  @Action
  async getUserProfile(): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: '/profile', method: 'GET'})
          .then((resp) => {
            const user = resp.data;
            this.__setUserProfileData(user);
            brandingStore.setLogo({logo: user.brand.logo, level: user.brand.level});
            mainStore.setStatus('success');
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Get the logged in user's profile settings
   * @return {Promise} - api response
   */
  @Action
  async getUserSettings(): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: '/settings', method: 'GET'})
          .then((resp) => {
            this.__setUserSettings(resp.data);
            mainStore.setStatus('success');
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Get the logged in user's notifications (Provisioned users only)
   * @return {Promise} - api response
   */
  @Action
  async getNotifications(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios({url: '/notifications', method: 'GET'})
          .then((resp) => {
            this.__setNotificationState(resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
    });
  }

  /**
   * Mark a given notification read
   * @param {string} uuid - unique id of the notification that is to be marked as read
   * @return {Promise} - api response
   */
  @Action
  async markNotificationRead(uuid: string): Promise<any> {
    const body = {
      read: true,
    };
    return new Promise((resolve, reject) => {
      axios({url: `/notifications/${uuid}`, data: body, method: 'PATCH'})
          .then((resp) => {
            this.__markNotificationAsRead(uuid);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
    });
  }

  /**
   * Get the licenses that a provisioned company has assinged to them (Provisioned users only)
   * @return {Promise} - api response
   */
  @Action
  async getLicenses(): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: '/licenses', method: 'GET'})
          .then((response) => {
            this.__setLicenses(response.data['licenses']);
            mainStore.setSuccess();
            resolve(response);
          })
          .catch((error) => {
            mainStore.setError();
            reject(error);
          });
    });
  }

  /**
   * Get the sub users of the logged in user's account
   * @return {Promise} - api response
   */
  @Action
  async getSubUsers(): Promise<any> {
    this.__emptySubUsers();
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: '/subusers', method: 'GET'})
          .then((response) => {
            if (response.status === 200) {
              this.__setSubUsers(response.data.data);
            }
            mainStore.setSuccess();
            resolve(response);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Get data for a specific sub user
   * @param {number} id - the id of the sub user to fetch data on
   * @return {Promise} - api response
   */
  @Action
  async getSubUser(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: `/subusers/${id}`, method: 'GET'})
          .then((response) => {
            mainStore.setSuccess();
            resolve(response.data);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Invite new sub users to the user's account
   * @param {any} payload - object containing the billing plan and email addresses to invite
   * @return {Promise} - api response
   */
  @Action
  async addSubUsers(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      const params = {
        billing_plan_id: payload.plan_id,
        emails: payload.emails,
      };
      axios({url: `/subusers`, data: params, method: 'POST'})
          .then((response) => {
            this.getSubUsers();
            mainStore.setSuccess();
            resolve(response);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Method to delete a sub user from the account
   * @param {any} payload - user information
   * @return {Promise} - api response
   */
  async deleteUser(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: `/subusers/${payload.id}`, data: {password: payload.password}, method: 'DELETE'})
          .then((response) => {
            if (response.status === 204) {
              this.__deleteUser(payload.id);
              mainStore.setSuccess();
              resolve(response);
            }
            reject(response);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Update the settings for user's meetings (pins and room lock)
   * @param {Object} data - data objct containing the new settings
   * @return {Promise} - api response
   */
  @Action
  async updateMeetingSettings(data: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: '/settings', data: data, method: 'PATCH'})
          .then((resp) => {
            this.__setMeetingSettings(data);
            mainStore.setSuccess();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Update the timezone that the user is in for meeting notifications
   * @param {Object} data - data object containing the setting information
   * @return {Promise} - api response
   */
  @Action
  async updateTimezoneSettings(data: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: '/settings', data: data, method: 'PATCH'})
          .then((resp) => {
            this.__setTimezoneSettings(data);
            mainStore.setSuccess();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Update the user's email settings
   * @param {any} data - new email settings to update to
   * @return {Promise} - api response
   */
  @Action
  async updateEmailSettings(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: '/settings', data: data, method: 'PATCH'})
          .then((resp) => {
            this.__setEmailSettings(data);
            mainStore.setSuccess();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Update the user's profile settings
   * @param {any} data - data object containing updated values
   * @return {Promise} - api response
   */
  @Action
  async updateProfile(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: '/profile', data: data, method: 'PATCH'})
          .then((resp) => {
            this.__setProfileUpdate(data);
            mainStore.setSuccess();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Change the user's password
   * @param {any} data - new password data
   * @return {Promise} - api response
   */
  @Action
  async updatePassword(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: '/password', data: data, method: 'PATCH'})
          .then((resp) => {
            mainStore.setSuccess();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Update the user's email address associated with their account
   * @param {any} data - the new email data to update
   * @return {Promise} - api response
   */
  @Action
  async updateEmail(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: '/email', data: data, method: 'PATCH'})
          .then((resp) => {
            mainStore.setSuccess();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Update the user's personal room alias
   * @param {any} data - the object containing the new alias information
   * @return {Promise} - api response
   */
  @Action
  async updateRoomId(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      for (const index in mainStore.protectedRoutes) {
        if (mainStore.protectedRoutes.length > 0) {
          const route = mainStore.protectedRoutes[index];

          const path = route.path.replace('/', '');

          if (path.includes(data.alias)) {
            mainStore.setError();
            return reject(
                {response: {data: {message: 'Chosen path contains a protected name. Please choose another one'}}},
            );
          }
        }
      }
      axios({url: '/personal-meeting-room-aliases', data: data, method: 'PATCH'})
          .then((resp) => {
            this.__setUpdatedRoomId(data);
            mainStore.setSuccess();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setError();
            if (!err.response.data.errors) {
              err.response.data.errors = {};
            }
            reject(err);
          });
    });
  }

  /**
   * Update the hail mary email call group settings for the user
   * @param {any} data - set of emails to update the call group to
   * @return {Promise} - api response
   */
  @Action
  async updateCallGroupSettings(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: '/call-group', data: data, method: 'PATCH'})
          .then((resp) => {
            this.__setUpdatedCallGroupSettings(data);
            mainStore.setSuccess();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Get the user's personal branding settings and files
   * @return {Promise} - api response
   */
  @Action
  async getPersonalBrand(): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: '/personal-brand', method: 'GET'})
          .then((resp) => {
            this.__setPersonalBrand(resp.data);
            mainStore.setSuccess();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Update the user's personal branding
   * @param {any} data - the new data to set for the personal brand
   * @return {Promise} - api response
   */
  @Action
  async updatePersonalBrand(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
      axios({url: '/personal-brand', data: data, method: 'POST'})
          .then((resp) => {
            mainStore.setSuccess();
            axios.defaults.headers.post['Content-Type'] = 'applications/x-www-form-urlencoded';
            this.getPersonalBrand();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setError();
            axios.defaults.headers.post['Content-Type'] = 'applications/x-www-form-urlencoded';
            reject(err);
          });
    });
  }

  /**
   * Request licenses for the user's account (Provisioned users only)
   * @param {Object} payload - the requested licenses
   * @return {Promise} - api response
   */
  @Action
  async requestLicenses(payload: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: 'request-licenses', data: payload, method: 'POST'})
          .then((resp) => {
            mainStore.setSuccess();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  // Getters
  /**
   *
   */
  get __GETTERuserProfile() {
    return this.profileData;
  }

  /**
   *
   */
  get __GETTERuserFields() {
    return this.userFields;
  }

  /**
   *
   */
  get __GETTERuserSettings() {
    return this.personalSettings;
  }

  /**
   *
   */
  get __GETTERnotifications() {
    return this.notifications;
  }

  /**
   *
   */
  get __GETTERisUserProvisioned() {
    if (this.profileData) {
      return this.profileData.is_provisioned;
    }
    return false;
  }

  /**
   *
   */
  get __GETTERuserAccount() {
    if (this.profileData && this.profileData.billing_plan) {
      return this.profileData.billing_plan.name;
    }
    return undefined;
  }

  /**
   *
   */
  get __GETTERuserRole() {
    return this.profileData.role;
  }

  /**
   *
   */
  get __GETTERisUserRoleAdmin() {
    return !!(this.profileData.role === 'Admin');
  }

  /**
   *
   */
  get __GETTERisUserOnTrial() {
    return !!(this.profileData.billing_plan.name === 'Trial');
  }

  /**
   *
   */
  get __GETTERisUserOnNoLicense() {
    if (this.profileData) {
      return (this.profileData.billing_plan === null) && this.profileData.is_provisioned;
    }
    return false;
  }

  /**
   *
   */
  get __GETTERfullName() {
    if (this.profileData) {
      return this.profileData.name + ' ' + this.profileData.last_name;
    }
    return '';
  }

  /**
   *
   */
  get __GETTERfirstName() {
    if (this.profileData) {
      return this.profileData.name;
    }
    return '';
  }

  /**
   *
   *
   * @readonly
   * @memberof UserStore
   */
  get __GETTERisRecordingAllowed() {
    return this.profileData.is_recording_available;
  }

  /**
   * @readonly
   * @memberof UserStore
   */
  get __GETTERuserVmrAlias() {
    if (this.profileData) {
      return this.profileData.meeting_room.webrtc.alias;
    }
    return '';
  }

  /**
   *
   */
  get __GETTERisSVAdmin() {
    if (this.profileData) {
      return this.profileData.is_super_admin;
    }
    return false;
  }
}

export const userStore = new UserStore({store, name: 'userStore'});
