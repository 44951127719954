export default {
  methods: {
    qualityList() {
      return [
        {
          id: 'lowest',
          name: 'Lowest (max 100 kbps)',
          bandwidthKbps: 100,
        },
        {
          id: 'low',
          name: 'Low (max 500 kbps)',
          bandwidthKbps: 500,
        },
        {
          id: 'medium',
          name: 'Medium (max 1000 kbps)',
          bandwidthKbps: 1000,
        },
        {
          id: 'high',
          name: 'High (max 1500 kbps)',
          bandwidthKbps: 1500,
        },
        {
          id: 'best',
          name: 'Best (unlimited kbps)',
          bandwidthKbps: null,
        },
      ];
    },
  },
};
