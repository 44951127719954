<template>
  <header class="page-header">
    <nav class="navbar navbar-expand-md">
      <div class="container-fluid">
        <div class="navbar-column">
          <router-link
            class="navbar-brand"
            :to="{name: 'dashboard'}"
          >
            <img
              v-if="logo"
              alt="SimplyVideo"
              :src="logo"
            >
            <img
              v-else-if="businessLogo"
              alt="SimplyVideo"
              :src="businessLogo"
            >
          </router-link>
        </div>
        <div class="navbar-column">
          <ul class="navbar-nav list-group-horizontal">
            <li
              v-if="!isUserOnNoLicense"
              class="nav-item item-join"
            >
              <button
                class="btn btn-primary"
                @click="openPhonebookModal"
              >
                Phonebook
              </button>
            </li>
            <li class="nav-item item-join">
              <router-link :to="{name: 'join-meeting'}">
                <button
                  id="join-meeting"
                  class="btn btn-primary"
                >
                  Join meeting
                </button>
              </router-link>
            </li>
            <li class="nav-item nav-item-toggle">
              <button
                type="button"
                class="btn-toggle-menu"
                aria-expanded="false"
                @click="toggleSidebar"
              >
                <span class="line-1" />
                <span class="line-2" />
                <span class="line-3" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import {brandingStore} from '@/store/__STORE_branding';
import {userStore} from '@/store/__STORE_user';

export default {
  name: 'NavBar',
  computed: {
    logo() {
      return brandingStore.logo;
    },
    businessLogo() {
      return brandingStore.businessLogo;
    },
    authUser() {
      return userStore.__GETTERuserProfile;
    },
    isUserOnNoLicense() {
      return this.authUser?.is_provisioned && this.authUser?.billing_plan === null;
    },
  },
  methods: {
    toggleSidebar(e) {
      const aria = e.currentTarget.getAttribute('aria-expanded');
      document.body.classList.toggle('sidebar-open');
      e.currentTarget.setAttribute('aria-expanded', aria === 'false');
    },
    openPhonebookModal() {
      this.$emit('open-phonebook-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  @import '@/assets/scss/__variables';

  .nav-item.item-join {
    @media all and (max-width: $tabletPortraitMin) {
      margin-right: 1rem;
    }
  }

  .nav-item {
    display: flex;
    ::v-deep .nav-link {
      padding: 0;
      display: flex;
    }

    @media all and (max-width: $breakpoint-sm) {
      display: none;
    }
  }

  .nav-item-toggle {
    display: none;

    @media all and (max-width: $tabletPortraitMin) {
      display: flex;
    }
  }

  .btn-toggle-menu {
    padding: 10px;
    border: 0;
    outline: 0;
    position: relative;
    border-radius: 0 !important;
    background-color: transparent;

    &[aria-expanded="true"] {
      .line-1 {
        transform: translateY(7px) rotate(45deg);
      }

      .line-2 {
        opacity: 0;
      }

      .line-3 {
        transform: translateY(-7px) rotate(-45deg);
      }
    }

    @for $i from 1 through 3 {
      .line-#{$i} {
        width: 24px;
        height: 2px;
        background-color: #1a1a1a;
        display: flex;
        transition: all .2s;
        opacity: 1;
      }
    }

    .line-2 {
      margin: 5px 0;
    }
  }
</style>
