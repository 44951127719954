
import Vue from 'vue';
import Component from 'vue-class-component';
import IconStreamingOff from '@/components/icons/IconStreamingOff.vue';

import {pexipStore} from '@/store/__STORE_pexip';

@Component({
  components: {
    IconStreamingOff,
  },
})
/**
 * Intelligent component to select a screenshot for the annotation flow
 */
export default class EndStreamAcknowledgement extends Vue {
  name: 'Stop Livestream Acknowledgement Modal';
  /**
   * stop the active LiveStream
   */
  closeAck() {
    pexipStore.setLiveStreamAck(false);
  }
}
