<template>
  <div
    class="auto-dial-container"
  >
    <label>Protocol</label>
    <multiselect
      :value="value"
      :disabled="!editing"
      :options="protocolOptions"
      :searchable="false"
      @input="$emit('input', $event)"
    >
      <template
        slot="singleLabel"
        slot-scope="props"
      >
        <span class="d-flex">
          <i class="fas fa-exchange-alt mr-2" />
          <span class="option__desc"><span class="option__title">{{ props.option }}</span></span>
        </span>
      </template>
      <template
        slot="option"
        slot-scope="props"
      >
        <span class="option__desc"><span class="option__title">{{ props.option }}</span></span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'CallGroupAutoDialProtocol',
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    editing: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    protocolOptions() {
      return [
        'auto',
        'sip',
        'h323',
        'mssip',
      ];
    },
  },
};
</script>
