
export default {
  data() {
    return {
      microphoneVolume: 100,
      microphoneVolumeCheckInterval: null,
    };
  },
  methods: {
    monitorMicrophoneVolume(stream) {
      try {
        const AudioContext = window.AudioContext || // Default
                    window.webkitAudioContext || // Safari and old versions of Chrome
                    false;

        if (AudioContext) {
          const audioContext = new AudioContext();
          const analyser = audioContext.createAnalyser();
          const microphone = audioContext.createMediaStreamSource(stream);

          analyser.fftSize = 1024;
          microphone.connect(analyser);

          this.microphoneVolumeCheckInterval = window.setInterval(() => {
            const array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            let values = 0;

            const length = array.length;
            for (let i = 0; i < length; i++) {
              values += (array[i]);
            }

            let volume = Math.round(values / length);
            volume = volume <= 100 ? volume : 100;

            this.microphoneVolume = volume;
          }, 50);
        }
      } catch (e) {
        e;
      }
    },
  },
};
