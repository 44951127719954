import axios from 'axios';
import {Module, Action, Mutation, VuexModule} from 'vuex-class-modules';
import store from './';
import {mainStore} from './__STORE_main';
axios.defaults.headers = {
  ...axios.defaults.headers,
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};

/**
 *
 */
@Module
class MeetingsStore extends VuexModule {
  upcomingMeetings = {} as any;
  previousMeetings = {} as any;
  currentMeetings = [] as any;
  recentCallsCharts = {} as any;
  meetingsByDateChart = {} as any;
  meetingsByDateData = {} as any;
  historicEndpointsCharts = {} as any;
  historicEndpointsData = {} as any;
  historicEndpointsVariants = {} as any;
  roomUsage = {} as any;
  selMeeting = {} as any;
  recordings = [] as any;
  storage = {} as any;
  transcripts = [] as any;
  displayedFolderItemList = [] as any;
  displayedFileItemList = [] as any;
  folders = [] as any;
  audioPlayer = {
    selectedRecording: {} as any,
    recordingName: '',
    isShowAudioPlayer: false,
  };
  showDeleteRecording = {
    state: false,
    id: null,
    status: '',
  }
  showDeleteTranscript = {
    state: false,
    id: '',
  };
  showEditFile = {
    state: false,
    fileRecordingName: '',
    id: null,
  }
  showCloseFolder = {
    state: false,
    folderName: '',
    id: null,
    status: '',
  }
  showMoveFolder = {
    state: false,
    id: null,
    status: '',
  }
  phonebookContacts = {} as any;
  showPhonebookModal = false as boolean;
  // createFolderParentId = Number(localStorage.getItem('createFolderParentId'));
  createFolderParentId = null;
  parentPageNumber = 1;
  pageNumber=1;

  // Mutations

  /**
   *
   * @param {number} createFolderParentId
   */
   @Mutation
  private __setCreateFolderParentId(createFolderParentId: number) {
    this.createFolderParentId = createFolderParentId;
  }

   /**
   *
   * @param {number} pageNumber
   */
     @Mutation
   private __setPageNumber(pageNumber: number) {
     this.pageNumber = pageNumber;
   }

     /**
   *
   * @param {number} parentPageNumber
   */
      @Mutation
     private __setParentPageNumber(parentPageNumber: number) {
       this.parentPageNumber = parentPageNumber;
     }

  /**
   * Mutation to set the transcripts state
   * @param {any} data - transcripts from the api
   */
  @Mutation
      private __setTranscriptsData(data: any) {
        this.transcripts = data;
      }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setUpcomingMeetingsData(data: any) {
    this.upcomingMeetings = data;
  }

  /**
   *
   */
  @Mutation
  private __emptyUpcomingMeetingsData() {
    this.upcomingMeetings = [];
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setPreviousMeetingsData(data: any) {
    this.previousMeetings = data;
  }

  /**
   *
   */
  @Mutation
  private __emptyPreviousMeetingsData() {
    this.previousMeetings = [];
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setCurrentMeetingsData(data: any) {
    this.currentMeetings = data;
  }

  /**
   *
   */
  @Mutation
  private __emptyCurrentMeetingsData() {
    this.currentMeetings = [];
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setSelMeeting(data: any) {
    this.selMeeting = data;
  }

  /**
   *
   */
  @Mutation
  private __emptyRecordingData() {
    this.recordings = [];
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setRecordingData(data: any) {
    this.recordings = data;
  }

  /**
   *
   * @param {any} data
   */
    @Mutation
  private __setRecordingFolderItems(data: any) {
    this.displayedFolderItemList = data;
  }

    /**
     *
     * @param {any} data
     */
    @Mutation
    private __setRecordingFileItems(data: any) {
      this.displayedFileItemList = data;
    }

  /**
   *
   * @param {any} data
   */
  @Mutation
    private __setStorageData(data: any) {
      this.storage = data;
    }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setRecentCallsChartsData(data: any) {
    this.recentCallsCharts = data;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setMeetingsByDateChartData(data: any) {
    this.meetingsByDateChart = data;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setMeetingsByDateData(data: any) {
    this.meetingsByDateData = data;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setHistoricEndpointsCharts(data: any) {
    this.historicEndpointsCharts = data;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setHistoricEndpointsData(data: any) {
    this.historicEndpointsData = data;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setHistoricEndpointsVariantsArray(data: any) {
    this.historicEndpointsVariants = data;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setHistoricEndpointsVariantsData(data: any) {
    const objName = data.endpoint;
    this.historicEndpointsVariants[objName] = data.variants;
  }

  /**
   *
   * @param {any} data
   */
  @Mutation
  private __setRoomUsageData(data: any) {
    this.roomUsage = data;
  }

  /**
   * @param {any} recording
   */
  @Mutation
  private __setSelectedRecording(recording: any) {
    this.audioPlayer.selectedRecording = recording;
  }

  /**
   * @param {boolean} value
   */
  @Mutation
  private __setIsShowAudioPlayer(value: boolean) {
    this.audioPlayer.isShowAudioPlayer = value;
  }

  /**
   * @param {string} recordingName
   */
  @Mutation
  private __setRecordingName(recordingName: string) {
    this.audioPlayer.recordingName = recordingName;
  }

  /**
   * @param {{
   *  value: boolean,
   *  id: number,
   * status: string,
   * }} deleteRecording
   */
  @Mutation
  private __setShowConfirmDeleteRecording(deleteRecording: {
    value: boolean,
    id: number,
    status: string,
  }) {
    this.showDeleteRecording.state = deleteRecording.value;
    this.showDeleteRecording.id = deleteRecording.id;
    this.showDeleteRecording.status = deleteRecording.status;
  }

  /**
   * @param {{
   *  value: boolean,
   *  id: string
   * }} deleteTranscript
   */
  @Mutation
  private __setShowConfirmDeleteTranscript(deleteTranscript: {
    value: boolean,
    id: string,
  }) {
    this.showDeleteTranscript.state = deleteTranscript.value;
    this.showDeleteTranscript.id = deleteTranscript.id;
  }

  /**
   * @param {string} editFileName
   */
    @Mutation
  private __setFileRecordingName(editFileName: string) {
    this.showEditFile.fileRecordingName = editFileName;
  }

      /**
   * @param {{
       *  value: boolean,
       *  id: number,
       * name: string
       * }} editFile
       */
      @Mutation
    private __setShowConfirmEditFile(editFile: {
        value: boolean,
        id: number,
        name: string
      }) {
      this.showEditFile.state = editFile.value;
      this.showEditFile.id = editFile.id;
    }

        /**
   * @param {{
         *  value: boolean,
         * id: number,
         * name: string,
         * status: string
         * }} closeFolder
         */
        @Mutation
      private __setShowConfirmCloseFolder(closeFolder: {
          value: boolean,
          id: number,
              name: string,
              status: string
        }) {
        this.showCloseFolder.state = closeFolder.value;
        this.showCloseFolder.id = closeFolder.id;
        this.showCloseFolder.folderName = closeFolder.name;
        this.showCloseFolder.status = closeFolder.status;
      }

               /**
   * @param {{
                *  value: boolean,
                * id: number,
                * status: string
                * }} moveFolder
                */
               @Mutation
        private __setShowConfirmMoveFolder(moveFolder: {
                 value: boolean,
                 id: number,
                 status: string
               }) {
          this.showMoveFolder.state = moveFolder.value;
          this.showMoveFolder.id = moveFolder.id;
          this.showMoveFolder.status = moveFolder.status;
        }
               /**
   *
   * @param {any} data
   */
  @Mutation
               private __setFolderListData(data: any) {
                 this.folders = data;
               }

  /**
   * Mutation to set contacts list
   * @param {any} data - contacts list
   */
  @Mutation
  private setPhonebookContacts(data: any) {
    this.phonebookContacts = data;
  }

  /**
   * Mutation to set Phonebook modal state
   * @param {boolean} data - state
   */
  @Mutation
  setPhonebookModalState(data: boolean) {
    this.showPhonebookModal = data;
  }

  // Actions

  /**
   *
   * @param {number} createFolderParentId - welcome message to set
   */
    @Action
  async setCreateFolderParentId(createFolderParentId: number) {
    this.__setCreateFolderParentId(createFolderParentId);
  }

    /**
   *
   * @param {number} pageNumber - welcome message to set
   */
     @Action
    async setPageNumber(pageNumber: number) {
      this.__setPageNumber(pageNumber);
    }

     /**
   *
   * @param {number} parentPageNumber - welcome message to set
   */
        @Action
     async setParentPageNumber(parentPageNumber: number) {
       this.__setParentPageNumber(parentPageNumber);
     }

  /**
   *
   * @param {string} uuid - uuid of the transcript
   * @return {Promise<any>}
   */
  @Action
        getPdf(uuid: string): Promise<any> {
          return new Promise((resolve, reject) => {
            axios({url: `/transcripts/${uuid}/pdf`, method: 'GET'})
                .then((resp) => {
                  resolve(resp);
                })
                .catch((err) => {
                  reject(err);
                });
          });
        }

  /**
   *
   * @param {string} uuid - transcript uuid
   * @return {Promise<any>}
   */
  @Action
  getZip(uuid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios({url: `/transcripts/${uuid}/zip`, method: 'GET'})
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
    });
  }

  /**
   * set state of show Delete Recording
   * @param {{
   *  value: boolean,
   *  id: number,
   * status: string,
   * }} deleteRecording
   */
  @Action
  async setShowDeleteRecording(deleteRecording: {
    value: boolean,
    id: number,
    status: string,
  }) {
    this.__setShowConfirmDeleteRecording(deleteRecording);
  }

     /**
   * set state of show Delete Recording
   * @param {{
      *  value: boolean,
      *  id: number,
      * name: string
      * }} editFile
      */
     @Action
  async setShowEditFileRecording(editFile: {
       value: boolean,
       id: number,
       name: string
     }) {
    this.__setShowConfirmEditFile(editFile);
    this.__setFileRecordingName(editFile.name);
  }

      /**
   * set state of show close  Floder
   * @param {{
       *  value: boolean,
       * id: number,
       * name: string,
       * status: string
       * }} closeFolder
       */
      @Action
     async setShowCloseFolder(closeFolder: {
        value: boolean,
        id: number,
        name: string,
        status: string
      }) {
       this.__setShowConfirmCloseFolder(closeFolder);
     }

      /**
   * set state of show move  Floder
   * @param {{
          *  value: boolean,
          *  id: number,
          *  status: string
          * }} moveFolder
          */
       @Action
      async setShowMoveFolder(moveFolder: {
        value: boolean,
        id: number,
        status: string
       }) {
        this.__setShowConfirmMoveFolder(moveFolder);
      }

  /**
   * set state of show Delete Transcript
   * @param  {{
   *  value: boolean,
   *  id: string,
   * }} deleteTranscript
   */
  @Action
       async setShowDeleteTranscript(deleteTranscript: {
    value: boolean,
    id: string,
  }) {
         this.__setShowConfirmDeleteTranscript(deleteTranscript);
       }

  /**
   * Open the Audio PLayer component with the selected Recording
   * @param {{
   *  recording: any,
   *  name: string,
   * }} selectedRecording
   */
  @Action
  async openAudioPlayer(selectedRecording: {
    recording: any,
    name: string,
  }) {
    this.__setRecordingName(selectedRecording.name);
    this.__setSelectedRecording(selectedRecording.recording);
    this.__setIsShowAudioPlayer(true);
  }

  /**
   * close Audio/Video Player and clear states
   */
  @Action
  async closeAudioPlayer() {
    this.__setRecordingName('');
    this.__setSelectedRecording({});
    this.__setIsShowAudioPlayer(false);
  }

  /**
   * Get the users transcripts
   * @param {number} [pageNum] - optional page param to get specific page for pagination
   * @return {Promise} - api response
   */
  @Action
  async getTranscripts(pageNum?: number): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      let url = '/transcripts';
      if (pageNum) {
        url += `?page=${pageNum}`;
      }
      axios({url: url, method: 'GET'})
          .then((response) => {
            if (response.status === 200) {
              this.__setTranscriptsData(response.data);
            }
            mainStore.setStatus('success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Get the users upcoming meetings
   * @param {number} [pageNum] - optional param to specify page number to fetch
   * @return {Promise} - api response
   */
  @Action
  async getUpcomingMeetingsData(pageNum?: number): Promise<any> {
    this.__emptyUpcomingMeetingsData();
    mainStore.setStatus( 'loading');
    return new Promise((resolve, reject) => {
      let url='/upcoming-meetings';
      if (pageNum) {
        url += `?page=${pageNum}`;
      }
      axios({url: url, method: 'GET'})
          .then((response) => {
            if (response.status === 200) {
              this.__setUpcomingMeetingsData(response.data);
            }
            mainStore.setStatus( 'success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus( 'error');
            reject(err);
          });
    });
  }

  /**
   * Get previous meeting data for user
   * @param {any} param - object that contains the page number to fetch
   * @return {Promise} - api response
   */
  @Action
  async getPreviousMeetingsData(param: any): Promise<any> {
    this.__emptyPreviousMeetingsData();
    mainStore.setStatus( 'loading');
    return new Promise((resolve, reject) => {
      const url = '/previous-meetings?page=' + param.pageNum;
      axios({url: url, method: 'GET'})
          .then((response) => {
            if (response.status === 200) {
              this.__setPreviousMeetingsData(response.data);
            }
            mainStore.setStatus( 'success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus( 'error');
            reject(err);
          });
    });
  }

  /**
   * Get active conferences data
   * @return {Promise} - api response
   */
  @Action
  async getCurrentMeetingsData(): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = '/operations-dashboard/data/active-conferences';
      axios({url: url, method: 'GET'})
          .then((response) => {
            //
            if (response.status === 200) {
              this.__setCurrentMeetingsData(response.data);
            }
            mainStore.setStatus( 'success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus( 'error');
            reject(err);
          });
    });
  }

  /**
   * Get recent call data for user account
   * @return {Promise} - api response
   */
  @Action
  async getRecentCallsChartsData(): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = '/operations-dashboard/chart/recent-calls';
      axios({url: url, method: 'GET'})
          .then((response) => {
            if (response.status === 200) {
              this.__setRecentCallsChartsData(response.data);
            }
            mainStore.setStatus( 'success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Get meetings for given dates
   * @param {any} [dates] - optional object containing to and from dates for fetching data in range
   * @return {Promise} - api response
   */
  @Action
  async getMeetingsByDateData(dates?: any): Promise<any> {
    return new Promise((resolve, reject)=>{
      let url = 'operations-dashboard/data/meetings-by-date';
      if (dates && (dates.fromDate || dates.toDate)) {
        url += (dates ? '?' : '');
        url += (dates.fromDate ? 'from='+dates.fromDate : '');
        url += (dates.fromDate && dates.toDate ? '&' : '');
        url += (dates.toDate ? 'to='+dates.toDate : '');
      }
      axios({url: url, method: 'GET'})
          .then((response) => {
            if (response.status === 200) {
              this.__setMeetingsByDateData(response.data);
            }
            mainStore.setStatus('success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Get data for meetings by date chart
   * @param {any} [dates] - optional object param that contains to and from dates for querying specific date range
   * @return {Promise} - api response
   */
  @Action
  async getMeetingsByDateChart(dates?: any): Promise<any> {
    return new Promise((resolve, reject)=>{
      let url = 'operations-dashboard/chart/meetings-by-date';
      if (dates && (dates.fromDate || dates.toDate)) {
        url += (dates ? '?' : '');
        url += (dates.fromDate ? 'from='+dates.fromDate : '');
        url += (dates.fromDate && dates.toDate ? '&' : '');
        url += (dates.toDate ? 'to='+dates.toDate : '');
      }
      axios({url: url, method: 'GET'})
          .then((response) => {
            if (response.status === 200) {
              this.__setMeetingsByDateChartData(response.data);
            }
            mainStore.setStatus( 'success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus( 'error');
            reject(err);
          });
    });
  }

  /**
   * Get data for historic call endpoints charts
   * @param {any} [dates] - optional query param object containing to and from dates to query given date range
   * @return {Promise} - api response
   */
  @Action
  async getHistoricEndpointsCharts(dates?: any): Promise<any> {
    return new Promise((resolve, reject)=>{
      let url = 'operations-dashboard/chart/endpoints';
      if (dates && (dates.fromDate || dates.toDate)) {
        url += (dates ? '?' : '');
        url += (dates.fromDate ? 'from='+dates.fromDate : '');
        url += (dates.fromDate && dates.toDate ? '&' : '');
        url += (dates.toDate ? 'to='+dates.toDate : '');
      }
      axios({url: url, method: 'GET'})
          .then((response) => {
            if (response.status === 200) {
              this.__setHistoricEndpointsCharts(response.data);
            }
            mainStore.setStatus('success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Get historic call endpoints drilldown data
   * @param {any} [dates] - optional object param containing to and from dates
   * @return {Promise} - api response
   */
  @Action
  async getHistoricEndpointsData( dates: any): Promise<any> {
    return new Promise((resolve, reject)=>{
      let url = 'operations-dashboard/data/endpoints';
      if (dates && (dates.fromDate || dates.toDate)) {
        url += (dates ? '?' : '');
        url += (dates.fromDate ? 'from='+dates.fromDate : '');
        url += (dates.fromDate && dates.toDate ? '&' : '');
        url += (dates.toDate ? 'to='+dates.toDate : '');
      }
      //
      axios({url: url, method: 'GET'})
          .then((response) => {
            if (response.status === 200) {
              //
              this.__setHistoricEndpointsData(response.data);
            }
            mainStore.setStatus('success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Get historic call endpoints variants data
   * @param {any} attrs - object containing optional to and from dates and required endpoints
   * @return {Promise} - api response
   */
  @Action
  async getHistoricEndpointsVariants( attrs: any): Promise<any> {
    return new Promise((resolve, reject)=>{
      let url = 'operations-dashboard/data/endpoint-variants';
      if (attrs.dates) {
        url += (attrs.dates ? '?' : '');
        url += (attrs.dates.fromDate ? 'from='+attrs.dates.fromDate : '');
        url += (attrs.dates.fromDate && attrs.dates.toDate ? '&' : '');
        url += (attrs.dates.toDate ? 'to='+attrs.dates.toDate : '');
      }
      if (attrs.endpoints) {
        this.__setHistoricEndpointsVariantsArray(attrs.endpoints);

        attrs.endpoints.forEach((endpoint) => {
          const endpointName = (Object.keys(endpoint));
          const uniqueUrl = url+'&endpoint_name='+endpointName;
          axios({url: uniqueUrl, method: 'GET'})
              .then((response) => {
                if (response.status === 200) {
                  this.__setHistoricEndpointsVariantsData( {
                    endpoint: endpointName,
                    variants: response.data.variants,
                  });
                }
                mainStore.setStatus('success');
                resolve(response);
              })
              .catch((err) => {
                mainStore.setStatus('error');
                reject(err);
              });
        });
      }
    });
  }

  /**
   * Get personal room usage data for account
   * @param {any} [dates] - optional object param with to and from dates for filtering
   * @return {Promise} - api response
   */
  @Action
  async getRoomUsageData( dates: any): Promise<any> {
    return new Promise((resolve, reject)=>{
      let url = 'operations-dashboard/room-usage';
      if (dates && (dates.fromDate || dates.toDate)) {
        url += (dates ? '?' : '');
        url += (dates.fromDate ? 'from='+dates.fromDate : '');
        url += (dates.fromDate && dates.toDate ? '&' : '');
        url += (dates.toDate ? 'to='+dates.toDate : '');
      }
      //
      axios({url: url, method: 'GET'})
          .then((response) => {
            if (response.status === 200) {
              //
              this.__setRoomUsageData(response.data);
            }
            mainStore.setStatus('success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Create a new meeting for the user
   * @param {Object} param - object containing meeting data to send to the api to create meeting
   * @return {Promise} - api response
   */
  @Action
  async addMeeting(param: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: '/meetings', data: param, method: 'POST'})
          .then((response) => {
            if (response.status === 201) {
              this.__setSelMeeting(response.data);
            }
            mainStore.setStatus('success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Get a specific meeting from the api by meeting id
   * @param {number} id - id of the meeting to fetch from the api
   * @return {Promise} - api response
   */
  @Action
  async getMeeting(id: number): Promise<any> {
    this.__setSelMeeting({});
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: `/meetings/${id}`, method: 'GET'})
          .then((response) => {
            this.__setSelMeeting(response.data);
            mainStore.setStatus('success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Update a given meeting
   * @param {any} param - object containing the updated meeting information
   * @return {Promise} - api response
   */
  @Action
  async updateMeeting( param: any): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: `/meetings/${param.id}`, data: param, method: 'PATCH'})
          .then((response) => {
            mainStore.setStatus('success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Delete a meeting given its id
   * @param {number} id - the ID of the meeting to delete
   * @return {Promise} - api response
   */
  @Action
  async deleteMeeting(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: `/meetings/${id}`, method: 'DELETE'})
          .then((response) => {
            this.getUpcomingMeetingsData();
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Get the users recordings
   * @param {number} [pageNum] - optional page param to get specific page for pagination
   * @return {Promise} - api response
   */
  @Action
  async getRecordings(pageNum?: number): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      let url = '/recordings';
      if (pageNum) {
        url += `?page=${pageNum}`;
      }
      axios({url: url, method: 'GET'})
          .then((response) => {
            if (response.status === 200) {
              this.__setRecordingData(response.data);
            }
            mainStore.setStatus('success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Get the users recording storage plan
   * @return {Promise} - api response
   */
  @Action
  async getStorage(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios({url: '/storage', method: 'GET'})
          .then((response) => {
            this.__setStorageData(response.data);
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Get the users recordings
   * @param {number} [pageNum] - optional page param to get specific page for pagination
   * @return {Promise} - api response
   */
         @Action
  async getRecordingsData({perPage = 5, pageNum = this.parentPageNumber}: {perPage?: number, pageNum?: number} = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      const url = `/getRecordingsData?per_page=${perPage}&page=${this.parentPageNumber}`;
      axios({url: url, method: 'GET'})
          .then((response) => {
            if (response.status === 200) {
              this.__setRecordingData(response.data);
              this.__setRecordingFolderItems(response.data.data.folders);
              this.__setRecordingFileItems(response.data.data.recordings);
            }
            mainStore.setStatus('success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

         /**
       * @param {number} id - ID of the folder to be open
       * @param {number} [pageNum] - optional page param to get specific page for pagination
       * @return {Promise} - api response
       */
        @Action
         async getFolderData(id: number, {perPage = 2, pageNum = this.pageNumber}: {perPage?: number, pageNum?: number} = {}): Promise<any> {
           return new Promise((resolve, reject) => {
             mainStore.setStatus('loading');
             const url = `/getFolderData/${id}?per_page=${perPage}&page=${this.pageNumber}`;
             axios({url: url, method: 'GET'})
                 .then((response) => {
                   if (response.status === 200) {
                     this.__setRecordingData(response.data);
                     this.__setRecordingFolderItems(response.data.folderData.subfolders_data);
                     this.__setRecordingFileItems(response.data.folderData.recordings);
                   }
                   mainStore.setStatus('success');
                   resolve(response);
                 })
                 .catch((err) => {
                   mainStore.setStatus('error');
                   reject(err);
                 });
           });
         }

        /**
   * Get the users recordings
   * @param {number} [folderId]
   * @return {Promise} - api response
   */
  @Action
        async getFoldersList(folderId?: number): Promise<any> {
          return new Promise((resolve, reject) => {
            mainStore.setStatus('loading');
            axios({url: `/getFoldersList?folder_id=${folderId}`, method: 'GET'})
                .then((response) => {
                  if (response.status === 201) {
                    this.__setFolderListData(response.data);
                  }
                  mainStore.setStatus('success');
                  resolve(response);
                })
                .catch((err) => {
                  mainStore.setStatus('error');
                  reject(err);
                });
          });
        }

  /**
   * Create a folder in users storage
   * @param {Object} payload  - create folder payload, including folder_name
   * @return {Promise} - api response
   */
     @Action
  async createFolder(payload: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: `/userRecordingFolder`, data: payload, method: 'POST'})
          .then((response) => {
            if (response.status === 201) {
              if (this.createFolderParentId) {
                this.getFolderData(this.createFolderParentId);
              } else {
                this.getRecordingsData();
              }
            }
            mainStore.setStatus('success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

     /**
   * Create a folder in users storage
   * @param {Object} payload  - create folder payload, including folder_name
   * @return {Promise} - api response
   */
         @Action
     async renameFolder(payload: Object): Promise<any> {
       return new Promise((resolve, reject) => {
         mainStore.setStatus('loading');
         axios({url: `/userRecordingFolder`, data: payload, method: 'PATCH'})
             .then((response) => {
               if (response.status === 200) {
                 if (this.createFolderParentId) {
                   this.getFolderData(this.createFolderParentId);
                 } else {
                   this.getRecordingsData();
                 }
               }
               mainStore.setStatus('success');
               resolve(response);
             })
             .catch((err) => {
               mainStore.setStatus('error');
               reject(err);
             });
       });
     }

         /**
   * Edit a file in users storage
   * @param {Object} payload  - edit file payload, including file_name
   * @return {Promise} - api response
   */
     @Action
         async renameFile(payload: Object): Promise<any> {
           return new Promise((resolve, reject) => {
             mainStore.setStatus('loading');
             axios({url: `/renameRecordingFile`, data: payload, method: 'PATCH'})
                 .then((response) => {
                   if (response.status === 200) {
                     if (this.createFolderParentId) {
                       this.getFolderData(this.createFolderParentId);
                     } else {
                       this.getRecordingsData();
                     }
                   }
                   mainStore.setStatus('success');
                   resolve(response);
                 })
                 .catch((err) => {
                   mainStore.setStatus('error');
                   reject(err);
                 });
           });
         }

     /**
   * Move a folder in users storage
   * @param {Object} payload  - move folder payload, including folder_id
   * @return {Promise} - api response
   */
           @Action
     async moveFolder(payload: Object): Promise<any> {
       return new Promise((resolve, reject) => {
         mainStore.setStatus('loading');
         axios({url: `/moveUserFolder`, data: payload, method: 'PATCH'})
             .then((response) => {
               if (response.status === 200) {
                 if (this.createFolderParentId) {
                   this.getFolderData(this.createFolderParentId);
                 } else {
                   this.getRecordingsData();
                 }
               }
               mainStore.setStatus('success');
               resolve(response);
             })
             .catch((err) => {
               mainStore.setStatus('error');
               reject(err);
             });
       });
     }

           /**
   * Move a folder in users storage
   * @param {Object} payload  - move folder payload, including folder_id
   * @return {Promise} - api response
   */
           @Action
           async moveRecordingFile(payload: Object): Promise<any> {
             return new Promise((resolve, reject) => {
               mainStore.setStatus('loading');
               axios({url: `/moveRecordingFile`, data: payload, method: 'PATCH'})
                   .then((response) => {
                     if (response.status === 200) {
                       if (this.createFolderParentId) {
                         this.getFolderData(this.createFolderParentId);
                       } else {
                         this.getRecordingsData();
                       }
                     }
                     mainStore.setStatus('success');
                     resolve(response);
                   })
                   .catch((err) => {
                     mainStore.setStatus('error');
                     reject(err);
                   });
             });
           }

  /**
   * Delete a given recording from the users storage
   * @param {number} id - ID of the recording to be deleted
   * @return {Promise} - api response
   */
  @Action
           async deleteRecording(id: number): Promise<any> {
             return new Promise((resolve, reject) => {
               mainStore.setStatus('loading');
               axios({url: `/recordings/${id}`, method: 'DELETE'})
                   .then((response) => {
                     if (response.status === 204) {
                       if (this.createFolderParentId) {
                         this.getFolderData(this.createFolderParentId);
                       } else {
                         this.getRecordingsData();
                       }
                     }
                     mainStore.setStatus('success');
                     resolve(response);
                   })
                   .catch((err) => {
                     mainStore.setStatus('error');
                     reject(err);
                   });
             });
           }

  /**
   * Delete a given recording from the users storage
    * @param {Object} payload  - delete folder payload, including folder_id
   * @return {Promise} - api response
   */
     @Action
  async deleteFolderRecording(payload: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: `/userRecordingFolder`, data: payload, method: 'DELETE'})
          .then((response) => {
            if (response.status === 200) {
              if (this.createFolderParentId) {
                this.getFolderData(this.createFolderParentId);
              } else {
                this.getRecordingsData();
              }
            }
            mainStore.setStatus('success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Delete a given transcript from the users storage
   * @param {string} id - ID of the transcript to be deleted
   * @return {Promise} - api response
   */
  @Action
     async deleteTranscript(id: string): Promise<any> {
       return new Promise((resolve, reject) => {
         mainStore.setStatus('loading');
         axios({url: `/transcripts/${id}`, method: 'DELETE'})
             .then((response) => {
               if (response.status === 204) {
                 this.getTranscripts();
               }
               mainStore.setStatus('success');
               resolve(response);
             })
             .catch((err) => {
               mainStore.setStatus('error');
               reject(err);
             });
       });
     }

  /**
   * Send meeting invite to email address (DEPRECATED)
   * @param {Object} payload - meeting invite payload, including email and meeting id
   * @return {Promise} - api response
   */
  @Action
  async sendInvite(payload: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');

      axios({url: '/invite', data: payload, method: 'POST'})
          .then((response) => {
            mainStore.setStatus('success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Return a list of timezones
   * @return {Promise} - api response
   */
  @Action
  async getTimezones(): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: `/timezones/`, method: 'GET'})
          .then((response) => {
            mainStore.setStatus('success');
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            reject(err);
          });
    });
  }

  /**
   * Return a phonebook contcts
   * @param {String} payload - search param for contact filter
   * @return {Promise} - api response
   */
  @Action
  async getPhonebookContacts(payload: string): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setStatus('loading');
      axios({url: `/phonebook?search=${payload}`, method: 'GET'})
          .then((response) => {
            mainStore.setStatus('success');
            this.setPhonebookContacts(response.data);
            resolve(response);
          })
          .catch((err) => {
            mainStore.setStatus('error');
            this.setPhonebookContacts({});
            reject(err);
          });
    });
  }

  // Getters
  /**
   *
   */
  get __GETTERupcomingMeetingsList() {
    return this.upcomingMeetings;
  }

  /**
   * Get state of Video player
   */
  get __GETTERaudioPlayer() {
    return this.audioPlayer;
  }

  /**
   * Get state for modal
   */
  get __GETTERshowDeleteRecording() {
    return this.showDeleteRecording;
  }

  /**
   * Get state for modal
   */
  get __GETTERshowDeleteTranscript() {
    return this.showDeleteTranscript.state;
  }

  /**
   * Get state for modal
   */
  get __GETTERdeleteRecordingId() {
    return this.showDeleteRecording.id;
  }

  /**
   * Get state for modal
   */
  get __GETTERdeleteTranscriptId() {
    return this.showDeleteTranscript.id;
  }

  /**
   * Get state for modal
   */
  get __GETTERshowEditFile() {
    return this.showEditFile.state;
  }

  /**
     * Get state for modal
     */
  get __GETTERshowEditFileName() {
    return this.showEditFile;
  }

  /**
     * Get state for modal
     */
  get __GETTERshowCloseFolder() {
    return this.showCloseFolder;
  }

  get __GETTERshowCloseMoveFolder() {
    return this.showMoveFolder;
  }

  /**
   * Get state for phonebook contacts
   */
  get __GETTERphonebookContacts() {
    return this.phonebookContacts;
  }

  /**
   * Get state for phonebook modal
   */
  get __GETTERshowPhonebookModal() {
    return this.showPhonebookModal;
  }

  /**
   * Get state for modal
   */
  get __GETTERparentFolderId() {
    return this.createFolderParentId;
  }
}

export const meetingStore = new MeetingsStore({store, name: 'meetingsStore'});
