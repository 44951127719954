<template>
  <span>
    <svg
      id="Layer_1"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 107.5 70.8"
      style="enable-background:new 0 0 107.5 70.8;"
      xml:space="preserve"
    >
      <g>
        <g id="Layer_2_2_">
          <g>
            <circle
              cx="53.6"
              cy="35.4"
              r="6"
            />
          </g>
        </g>
        <g>
          <path
            d="M83.6,35.5c0-6.9-2.4-13.3-6.3-18.4l0,0c0,0,0,0,0,0c-2-2.6-5.8-3.1-8.4-1.1c-2.6,2-3.1,5.8-1.1,8.4c0,0,0,0,0,0
            c2.4,3,3.8,6.9,3.8,11c0,4.2-1.4,8-3.8,11.1l0,0c-2,2.6-1.5,6.3,1.1,8.4c2.6,2,6.4,1.6,8.4-1c0-0.1,0.1-0.1,0.1-0.2
            C81.3,48.6,83.6,42.3,83.6,35.5z"
          />
          <path
            d="M39.5,46.5L39.5,46.5C39.5,46.5,39.5,46.5,39.5,46.5C39.5,46.5,39.5,46.5,39.5,46.5C39.5,46.4,39.5,46.4,39.5,46.5
            c-2.4-3.1-3.8-6.9-3.8-11.1c0-4.1,1.4-7.9,3.7-10.9c0,0,0.1-0.1,0.1-0.1c2-2.6,1.6-6.4-1-8.4c-2.6-2-6.4-1.6-8.4,1c0,0,0,0,0,0
            l0,0c-4,5.1-6.3,11.4-6.3,18.4c0,6.9,2.3,13.3,6.3,18.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c2,2.6,5.8,3,8.4,1
            C41,52.8,41.5,49.1,39.5,46.5z"
          />
          <path
            d="M20.6,61.2L20.6,61.2c-5.5-7.1-8.8-16.1-8.8-25.8c0-9.7,3.3-18.6,8.8-25.7c0,0,0,0,0.1-0.1c2-2.6,1.6-6.4-1-8.4
            c-2.6-2-6.4-1.6-8.4,1c0,0,0,0,0,0l0,0C4,11.5-0.2,22.9-0.2,35.4c0,12.5,4.2,23.9,11.3,33.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
            l0,0c2,2.6,5.8,3,8.4,1C22.1,67.5,22.6,63.8,20.6,61.2z"
          />
          <path
            d="M96.3,68.4c7-9.1,11.3-20.5,11.3-32.9c0-12.5-4.2-24-11.3-33.1l0,0c0,0,0,0,0,0c-2-2.6-5.8-3.1-8.4-1.1
            c-2.6,2-3.1,5.8-1.1,8.4c0,0,0,0,0,0l0,0c5.5,7.1,8.8,16,8.8,25.7c0,9.7-3.3,18.7-8.9,25.8l0,0c-2,2.6-1.5,6.3,1.1,8.4
            c2.6,2,6.4,1.6,8.4-1C96.2,68.5,96.2,68.4,96.3,68.4z"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
import Props from './propsMixin';
export default {
  mixins: [Props],
};
</script>
