/**
 * Methods to make device-friendly designs possible.
 */
export default {
  methods: {
    /**
     * Whether the device is a mobile device or not.
     *
     * Chosen based off of the Bootstrap "sm" breakpoint.
     * https://getbootstrap.com/docs/4.1/layout/overview/
     * @return {boolean}
     */
    isMobileDevice() {
      return ( window.innerWidth <= 813 );
    },

    /**
     * Setting up secondary check to help determine moving the setting buttons
     * @return {boolean}
     */
    thinDesktop() {
      return ( window.innerWidth <= 1120 );
    },

    /**
     * Setting up secondary check to help separate out when we hide the chat in ongoing meeting
     * @return {boolean}
     */
    isMobile() {
      return ( window.innerWidth <= 576 );
    },

    /**
     * Calculates the orientation of the device.
     * @return {String} "Landscape" or "Portrait"
     */
    isLandscape() {
      return window.innerWidth > window.innerHeight;
    },
  },
};
