
import Component, {mixins} from 'vue-class-component';
import {Watch} from 'vue-property-decorator';
import {Responsive} from '@/mixins/Responsive';

import {EmojiButton} from '@joeattardi/emoji-button';
import ImageModal from '@/components_v2/intelligent/annotation/__COMP_ImageModal.vue';
import UploadPreview from '@/components_v2/generic/call/__COMP_UploadPreview.vue';
import ChatMessage from '@/components_v2/generic/call/__COMP_ChatMessage.vue';

import IconSpeechBubble from '@/components/icons/IconSpeechBubble.vue';
import IconSmile from '@/components/icons/IconSmile.vue';
import SmallSpinner from '@/components_v2/generic/misc/__COMP_SmallSpinner.vue';
import {Mentionable} from 'vue-mention';
import * as linkify from 'linkifyjs';

import {pexipStore} from '@/store/__STORE_pexip';
import {messageStore} from '@/store/__STORE_message';
import {callStore} from '@/store/__STORE_call';
import {authStore} from '@/store/__STORE_auth';
import {userStore} from '@/store/__STORE_user';

@Component({
  components: {
    ChatMessage,
    IconSpeechBubble,
    IconSmile,
    ImageModal,
    UploadPreview,
    Mentionable,
    SmallSpinner,
  },
})
/**
 * Intelligent component that manages all the chat messages for a conference
 */
export default class ChatBox extends mixins(Responsive) {
  name: 'Chat Box'

  private message = ''
  private picker = null
  private sending = false
  private mentionOpen = false
  messageMaxLength = 2000
  totalRemaining = 2000

  /**
   * Updates when the messages in the store update
   * @param {any} newVal - the new value of the array of messages
   */
  @Watch('chatMessages')
  updateMessages(newVal: any) {
    let hasUrl = false;
    let hasFile = false;
    if (newVal.length) {
      const message = newVal[newVal.length - 1];
      hasUrl = this.checkforURL(message.body);
      hasFile = !!message.file;
    }
    let timeout = 500;
    if (hasUrl && !hasFile) {
      timeout = 1000;
    }
    const contentRef = this.$refs.chatContent as any;
    window.setTimeout(() => {
      contentRef.scrollTop = contentRef.scrollHeight;
    }, timeout);
  }

  /**
   * Watches the connection state of the pexip call and initialises the chat when it changes
   * @param {boolean} val - the new value of the connected state
   */
  @Watch('connectedState', {immediate: true})
  initialiseChat(val: boolean) {
    if (val) {
      const registeredName = (authStore.__GETTERisUserLoggedIn) ? userStore.__GETTERfullName : pexipStore.call.displayName;
      messageStore.initialiseChat({
        participantUuid: pexipStore.__GETTERmyUuid,
        roomAlias: callStore.__GETTERcallAlias,
        registeredName: registeredName,
        displayName: pexipStore.call.displayName,
      });
    }
  }

  /**
   * Watches for when a file is being uploaded, if it is then the chat window is focused
   * @param {boolean} val - whether a file is uploaded / is being uploaded
   */
  @Watch('fileAttached')
  focusChat(val: boolean) {
    if (val) {
      const chatRef = this.$refs.chatMessage as any;
      chatRef.focus();
    }
  }

  /**
   * @param {number} messageLength
   * @return {number}
   */
  checkMessageLength(messageLength: number) {
    return this.messageMaxLength - this.message.length;
  }

  /**
   * gives liveCountDown
   */
  liveCountDown() {
    this.totalRemaining = this.checkMessageLength(this.message.length);
    if (this.totalRemaining == 0) {
      const chatRef = this.$refs.chatMessage as any;
      chatRef.focus();
      return;
    }
  }

  /**
   * Check if the string has a URL.
   * @param {string} message - the message to search
   * @return {boolean} - whether the text contains a URL
   */
  checkforURL(message: string): boolean {
    const links = linkify.find(message, 'url');

    if (links.length) {
      // Look through the message and find the first url for link previewing
      return true;
    }
    return false;
  }

  /**
   * Set state of local mention box to true
   */
  mentionBoxOpen() {
    this.mentionOpen = true;
  }

  /**
   * Set state of local mention box to false
   */
  mentionBoxClose() {
    this.mentionOpen = false;
  }

  /**
   * Capturing vue-mention event to add the active mention to the message for sending
   * @param {string} participantUuid - the event coming from Mentionable with uuid
   */
  addMention(participantUuid: string) {
    const mentions = this.mentionedUuids;
    if (!mentions.includes(participantUuid)) {
      this.mentionedUuids.push(participantUuid);
    }

    this.message += ' ';

    const chatRef = this.$refs.chatMessage as any;

    setTimeout(() => {
      chatRef.selectionStart = chatRef.selectionEnd = chatRef.value.length;
      chatRef.focus();
    }, 0);
    this.mentionBoxClose();
  }

  /**
   * Fires when component is created
   */
  created() {
    this.picker = new EmojiButton({
      autoHide: false,
      zIndex: 9000,
      position: 'top-end',
      emojiSize: '1.5em',
    });

    this.picker.on('emoji', (selection) => {
      // `selection` object has an `emoji` property
      // containing the selected emoji
      this.message += selection.emoji;
    });
  }

  /**
   * @param {any} event - the change event from the input
   */
  attachFile(event: any) {
    messageStore.uploadFile(event.target.files[0])
        .catch((err) => {
          if (err.response.status === 413) {
            this.$toast.error('The file you tried to upload was too large. Files must be less than 50mb.');
          } else if (err.response.status === 422) {
            this.$toast.error(`Something went wrong. Please contact support with error code 'Gravity'`);
          } else {
            this.$toast.error('Something went wrong while uploading your file. Please try again.');
          }
        });
    const fileRef = this.$refs.file as any;
    fileRef.value = '';
  }

  /**
   *  Method to open image modal.
   */
  openModal() {
    messageStore.setShowImageModal(true);
  }

  /**
   * Check the mentioned uuids array to verify the person still is taggable.
   * If the participant is still in the call, we're good to check the message.
   */
  checkForMentions() {
    this.mentionedUuids.forEach((mention) => {
      const participantToCheck = this.mentionListRoster.find((part) => part.participant_uuid === mention);
      this.checkMessage(participantToCheck);
    });
  }

  /**
   * Check the message to see if the participant is still mentioned
   * If they are, we're good
   * If not, we remove the mention uuid from the array.
   * @param {any} participant
   */
  checkMessage(participant: any) {
    if (!this.message.includes('@'+participant.value)) {
      messageStore.removeMentionUuid(participant.participant_uuid);
    }
  }

  /**
   * Fires when a chat message is sent, sends the messaged to pexip
   */
  async sendChatMessage() {
    if (!this.sending && !this.mentionOpen && !this.fileUploading) {
      this.sending = true;
      if (this.mentionedUuids.length) {
        this.checkForMentions();
      }
      if (this.fileAttached || this.message.trim().length) {
        await messageStore.sendMessage(this.message)
            .then(() => {
              this.message = '';
            })
            .catch((err) => {
              if (err.response.status === 422) {
                this.$toast.error(`Something went wrong. Please contact support with error code 'Codex'`);
              } else {
                this.$toast.error('Something went wrong trying to send your message. If this persists please contact support.');
              }
            });
      }

      this.sending = false;

      // Refocus on the chat box to allow for more messages
      const chatRef = this.$refs.chatMessage as any;
      chatRef.blur();
      // Focus after delay to prevent a newline from appearing in the input
      setTimeout(() => {
        chatRef.focus();
      }, 0);
    }
  }

  /**
   * Toggles whether the emoji picker is visible or not
   */
  toggleEmojiPicker() {
    const emojiButton = this.$refs.emojiButton;
    this.picker.togglePicker(emojiButton);
  }

  /**
   * Toggles whether the chat expanded or not
   */
  toggleChat() {
    callStore.setChatExpand(!this.isChatExpanded);
  }

  /**
   * Returns the chat messages stored in the pexip store
   */
  get chatMessages() {
    return messageStore.messages;
  }

  /**
   * Gets the current connection status of the call
   */
  get connectedState() {
    return pexipStore.__GETTERconnectedState;
  }

  /**
   * Get state of sho
   */
  get fileAttached() {
    return messageStore.__GETTERfileUploading || messageStore.__GETTERshowFileUploaded;
  }

  /**
   * Get the state of whether the file is uploading from the message store
   */
  get fileUploading() {
    return messageStore.__GETTERfileUploading;
  }

  /**
   * Returns the image modal state form message store.
   */
  get imageModalVisible() {
    return messageStore.__GETTERshowImageModal;
  }

  /**
   * Return state of expanding the chat as a boolean
   */
  get isChatExpanded() {
    return callStore.__GETTERexpandChat;
  }

  /**
   *
   */
  get isChatConnected() {
    return messageStore.__GETTERisChatConnected;
  }

  /**
   *
   */
  get isChatConnecting() {
    return messageStore.__GETTERisChatConnecting;
  }
  /**
   *
   */
  get isChatError() {
    return messageStore.__GETTERisChatConnectionError;
  }

  /**
   * Get chatParticipants
   */
  get mentionListRoster() {
    return messageStore.__GETTERmentionListRoster;
  }

  /**
   * Get chatParticipants
   */
  get mentionedUuids() {
    return messageStore.__GETTERmentionedUuids;
  }
}
