/* eslint-disable camelcase */
import store from './index';
import axios from 'axios';
import {VuexModule, Module, Mutation, Action} from 'vuex-class-modules';
import {mainStore} from './__STORE_main';

export type TProfile = {
  [key: string]: any
  is_recording_available: boolean
}
/**
 *
 */
@Module
class ExpertStore extends VuexModule {
  callHistory = [];
  // Mutations

  /**
   *
   * @param {any} payload
   */
  @Mutation
  private __setCallHistory(payload: any) {
    this.callHistory = payload;
  }

  // Actions
  /**
   * Get user fields for editting him/her
   * @param {any} data - User id
   * @return {Promise} - api response
   */
  @Action
  async setBusy(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: '/set-busy', data, method: 'POST'})
          .then((resp) => {
            mainStore.setSuccess();
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  /**
   * Get user fields for editting him/her
   * @return {Promise} - api response
   */
  @Action
  async getCalls(): Promise<any> {
    return new Promise((resolve, reject) => {
      mainStore.setLoading();
      axios({url: '/get-calls', method: 'GET'})
          .then((resp) => {
            mainStore.setSuccess();
            this.__setCallHistory(resp.data);
            resolve(resp);
          })
          .catch((err) => {
            mainStore.setError();
            reject(err);
          });
    });
  }

  // Getters

  /**
   *
   */
  get __GETTERcallHistory() {
    return this.callHistory;
  }
}

export const expertStore = new ExpertStore({store, name: 'expertStore'});
