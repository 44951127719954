
import Vue from 'vue';
import Component from 'vue-class-component';
import {PropSync} from 'vue-property-decorator';
import Message from '@/types/Message';
import IconDownload from '@/components/icons/IconDownload.vue';
import IconEdit from '@/components/icons/IconEdit.vue';
import IconSmile from '@/components/icons/IconSmile.vue';
import ChatFile from '@/types/ChatFile';
import ReactMenu from '@/components_v2/generic/call/__COMP_ReactMenu.vue';

// import {pexipStore} from '@/store/__STORE_pexip';
import {messageStore} from '@/store/__STORE_message';

@Component({
  components: {
    IconDownload,
    IconEdit,
    IconSmile,
    ReactMenu,
  },
})
/**
 * Generic component to display a chat message
 */
export default class MessageMenu extends Vue {
  name: 'Message Menu'
  isHovered = false;

  @PropSync('message', {type: Object, required: true}) readonly __PROPmessage: Message

  /**
   * toggle the reaction menu
   */
  toggleReactMenu() {
    if (this.showReactionMenu) {
      messageStore.setSelectedMessageUuid('');
    } else {
      messageStore.setSelectedMessageUuid(this.__PROPmessage.uuid);
    }
  }

  /**
   * Initiate the Image markup
   * @param {ChatFile} chatFile
   */
  startImageMarkup(chatFile: ChatFile) {
    messageStore.setSelectedFile(chatFile);
    messageStore.startImageMarkup();
  }

  /**
   * Download the viewed image
   */
  quickDownload() {
    const link = document.createElement('a') as any;
    const filename = this.__PROPmessage.file.filename === 'blob' ? 'download.png': this.__PROPmessage.file.filename;
    link.setAttribute('download', filename);
    link.href = this.__PROPmessage.file.original_url;
    link.target = '_blank';
    link.click();
    link.remove();
  }

  /**
   *
   */
  get markupActive() {
    return messageStore.__GETTERmarkupActive;
  }

  /**
   * Get the string for the reactionMenu
   */
  get showReactionMenu() {
    return messageStore.__GETTERselectedMessageUuid === this.__PROPmessage.uuid;
  }

  /**
   * Calculates what the file name display should be
   */
  get fileName(): string {
    return this.__PROPmessage.file.filename === 'blob' ? 'Marked Up Image' : this.__PROPmessage.file.filename;
  }

  /**
   *
   */
  get reactionEmojis() {
    return messageStore.__GETTERreactionEmojis;
  }
}
