<template>
  <main>
    <section class="section">
      <div class="container">
        <div class="login-panel">
          <div class="heading text-center">
            <h1>Start hosting<br class="d-none d-lg-block"> online video meetings for free</h1>
            <p>Simply add your details below to get started.</p>
          </div>
          <form
            class="form-horizontal mt-2"
            @submit.prevent="register"
          >
            <div
              class="form-group"
              :class="{ 'form-error' : errors.name }"
            >
              <label class="sr-only">First name</label>
              <input
                ref="name"
                v-model="name"
                type="text"
                maxlength="40"
                class="form-control"
                placeholder="Your first name"
              >
              <div
                v-for="(error, index) in errors.name"
                :key="index"
                class="form-error-message"
              >
                {{ error }}
              </div>
            </div>
            <div
              class="form-group"
              :class="{ 'form-error' : errors.last_name }"
            >
              <label class="sr-only">Last name</label>
              <input
                v-model="last_name"
                type="text"
                maxlength="40"
                class="form-control"
                placeholder="Last Name"
              >
              <div
                v-for="(error, index) in errors.last_name"
                :key="index"
                class="form-error-message"
              >
                {{ error }}
              </div>
            </div>
            <div
              class="form-group"
              :class="{ 'form-error': errors.email }"
            >
              <label class="sr-only">Email address</label>
              <input
                v-model="email"
                type="email"
                maxlength="40"
                class="form-control"
                placeholder="Email address"
              >
              <div
                v-for="(error, index) in errors.email"
                :key="index"
                class="form-error-message"
              >
                {{ error }}
              </div>
            </div>
            <div
              class="form-group"
              :class="{ 'form-error': errors.password }"
            >
              <label class="sr-only">Password</label>
              <input
                v-model="password"
                type="password"
                maxlength="40"
                class="form-control"
                placeholder="Password"
              >
              <div
                v-for="(error, index) in errors.password"
                :key="index"
                class="form-error-message"
              >
                {{ error }}
              </div>
              <div
                v-if="showPasswordStrengthError"
                class="form-error-message"
              >
                Please choose stronger password.
              </div>
            </div>
            <div
              id="password"
              class="form-group"
            >
              <password-strength
                :password="password"
                :user-inputs="[name, last_name, email]"
                @update-password-strength="updatePasswordStrength"
              />
            </div>
            <div
              id="timezone-multiselect"
              class="form-group"
              :class="{ 'form-error' : errors.selectedTimezoneOption }"
            >
              <multiselect
                v-model="selectedTimezoneOption"
                track-by="id"
                label="name"
                placeholder=""
                :options="timezoneOptions"
              >
                <template
                  slot="singleLabel"
                  slot-scope="props"
                >
                  <span class="d-flex">
                    <img
                      :src="`/img/icons/icon-clock.svg`"
                      class="mr-2"
                    >
                    <span class="option__desc"><span class="option__title">{{ props.option.name.replace('_', ' ') }}</span></span>
                  </span>
                </template>
                <template
                  slot="option"
                  slot-scope="props"
                >
                  <span class="option__desc"><span class="option__title">{{ props.option.name.replace('_', ' ') }}</span></span>
                </template>
              </multiselect>
              <div
                v-for="(error, index) in errors.timezone"
                :key="index"
                class="form-error-message"
              >
                {{ error }}
              </div>
            </div>
            <div
              class="form-group"
              :class="{ 'form-error': errors.terms }"
            >
              <div class="custom-checkbox terms registration-checkbox">
                <input
                  id="terms-checkbox"
                  v-model="terms"
                  type="checkbox"
                >
                <label for="terms-checkbox" />
                I agree to the
                <a
                  href="https://simplyvideo.io/terms-of-use"
                  target="_blank"
                >
                  terms and conditions
                </a>
              </div>
              <div
                v-for="(error, index) in errors.terms"
                :key="index"
                class="form-error-message"
              >
                {{ error }}
              </div>
            </div>
            <div class="form-group">
              <button
                type="submit"
                class="btn btn-lg btn-block btn-primary"
              >
                Get started
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
    <DiscountCodeDialog
      v-if="showDiscount"
      @close="closeDiscount"
      @send-code="updateCode"
    />
  </main>
</template>
<script>
import DiscountCodeDialog from '@/components/DiscountCodeDialog.vue';
import PasswordStrength from '@/components/PasswordStrength.vue';
import PasswordStrengthMixin from '@/mixins/PasswordStrengthMixin';
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import {authStore} from '@/store/__STORE_auth';
export default {
  components: {
    DiscountCodeDialog,
    PasswordStrength,
    Multiselect,
  },
  mixins: [
    PasswordStrengthMixin,
  ],
  data() {
    return {
      name: '',
      last_name: '',
      email: '',
      password: '',
      terms: false,
      errors: [],
      timezoneOptions: [],
      selectedTimezoneOption: null,
      discountCode: null,
      showDiscount: false,
      openedDiscount: false,
    };
  },
  created() {
    // axios.get('/timezones').then((response) => {
    //   this.timezoneOptions = response.data.map((timezoneData) => {
    //     const gmt = ' GMT' + (timezoneData.offset >= 0 ? '+' : '') + timezoneData.offset;
    //     return {
    //       id: timezoneData.identifier,
    //       name: timezoneData.identifier + gmt,
    //     };
    //   });
    // });
        axios.get('/timezones').then((response) => {
      this.timezoneOptions = response.data.map((timezoneData) => {
        const gmt = ' GMT' + (timezoneData.offset >= 0 ? '+' : '') + timezoneData.offset;
        return {
          id: timezoneData.identifier,
          name: timezoneData.identifier + gmt,
        };
      });

      // Get the user's browser time zone
      let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (userTimezone.toString() === 'Asia/Calcutta') {
        userTimezone = userTimezone.replace('Calcutta', 'Kolkata');
      }

      // Find the user's time zone in the API time zones
      const userTimeZoneOption = this.timezoneOptions.find((option) => option.id === userTimezone);

      // Set the selectedTimezoneOption based on whether a match was found
      this.selectedTimezoneOption = userTimeZoneOption || null;
    });
  },
  mounted() {
    window.setTimeout(() => {
      this.$refs.name.focus();
    }, 400);
    // eslint-disable-next-line
    // const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // this.selectedTimezoneOption = {
    //   id: userTimezone,
    //   name: userTimezone,
    // };
  },
  methods: {
    closeDiscount() {
      this.showDiscount = false;
      this.register();
    },
    updateCode(code) {
      this.discountCode = code;
      this.showDiscount = false;
      this.register();
    },
    register() {
      if (this.name && this.last_name && this.email && this.selectedTimezoneOption && this.password && !this.sufficientPassword) {
        this.errors = [];
        this.showPasswordStrengthError = true;
        return;
      }

      this.showPasswordStrengthError = false;

      if (this.email.match(/@simplyvideo\.io/g) && !this.openedDiscount) {
        // show popup to get a discount code from user
        this.showDiscount = true;
        this.openedDiscount = true;
        return;
      }

      const data = {
        name: this.name,
        last_name: this.last_name,
        email: this.email,
        password: this.password,
        password_confirmation: this.password,
        terms: this.terms ? 1 : 0,
        currency_id: 1,
        timezone: this.selectedTimezoneOption && this.selectedTimezoneOption.id,
        discount_code: this.discountCode,
      };
      authStore.register(data)
          .then(() => {
            this.$toast.success('Registered successfully!');
            this.$router.push({
              name: 'need-to-verify',
              params: {
                email: this.email,
              },
            });
          })
          .catch((err) => {
            this.discountCode = null;
            this.openedDiscount = false;
            if (err.response.status === 418) {
              this.$toast.error(`I'm a teapot!`);
            }
            if (err.response.data.errors) {
              this.errors = err.response.data.errors;
            } else {
              this.errors = [];
            }
          });
    },
  },
};
</script>
