<template>
  <div class="action-cover">
    <div
      v-if="(callType === 'video') && allowed && selectedCameraDeviceId"
      v-tooltip="enabled ? 'Disable camera' : 'Enable camera'"
      :class="{'circular': circular, 'meeting-action bg-white': !circular, 'hide': !enabled}"
      @click="$emit('toggle')"
    >
      <img
        v-show="enabled"
        src="@/assets/img/icons/ic_camera.svg"
      >
      <img
        v-show="!enabled"
        src="@/assets/img/icons/ic_camera_mute.svg"
      >
    </div>

    <div
      v-else
      :class="{'circular disabled': circular, 'meeting-action bg-white disabled': !circular, 'enabled': enabled}"
      @click="$emit('open-settings')"
    >
      <img src="@/assets/img/icons/ic_camera_mute.svg">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    callType: {
      type: String,
      default: null,
    },
    selectedCameraDeviceId: {
      type: [String, Boolean],
      default: null,
    },
    allowed: {
      type: Boolean,
      required: true,
    },
    enabled: {
      type: Boolean,
      required: true,
    },
    circular: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/__meeting_action";

  .circular {
    background: url('../../../assets/img/icons/ic_camera_white.svg') center center no-repeat transparent;
    background-size: 20px auto;
    border-radius: 50%;
    border: 1px solid white;
    height: 40px;
    width: 40px;

    &.hide,
    &.disabled {
      background-color: rgba(0,0,0,0.5);
      background-image: url('../../../assets/img/icons/ic_camera_mute_white.svg');
    }

    &.hide {
      border-color: var(--primary-color);
    }

    img {
      display: none;
    }
  }
</style>
