
import Vue from 'vue';
import Component from 'vue-class-component';
import IconDownload from '@/components/icons/IconDownload.vue';
import IconEdit from '@/components/icons/IconEdit.vue';
import ChatFile from '@/types/ChatFile';

import {messageStore} from '@/store/__STORE_message';

@Component({
  components: {
    IconDownload,
    IconEdit,
  },
})
/**
 * Intelligent component to select a screenshot for the annotation flow
 */
export default class ImageModal extends Vue {
  name: 'Image Modal';

  /**
   * Close the viewer for the selected image
   */
  closeModal() {
    messageStore.setShowImageModal(false);
    messageStore.setSelectedFile(null);
  }

  /**
   * Initiate the Image markup
   */
  startImageMarkup() {
    messageStore.startImageMarkup();
  }

  /**
   * Download the viewed image
   */
  downloadImage() {
    const link = document.createElement('a') as any;
    link.download = this.selectedFile.filename === 'blob' ? 'download.png': this.selectedFile.filename;
    link.href = this.selectedFile.original_url;
    link.target = '_blank';
    link.click();
    link.remove();
  }

  /**
   * Getter for the selected file image from the local state
   */
  get selectedFile(): ChatFile {
    return messageStore.__GETTERselectedFile;
  }

  /**
   * Calculates what the file name display should be
   */
  get fileName(): string {
    return this.selectedFile.filename === 'blob' ? 'Marked Up Image' : this.selectedFile.filename;
  }
}
