
import Component from 'vue-class-component';
import {PropSync} from 'vue-property-decorator';
import Vue from 'vue';

import {callStore} from '@/store/__STORE_call';
import Logo from '@/components_v2/intelligent/branding/__COMP_Logo.vue';

@Component({
  components: {
    Logo,
  },
})
/**
 * Component to replace the meeting sidebar logo img
 */
export default class SidebarLogo extends Vue {
  name: 'Sidebar Logo'

  @PropSync('alias', {type: String, default: null}) readonly __PROPalias: string

  private logoLoaded = false

  /**
   * Runs when the component is mounted
   */
  async mounted() {
    if (!this.__PROPalias) {
      this.$toast.error('No Alias Provided');
      return;
    }
    await callStore.getVMR(this.__PROPalias);
    this.logoLoaded = true;
  }

  /**
   * Gets the logo to display
   */
  get logo() {
    if (callStore.vmr.brand.logo) {
      return callStore.vmr.brand.logo;
    }
    return '/img/sv-primary-logo-col.png';
  }
}
