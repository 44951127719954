<template>
  <svg
    id="Capa_1"
    enable-background="new 0 0 508.543 508.543"
    :height="height"
    viewBox="0 0 508.543 508.543"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
  ><g><path
    :fill="fillColor"
    d="m476.771 320h-79.042l-28.875-65h-1.207c-4.287-7.312-9.153-14.281-14.603-20.821-19.968-23.96-46.28-40.995-75.486-49.515 23.201-19.132 38.016-48.086 38.016-80.437 0-57.471-46.756-104.227-104.227-104.227s-104.228 46.756-104.228 104.228c0 32.167 14.65 60.973 37.624 80.107-29.344 8.32-55.851 25.218-75.994 49.073l-.434.514c-23.566 27.906-36.544 63.392-36.544 99.917v84.051h235.054l-19.917 69.128 21.526 21.526 83.337-45.457 83.337 45.457 21.526-21.526-24.113-83.69 44.25-52.878zm-339.652-215.772c0-40.929 33.298-74.228 74.228-74.228 40.929 0 74.228 33.299 74.228 74.228s-33.299 74.228-74.228 74.228c-40.93-.001-74.228-33.3-74.228-74.228zm-75.348 229.61c0-29.45 10.464-58.061 29.467-80.564l.434-.514c23.776-28.156 58.549-44.305 95.401-44.305h47c37.167 0 72.131 16.376 95.925 44.929 1.748 2.097 3.409 4.258 5.011 6.458l-28.883 60.158h-79.354v30.448l31.331 37.44h-196.332zm337.506 62.467 20.006 69.434-67.512-36.824-67.512 36.824 20.006-69.434-38.75-46.305h59.486l27.647-57.585 25.581 57.585h59.799z"
  /></g></svg>
</template>

<script>
import Props from './propsMixin';
export default {
  mixins: [Props],
};
</script>
