
import Vue from 'vue';
import Component from 'vue-class-component';

import {messageStore} from '@/store/__STORE_message';

@Component
/**
 * Intelligent component for controlling the settings area for participants
 * Allows for disconnecting and muting all participants as well as interacting
 * with the room lock
 */
export default class ReactMenu extends Vue {
  name: 'React Menu'

  /**
   * @param {string} unicode
   */
  sendReact(unicode: string) {
    const reaction = {
      emoji: unicode,
      messageUuid: this.reactToMessage,
    };
    messageStore.handleReaction(reaction);
    messageStore.setSelectedMessageUuid('');
  }

  /**
   *
   */
  get reactionEmojis() {
    return messageStore.__GETTERreactionEmojis;
  }

  /**
   * Get the message id from the store, which is passed into the store by clicking on that message menu
   */
  get reactToMessage() {
    return messageStore.__GETTERselectedMessageUuid;
  }
}
