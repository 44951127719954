export default {
  data() {
    return {
      speakerVolume: 0, // Speaker activity level
      speakerCheckInterval: null,
    };
  },
  methods: {
    monitorSpeakerVolume() {
      try {
        const AudioContext = window.AudioContext || 
                    window.webkitAudioContext || 
                    false;

        if (AudioContext) {
          const audioContext = new AudioContext();
          const analyser = audioContext.createAnalyser();

          analyser.fftSize = 1024;
          const bufferLength = analyser.frequencyBinCount;
          const dataArray = new Uint8Array(bufferLength);

          const destination = audioContext.createMediaStreamDestination();
          const oscillator = audioContext.createOscillator();
          oscillator.connect(destination);
          oscillator.start();

          const mediaStream = destination.stream;
          const mediaStreamSource = audioContext.createMediaStreamSource(mediaStream);
          mediaStreamSource.connect(analyser);

          this.speakerVolumeCheckInterval = window.setInterval(() => {
            analyser.getByteFrequencyData(dataArray);
            let values = 0;

            const length = dataArray.length;
            for (let i = 0; i < length; i++) {
              values += dataArray[i];
            }

            let volume = Math.round(values / length);
            volume = volume <= 100 ? volume : 100;

            this.speakerVolume = volume;
          }, 50);
        }
      } catch (e) {
        console.error('Error monitoring speaker volume:', e);
      }
    },
  },
  // created() {
  //   this.monitorSpeakerActivity();
  // },
  // beforeDestroy() {
  //   clearInterval(this.speakerCheckInterval);
  // },
};
