<template>
  <div
    id="modal-calendar-integration"
    class="f-modal"
  >
    <div class="f-modal-table">
      <div class="f-modal-cell align-middle">
        <div class="f-modal-content">
          <div
            class="f-modal-close"
            @click="$emit('close')"
          />
          <div class="modal-popup">
            <div class="modal-popup-content">
              <confirm-dialog-icon />

              <h6 class="mb-2">
                Enter discount code
              </h6>

              <div>
                Please enter the discount code below
              </div>

              <div
                class="form-group mt-1 mb-2"
              >
                <input
                  id="password-confirm"
                  v-model="code"
                  class="form-control"
                  type="password"
                  placeholder="Discount code..."
                >
              </div>
            </div>

            <div class="text-center">
              <a
                href="#"
                class="btn btn-outline"
                @click.prevent="$emit('close')"
              >
                Cancel
              </a>
              <a
                href="#"
                class="btn btn-primary ml-2"
                @click.prevent="$emit('send-code', code)"
              >
                Confirm
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConfirmDialogIcon from './ConfirmDialogIcon';

export default {
  components: {
    ConfirmDialogIcon,
  },
  data() {
    return {
      code: '',
    };
  },
  created() {
    // eslint-disable-next-line
        $('body').addClass('f-modal-is-open');
  },
  destroyed() {
    // eslint-disable-next-line
        $('body').removeClass('f-modal-is-open');
  },
};
</script>
