/* eslint-disable camelcase */
import axios from 'axios';
axios.defaults.headers = {
  ...axios.defaults.headers,
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};
import store from './';
import {VuexModule, Module, Mutation, Action} from 'vuex-class-modules';
import {callStore} from './__STORE_call';
import {authStore} from './__STORE_auth';
import {userStore} from './__STORE_user';

type TParticipant = {
  uuid: string;
  api_url: string;
  buzz_time: number;
  call_direction: string;
  call_tag: string;
  disconnect_supported: 'YES' | 'NO';
  display_name: string;
  encryption: string;
  external_node_uuid: string;
  fecc_supported: 'YES' | 'NO';
  has_media: boolean;
  is_audio_only_call: 'NO' | 'YES';
  is_external: boolean;
  is_muted: 'NO' | 'YES';
  is_presenting: 'NO' | 'YES';
  is_streaming_conference: boolean;
  is_video_call: 'YES' | 'NO';
  local_alias: string;
  mute_supported: 'YES' | 'NO';
  overlay_text: string;
  presentation_supported: 'NO' | 'YES';
  protocol: 'api' | 'webrtc' | 'sip' | 'rtmp' | 'h323' | 'mssip';
};
/**
 * Behold, the eighth wonder of the world
 */
@Module
class PexipStore extends VuexModule {
  private MAX_LIVE_STREAMS = 1;
  // State
  isLoggedIn = authStore.__GETTERisUserLoggedIn;
  pexipClient = null;
  streams = {
    selfStream: null,
    videoStream: null,
    topVideo: 'video',
  };
  devices = {
    selectedMicrophoneDeviceId: '' as any,
    selectedSpeakerDeviceId: '' as any,
    selectedCameraDeviceId: '' as any,
    isCameraAllowedByBrowser: false,
    isMicrophoneAllowedByBrowser: false,
    hasNoCamera: false,
  };
  call = {
    type: '',
    displayName: '',
    myUuid: '',
    conferenceId: '' as any,
    isHost: false,
    pin: '',
    previousPin: '',
    lastPinTried: false,
    layout: '1:0',
    pendingLayoutChange: '',
    maximumBandwidthKbps: 1000,
    isAlwaysLocked: true,
    freePlan: true,
    meetingDuration: '' as any,
    remainDuration: '' as any,
    remainTime: '' as any,
    chatMessages: [] as any[],
    participants: [] as TParticipant[],
    selectedParticipantUuid: '' as any,
    isLocked: false,
    inLobby: true,
    isScreenStream: false,
    muteStatus: false,
    isShowParticipantsSettings: false,
    participantTimes: [] as any[],
    isShowSidebar: true,
    isRoomOwner: false,
  };
  connection = {
    initialConnection: true,
    isConnecting: false,
    isReconnecting: false,
    connected: false,
    retryConnecting: false,
    isTesting: true,
    connectionAttempts: 0,
    isTestingCompleted: false,
  };
  supportedMedia = {
    isIncomingAudioSupportedByCallType: false,
    isOutgoingAudioSupportedByCallType: false,
    isIncomingVideoSupportedByCallType: false,
    isOutgoingVideoSupportedByCallType: false,
  };
  intervals = {
    updateMeetingDurationInterval: '' as any,
    updateRemainDurationInterval: '' as any,
    updateParticipantTimesInterval: '' as any,
  };
  actions = {
    microphone: {
      allowed: false,
      enabled: false,
      mutedRemotely: false,
    },

    camera: {
      allowed: false,
      enabled: false,
    },
    liveStream: {
      active: false,
      showEndStreamAck: false,
      uuid: '',
    },
    screenShare: {
      /**
       * Feedback on wether the screenShare has been invoked
       */
      enabled: false,
      /**
       * Feedback on wether the user recieved a presentation stream from the far-end `true`.
       */
      incoming: false,
      stream: null,
    },

    annotation: {
      allowed: false,
      enabled: false,
      screenShare: false,
      showScreenshot: false,
      showCanvas: false,
      url: '',
      participantUuid: '',
      preLayout: '',
    },

    recording: {
      availableMessage: '',
      allowed: false,
      enabled: false,
      locked: false,
      participantName: 'rec.simplyvideo.net',
    },
  };

  redirectBack = false;
  redirectAfterCall = false;
  redirectBackError = '' as string;
  resetIdle = false;
  isShowSettings = false;
  isKeyPad = false;
  showKeypad = true;
  showPinInput = false;
  currentDate = new Date();
  hasCalledConferenceStatus= false;
  previousParticipantJson: any = null;
  previousParticipantStatus = false;


  // ************************************************************

  // Mutations

  /**
   * Mutation to set whether the user is the room owner or not
   * @param {boolean} owner - whether the user is the room owner or not
   */
  @Mutation
  private __setIsRoomOwner(owner: boolean) {
    this.call.isRoomOwner = owner;
  }

  /**
   * Mutation to set the uuid of the participant that is being annotated
   * @param {string} uuid - the uuid of the participant that is being annotated
   */
  @Mutation
  private __setAnnotationParticipantUuid(uuid: string) {
    this.actions.annotation.participantUuid = uuid;
  }

  /**
   * Mutation to set the layout set before the annotation starts
   * @param {string} layout - the previous layout to save
   */
  @Mutation
  private __setPreAnnotationLayout(layout: string) {
    this.actions.annotation.preLayout = layout;
  }

  /**
   * Mutation to set the image url for annotation
   * @param {string} url - the url to set the state to
   */
  @Mutation
  private __setAnnotationUrl(url: string) {
    this.actions.annotation.url = url;
  }

  /**
   * Mutation to set the state of the show screenshot boolean for annotation feature
   * @param {boolean} state - the value to set the state to
   */
  @Mutation
  private __setShowScreenshotState(state: boolean) {
    this.actions.annotation.showScreenshot = state;
  }

  /**
   * Mutation to set the state of the show canvas variable
   * @param {boolean} state - boolean value to set the state to
   */
  @Mutation
  private __setShowCanvas(state: boolean) {
    this.actions.annotation.showCanvas = state;
  }

  /**
   * Mutation to set the pexip client instance
   * @param {any} pexip - the pexip instance
   */
  @Mutation
  private __setPexipClient(pexip: any) {
    this.pexipClient = pexip;
  }

  /**
   * Mutation to clear the redirct back error state
   */
  @Mutation
  private __clearRedirect() {
    this.redirectBackError = '';
    this.redirectBack = false;
    this.redirectAfterCall = false;
  }

  /**
   * Update specific participant data
   * @param {any} dataArray - updated participant data {index, data}
   */
  @Mutation
  private __updateParticipant(dataArray: any) {
    this.call.participants[dataArray.index] = dataArray.data;
  }

  /**
   * Set the testing state of the call
   * @param {boolean} value - testing state boolean value
   */
  @Mutation
  private __setTestingState(value: boolean) {
    this.connection.isTesting = value;
  }

  /**
   * Set the selected camera and microphone device IDs for the user
   * @param {
   * {
   *  selectedCameraDeviceId: string,
   *  selectedSpeakerDeviceId: string,
   *  selectedMicrophoneDeviceId: string,
   * }
   * } data - selected ID data to set in the state
   */
  @Mutation
  private __setCamAndMicIds(data: {
    selectedCameraDeviceId: string;
    selectedMicrophoneDeviceId: string;
    selectedSpeakerDeviceId: string;
  }) {
    this.devices.selectedCameraDeviceId = data.selectedCameraDeviceId;
    this.devices.selectedMicrophoneDeviceId = data.selectedMicrophoneDeviceId;
    this.devices.selectedSpeakerDeviceId = data.selectedSpeakerDeviceId;
  }

  /**
   * Set whether the camera and microphone have been allowed by the browser
   * @param {
   * {
   *  isCameraAllowedByBrowser: boolean,
   *  isMicrophoneAllowedByBrowser: boolean,
   * }
   * } data - state of whether camera and microphone have been allowed by the browser
   */
  @Mutation
  private __setAllowedCamAndMic(data: {
    isCameraAllowedByBrowser: boolean;
    isMicrophoneAllowedByBrowser: boolean;
  }) {
    this.devices.isMicrophoneAllowedByBrowser =
      data.isMicrophoneAllowedByBrowser;
    this.devices.isCameraAllowedByBrowser = data.isCameraAllowedByBrowser;
  }

  /**
   * Set whether the user has recording available
   * @param {
   * {
   *  isRecordingAvailable: boolean,
   *  isRecordingAvailableMessage: string,
   * }
   * } data - State of what is allowed for the user in regards to recordings
   */
  @Mutation
  private __setRecordingAvailable(data: {
    isRecordingAvailable: boolean;
    isRecordingAvailableMessage: string;
  }) {
    this.actions.recording.allowed = data.isRecordingAvailable;
    this.actions.recording.availableMessage = data.isRecordingAvailableMessage;
  }

  /**
   * Set the max allowed bandwidth for the call for given user
   * @param {number} max - maximum allowed bandwidth figure
   */
  @Mutation
  private __setMaxBandwidth(max: number) {
    this.call.maximumBandwidthKbps = max;
  }

  /**
   * Set the call type string in state
   * @param {any} type - string of the call type
   */
  @Mutation
  private __setCallType(type: any) {
    this.call.type = type;
  }

  /**
   * Mutation to set the isConnecting state
   * @param {boolean} value - boolean state of the isConnection
   */
  @Mutation
  private __setConnectingState(value: boolean) {
    this.connection.isConnecting = value;
  }

  /**
   * Mutation to set the display name state
   * @param {string} name - string name of the connecting participant
   */
  @Mutation
  private __setDisplayName(name: string) {
    this.call.displayName = name;
  }

  /**
   * Increment the number of connection attempts
   */
  @Mutation
  private __incrementConnectionAttempts() {
    this.connection.connectionAttempts++;
  }

  /**
   * Set the incoming and outgoing media states
   */
  @Mutation
  private __setIncomingAndOutgoingMedia() {
    if (this.call.type === 'video') {
      this.supportedMedia.isOutgoingAudioSupportedByCallType = true;
      this.supportedMedia.isIncomingAudioSupportedByCallType = true;
      this.supportedMedia.isOutgoingVideoSupportedByCallType = true;
      this.supportedMedia.isIncomingVideoSupportedByCallType = true;
    }

    if (this.call.type === 'audioonly') {
      this.supportedMedia.isOutgoingAudioSupportedByCallType = true;
      this.supportedMedia.isIncomingAudioSupportedByCallType = true;
      this.supportedMedia.isOutgoingVideoSupportedByCallType = false;
      this.supportedMedia.isIncomingVideoSupportedByCallType = false;
    }

    if (!this.devices.isCameraAllowedByBrowser || this.devices.hasNoCamera) {
      this.supportedMedia.isOutgoingVideoSupportedByCallType = false;
    }
  }

  /**
   *
   * @param {boolean} value - boolean value to set the reconnecting state to
   */
  @Mutation
  private __setReconnectingState(value: boolean) {
    this.connection.isReconnecting = value;
  }

  /**
   * Clear the state of participants on reconnect
   */
  @Mutation
  private __clearParticipants() {
    this.call.participants = [] as any;
  }

  /**
   *
   * @param {boolean} value - boolean value to set state to
   */
  @Mutation
  private __setShowSettingsState(value: boolean) {
    this.isShowSettings = value;
  }

  /**
   * Sets whether the camera and microphone actions are enabled for the user
   * @param {any} data - data to set for action statuses
   */
  @Mutation
  private __setActionStatuses(data: any) {
    const mAllowed = data.isMicrophoneAllowedByBrowser;
    const mEnabled = data.isMicrophoneAllowedByUser;

    this.actions.microphone = {
      allowed: mAllowed,
      enabled: mEnabled,
      mutedRemotely: false,
    };

    const mMute = mAllowed ? !mEnabled : true;
    this.pexipClient.muteAudio(mMute);

    const cAllowed = data.isCameraAllowedByBrowser;
    const cEnabled = data.isCameraAllowedByUser;

    this.actions.camera = {
      allowed: cAllowed,
      enabled: cEnabled,
    };

    let cMute = cAllowed ? !cEnabled : true;

    if (this.devices.hasNoCamera) {
      cMute = true;
    }

    this.pexipClient.muteVideo(cMute);
  }

  /**
   *
   * @param {boolean} value - bool value to set state to
   */
  @Mutation
  private __setCameraActionState(value: boolean) {
    this.actions.camera.enabled = value;
  }

  /**
   *
   * @param {boolean} value - bool value to set state to
   */
  @Mutation
  private __setMicrophoneActionState(value: boolean) {
    this.actions.microphone.enabled = value;
  }

  /**
   *
   * @param {any} stream - self stream to set
   */
  @Mutation
  private __setSelfStream(stream: any) {
    this.streams.selfStream = stream;
  }

  /**
   * Apply new layout string and clear pending string
   * @param {string} newLayout - new layout type string
   */
  @Mutation
  private __applyLayoutChange(newLayout: string) {
    this.call.layout = newLayout;
    this.call.pendingLayoutChange = '' as any;
  }

  /**
   *
   * @param {string} layout - layout that is currently to be set in state
   */
  @Mutation
  private __setLayout(layout: string) {
    this.call.layout = layout;
  }

  /**
   *
   * @param {boolean} value - boolean value to set state to
   */
  @Mutation
  private __setConnectedState(value: boolean) {
    this.connection.connected = value;
  }

  /**
   *
   * @param {string} value - remain duration time string
   */
  @Mutation
  private __setRemainDuration(value: string) {
    this.call.remainDuration = value;
  }

  /**
   * Decrement the remaining time value in state
   */
  @Mutation
  private __decrementRemainTime() {
    this.call.remainTime--;
  }

  /**
   *
   * @param {boolean} value - bool value to set state to
   */
  @Mutation
  private __setFreePlanState(value: boolean) {
    this.call.freePlan = value;
  }

  /**
   *
   * @param {any} value - remain time value to set
   */
  @Mutation
  private __setRemainTime(value: any) {
    this.call.remainTime = value;
  }

  /**
   *
   * @param {any} value - conference id value to set
   */
  @Mutation
  private __setConferenceId(value: any) {
    this.call.conferenceId = value;
  }

  /**
   *
   * @param {boolean} value - bool value to assign to state
   */
  @Mutation
  private __setResetIdleState(value: boolean) {
    this.resetIdle = value;
  }

  /**
   *
   * @param {string} value - meeting duration string
   */
  @Mutation
  private __setMeetingDuration(value: string) {
    this.call.meetingDuration = value;
  }

  /**
   * Redirects to after call page when user disconnects
   */
  @Mutation
  private __redirectToAfterCallPage() {
    this.redirectAfterCall = true;
  }

  /**
   * Redirects back to previous route with message
   * @param {string} msg - string of error or message to return with
   */
  @Mutation
  private __redirectToPrev(msg: string) {
    this.redirectBack = true;
    this.redirectBackError = msg;
  }

  /**
   *
   * @param {any} value - pin vlaue to set
   */
  @Mutation
  private __setPin(value: any) {
    this.call.pin = value;
  }

  /**
   *
   * @param {boolean} value - bool value to set state to
   */
  @Mutation
  private __setShowPinInput(value: boolean) {
    this.showPinInput = value;
  }

  /**
   *
   * @param {boolean} value - bool value to set state to
   */
  @Mutation
  private __setTestingCompleteState(value: boolean) {
    this.connection.isTestingCompleted = value;
  }

  /**
   *
   * @param {boolean} value - bool value to set state to
   */
  @Mutation
  private __setLastPinTriedState(value: boolean) {
    this.call.lastPinTried = value;
  }

  /**
   *
   * @param {
   * {
   *  name: string,
   *  message: any,
   * }
   * } message - message object
   */
  @Mutation
  private __appendChatMessage(message: { name: string; message: any }) {
    const date = new Date();
    const hours = date.getHours();
    const mins = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    this.call.chatMessages.push({
      id: date.getTime(),
      time: `${hours}:${mins}`,
      name: message.name,
      message: message.message,
    });
  }

  /**
   *
   * @param {any} participants - participants list from pexip
   */
  @Mutation
  private __setParticipantsState(participants: any) {
    this.call.participants = participants;
  }

  /**
   * Removes the deleted participant from state list
   * @param {number} index - index of the participant to delete
   */
  @Mutation
  private __deleteParticipant(index: number) {
    this.call.participants.splice(index, 1);
  }

  /**
   *
   * @param {string} uuid - uuid of the participant selected
   */
  @Mutation
  private __setSelectedParticipantUuid(uuid: string) {
    this.call.selectedParticipantUuid = uuid;
  }

  /**
   *
   * @param {boolean} value - bool value to set state to
   */
  @Mutation
  private __setIsHost(value: boolean) {
    this.call.isHost = value;
  }

  /**
   *
   * @param {boolean} value - bool value to set state to
   */
  @Mutation
  private __setIsMutedRemotely(value: boolean) {
    this.actions.microphone.mutedRemotely = value;
  }

  /**
   *
   * @param {any} participant - new participant data to be pushed to array
   */
  @Mutation
  private __pushNewParticipant(participant: any) {
    this.call.participants.push(participant);
  }

  /**
   *
   * @param {boolean} value - boolean value to set state to
   */
  @Mutation
  private __setRecordingLockState(value: boolean) {
    this.actions.recording.locked = value;
  }

  /**
   *
   * @param {boolean} value - bool value to set state to
   */
  @Mutation
  private __setIsLockedState(value: boolean) {
    this.call.isLocked = value;
  }

  /**
   *
   * @param {boolean} value - boolean value to set state to
   */
  @Mutation
  private __setIsKeypadState(value: boolean) {
    this.isKeyPad = value;
  }

  /**
   *
   * @param {boolean} value - boolean value to set state to
   */
  @Mutation
  private __setInLobbyState(value: boolean) {
    this.call.inLobby = value;
  }

  /**
   *
   * @param {boolean} value - bool value to set state to
   */
  @Mutation
  private __setInitialConnectionState(value: boolean) {
    this.connection.initialConnection = value;
  }

  /**
   *
   * @param {string} value - uuid value to assign
   */
  @Mutation
  private __setMyUuid(value: string) {
    this.call.myUuid = value;
  }

  /**
   *
   * @param {any} stream - stream to assign
   */
  @Mutation
  private __setVideoStream(stream: any) {
    this.streams.videoStream = stream;
  }

  /**
   *
   * @param {boolean} value - boolean state to set the state value to
   */
  @Mutation
  private __setIsAlwaysLocked(value: boolean) {
    this.call.isAlwaysLocked = value;
  }

  /**
   *
   * @param {string} pin - pin string
   */
  @Mutation
  private __setPreviousPin(pin: string) {
    this.call.previousPin = pin;
  }

  /**
   *
   * @param {boolean} value - boolean state value to set
   */
  @Mutation
  private __setAllowedAnnotation(value: boolean) {
    this.actions.annotation.allowed = value;
  }

  /**
   *
   * @param {boolean} value - boolean value to set state to
   */
  @Mutation
  private __setIsRecordingState(value: boolean) {
    this.actions.recording.enabled = value;
  }

  /**
   *
   * @param {boolean} enabled - whether the microphone of hte user is enabled or not
   */
  @Mutation
  private __setMicEnabledState(enabled: boolean) {
    this.actions.microphone.enabled = enabled;
  }

  /**
   *
   */
  @Mutation
  private __toggleVideo() {
    this.actions.camera.enabled = !this.actions.camera.enabled;
  }

  /**
   *
   */
  @Mutation
  private __toggleAnnotation() {
    this.actions.annotation.enabled = !this.actions.annotation.enabled;
  }

  /**
   *
   * @param {boolean} state - boolean state to set state value to
   */
  @Mutation
  private __setAnnotationScreenshareState(state: boolean) {
    this.actions.annotation.screenShare = state;
  }

  /**
   *
   * @param {boolean} state - annotation state value to set to
   */
  @Mutation
  private __setAnnotationState(state: boolean) {
    this.actions.annotation.enabled = state;
  }

  /**
   *
   * @param {string} layout - layout string to set
   */
  @Mutation
  private __setPendingLayout(layout: string) {
    this.call.pendingLayoutChange = layout;
  }

  /**
   *
   * @param {boolean} state - boolean to set
   */
  @Mutation
  private __setIsShowParticipantsSettings(state: boolean) {
    this.call.isShowParticipantsSettings = state;
  }

  /**
   * Set the state of the participant times to new values
   * @param {any} participantTimes - array of updated participant times
   */
  @Mutation
  private __setParticipantTimesState(participantTimes: any) {
    this.call.participantTimes = participantTimes;
  }

  /**
   * Mutation to set the state of the mute status value
   * @param {boolean} state - boolean state to set the state value to
   */
  @Mutation
  private __setMuteStatus(state: boolean) {
    this.call.muteStatus = state;
  }

  /**
   * Mutation that sets the state of isShowSidebar
   * @param {boolean} state - boolean state to set
   */
  @Mutation
  private __setIsShowSidebar(state: boolean) {
    this.call.isShowSidebar = state;
  }

  /**
   * Sets the state of tthe showkeypad variable
   * @param {boolean} state - boolean state to set
   */
  @Mutation
  private __setShowKeypad(state: boolean) {
    this.showKeypad = state;
  }

  /**
   * Mutation to set the video that is on top (the one the user is currently seeing)
   * @param {string} stream - the video string that is currently on top 'video' | 'screen'
   */
  @Mutation
  private __setTopVideo(stream: string) {
    this.streams.topVideo = stream;
  }

  /**
   * Set the live stream status
   *
   * @param {{ active: boolean, uuid: string}} args
   * @memberof PexipStore
   */
  @Mutation
  set_live_stream(args: { active: boolean, uuid: string}) {
    this.actions.liveStream.active = args.active;
    this.actions.liveStream.uuid = args.uuid;
  }

  /**
   * Set the live stream acknowledgement state
   * @param {boolean} state
   */
  @Mutation
  private __setLiveStreamAck(state: boolean) {
    this.actions.liveStream.showEndStreamAck = state;
  }

  /**
   *
   * @param {any} previousParticipantJson - previousParticipantJson to set
   */
     @Mutation
  private __setPreviousParticipantJson(previousParticipantJson: any) {
    this.previousParticipantJson = previousParticipantJson;
  }

     /**
   * Set the live stream acknowledgement state
   * @param {boolean} state
   */
    @Mutation
     private __setPreviousParticipantStatus(state: any) {
       this.previousParticipantStatus = state;
     }

    /**
   * Set the live stream acknowledgement state
   * @param {boolean} state
   */
    @Mutation
    private __setHasCalledConferenceStatus(state: any) {
      this.hasCalledConferenceStatus = state;
    }
    // **********************************************************

    // Actions
  /**
   * Get the state
   * @param {boolean} state
   */
  @Action
    async setLiveStreamAck(state: boolean) {
      this.__setLiveStreamAck(state);
    }

  /**
   * Action to set whether the user is the room owner
   * @param {boolean} owner - whether the user is the room owner
   */
  @Action
  async setIsRoomOwner(owner: boolean) {
    this.__setIsRoomOwner(owner);
  }

  /**
   * Action that closes the sidebar in call
   */
  @Action
  async closeSidebar() {
    this.__setIsShowSidebar(false);
  }

  /**
   * Action that opens the sidebar in call
   */
  @Action
  async openSidebar() {
    this.__setIsShowSidebar(true);
  }

  /**
   * Action stops the recording
   */
  @Action
  async stopRecording() {
    const p = pexipStore.call.participants.find(
        (p) => p.display_name === this.actions.recording.participantName,
    );
    if (p) {
      this.pexipClient.disconnectParticipant(p.uuid);
    }
    this.__setIsRecordingState(false);
    this.__setRecordingLockState(false);
  }

  /**
   * Action sets recording state
   * @param {boolean} value
   */
  @Action
  async setIsRecordingState(value: boolean) {
    this.__setIsRecordingState(value);
  }

  /**
   * Action mutes all participants in a conference
   */
  @Action
  async muteAllParticipants() {
    this.__setMuteStatus(!this.call.muteStatus);
    this.call.participants.forEach((participant: any) => {
      // Don't mute ourself
      if (participant.uuid === this.call.myUuid) {
        return;
      }

      this.pexipClient.setParticipantMute(
          participant.uuid,
          this.call.muteStatus,
      );
    });
  }

  /**
   * Action to set the update remain duration interval
   */
  @Action
  private async __setUpdateRemainDurationInterval() {
    this.intervals.updateRemainDurationInterval = setInterval(() => {
      this.updateRemainDuration();
    }, 1000);
  }

  /**
   * Action to setup the time interval for updating the participant times array
   */
  @Action
  private async __setParticipantTimesInterval() {
    this.intervals.updateParticipantTimesInterval = setInterval(() => {
      this.updateParticipantTimes();
    }, 500);
  }

  /**
   * Action to set the participant times update interval
   */
  @Action
  async setParticipantTimesInterval() {
    this.__setParticipantTimesInterval();
  }

  /**
   * Action that calls the mutator to set the participant times state
   * @param {any} times - participant times array to set to state
   */
  @Action
  async setParticipantTimes(times: any) {
    this.__setParticipantTimesState(times);
  }

  /**
   * Set whether to show or hide the participants setting window
   * @param {boolean} state - state of the participants settings
   */
  @Action
  async setIsShowParticipantsSettings(state: boolean) {
    this.__setIsShowParticipantsSettings(state);
  }

  /**
   * Set whether to show or hide the sidebar
   * @param {boolean} state - state of the sidebar
   */
  @Action
  async setIsShowSidebar(state: boolean) {
    this.__setIsShowSidebar(state);
  }

  /**
   * Set the display name of the connecting participant
   * @param {string} name - string of the name of the connecting participant
   */
  @Action
  async setDisplayName(name: string) {
    this.__setDisplayName(name);
  }
  /**
   * Action to clear the redirect back error state
   */
  @Action
  async clearRedirect() {
    this.__clearRedirect();
  }

  /**
   *
   * @param {string} pin - pin string to set
   */
  @Action
  async setPin(pin: string) {
    this.__setPin(pin);
  }

  /**
   *
   * @param {boolean} state - boolean state to set the always locked state to
   */
  @Action
  async setIsAlwaysLocked(state: boolean) {
    this.__setIsAlwaysLocked(state);
  }

  /**
   *
   * @param {string} pin - pin string
   */
  @Action
  async setPreviousPin(pin: string) {
    this.__setPreviousPin(pin);
  }

  /**
   *
   * @param {boolean} allowed - boolean value on whether annotation is allowed
   */
  @Action
  async setAllowedAnnotation(allowed: boolean) {
    this.__setAllowedAnnotation(allowed);
  }

  /**
   *
   * @param {boolean} value - boolean value to set state to
   */
  @Action
  async setRecordingLockState(value: boolean) {
    this.__setRecordingLockState(value);
  }

  /**
   *
   * @param {string} type - call type string to set
   */
  @Action
  async setCallType(type: string) {
    this.__setCallType(type);
  }

  /**
   *
   * @param {boolean} state - boolean state value to set
   */
  @Action
  async setIsTestingComplete(state: boolean) {
    this.__setTestingCompleteState(state);
  }

  /**
   *
   */
  @Action
  async closeBrowser() {
    if (this.pexipClient) {
      this.pexipClient.disconnect();
    }
    if (this.intervals.updateParticipantTimesInterval) {
      window.clearInterval(this.intervals.updateParticipantTimesInterval);
    }
  }

  /**
   *
   * @param {boolean} muted - whether or not the user is now muted
   */
  @Action
  async toggleAudio(muted: boolean) {
    this.__setMicEnabledState(!muted);
    this.pexipClient.muteAudio(muted);
  }

  /**
   *
   */
  @Action
  async toggleVideo() {
    this.pexipClient.muteVideo(this.actions.camera.enabled);
    this.__toggleVideo();
  }

  /**
   *
   */
  @Action
  async toggleScreenShare() {
    if (this.actions.screenShare.incoming) {
      throw new Error(
          'Can\'t Start Presentation Because Someone Else In The Meeting Is Currently Presenting Their Screen',
      );
    }
    if (this.actions.screenShare.enabled) {
      this.pexipClient.present(''); // Tell pexip to stop screen sharing
    } else {
      this.pexipClient.present('screen'); // Tell pexip to start scren sharing
    }
  }

  /**
   * Action to specifically stop the screenshare
   */
  @Action
  async stopScreenshare() {
    if (
      !this.actions.screenShare.enabled &&
      !this.actions.screenShare.incoming
    ) {
      return;
    }
    this.pexipClient.present('');
  }

  /**
   *
   */
  @Action
  async toggleAnnotation() {
    this.__toggleAnnotation();
  }

  /**
   * Action that is called to clear up after annotation has finished
   */
  @Action
  async resetAfterAnnotation() {
    this.__setAnnotationScreenshareState(false);
    await this.pexipClient.transformLayout({
      layout: this.actions.annotation.preLayout,
    });
    this.__applyLayoutChange(this.actions.annotation.preLayout);
    await this.pexipClient.setParticipantSpotlight(this.actions.annotation.participantUuid, false);
    this.__setAnnotationUrl('');
    this.__setPreAnnotationLayout('');
    this.__setAnnotationParticipantUuid('');
    this.__setIsShowSidebar(true);
  }

  /**
   *
   */
  @Action
  private async __setUpdateMeetingDurationInterval() {
    this.intervals.updateMeetingDurationInterval = setInterval(() => {
      this.updateMeetingDuration();
    }, 500);
  }

  /**
   *
   * @param {any} participant - the participant being annotated
   */
  @Action
  async startAnnotation(participant: any) {
    this.__setPreAnnotationLayout(this.call.layout);
    this.__setAnnotationParticipantUuid(participant.uuid);
    await this.removeAllSpotlights();
    this.pexipClient.setParticipantSpotlight(participant.uuid, true);
    this.pexipClient.transformLayout({
      layout: '1:0',
    });
    this.__applyLayoutChange('1:0');
    this.__setIsShowSidebar(false);
    this.__setShowScreenshotState(true);
  }

  /**
   * Action that is called after the screenshot is selected to set state and carry on
   * the flow
   * @param {string} url - the string url of the captured image to annotate
   */
  @Action
  async setAnnotationUrl(url: string) {
    this.__setAnnotationUrl(url);
    this.__setShowScreenshotState(false);
    this.__setAnnotationState(true);
    this.__setShowCanvas(true);
    this.toggleScreenShare();
  }

  /**
   * Action to stop annotation when the user is finished
   */
  @Action
  async stopAnnotation() {
    this.stopScreenshare();
    this.__setShowCanvas(false);
    this.resetAfterAnnotation();
    this.__setAnnotationState(false);
  }

  /**
   * Action to remove spotlight from all participants
   */
  @Action
  async removeAllSpotlights() {
    this.call.participants.forEach((element: any) => {
      this.pexipClient.setParticipantSpotlight(element.uuid, false);
    });
  }

  /**
   * Action to cancel the annotation flow part way through before it reaches screenshare
   */
  @Action
  async cancelAnnotation() {
    this.__setAnnotationState(false);
    this.__setShowCanvas(false);
    this.__setShowScreenshotState(false);
    this.resetAfterAnnotation();
  }

  /**
   *
   * @param {string} layout - layout to set
   */
  @Action
  async setPendingLayout(layout: string) {
    this.__setPendingLayout(layout);
  }

  /**
   *
   * @param {TParticipant} participant - participant object
   */
  @Action
  async disconnectParticipant(participant: TParticipant) {
    this.pexipClient.disconnectParticipant(participant.uuid);
    if (participant.display_name === this.actions.recording.participantName) {
      this.__setIsRecordingState(false);
      this.__setRecordingLockState(false);
    }
  }

  /**
   *
   * @param {any} participant - participant chosen object
   */
  @Action
  async unlockParticipant(participant: any) {
    this.pexipClient.unlockParticipant(participant.uuid);
    if (participant.display_name === this.actions.recording.participantName) {
      this.__setRecordingLockState(false);
    }
  }

  /**
   *
   * @param {any} participant - participant object
   */
  @Action
  async toggleParticipantMute(participant: any) {
    const muted = !(participant.is_muted === 'YES');
    this.pexipClient.setParticipantMute(participant.uuid, muted);
  }

  /**
   *
   * @param {any} participant - participant object
   */
  @Action
  async toggleParticipantSpotlight(participant: any) {
    const spotlighted = !(participant.spotlight > 0);
    this.pexipClient.setParticipantSpotlight(participant.uuid, spotlighted);
  }

  /**
   *
   * @param {any} participant - participant object
   */
  @Action
  async setParticipantToHost(participant: any) {
    this.pexipClient.setRole(participant.uuid, 'chair');
  }

  /**
   *
   * @param {any} participant - participant object
   */
  @Action
  async setParticipantToGuest(participant: any) {
    this.pexipClient.setRole(participant.uuid, 'guest');
  }

  /**
   *
   * @param {any} participant - participant object
   */
  @Action
  async selectParticipant(participant: any) {
    if (participant.uuid === this.call.selectedParticipantUuid) {
      // Deselect
      this.__setSelectedParticipantUuid('');
    } else {
      this.__setSelectedParticipantUuid(participant.uuid);
    }
  }

  /**
   *
   */
  @Action
  async toggleMeetingLock() {
    this.pexipClient.setConferenceLock(!this.call.isLocked);
  }

  /**
   *
   * @param {
   * {
   *  digit: any,
   *  uuid: string,
   * }
   * } params - object containing required info: digit and uuid to send to
   */
  @Action
  async sendDTMF(params: { digit: any; uuid: string }) {
    this.pexipClient.sendDTMF(params.digit, params.uuid);
  }

  /**
   * Action to initialise the pexip client
   * @param {any} pexipClient - pexip client to set
   */
  @Action
  async initialisePexip(pexipClient: any) {
    console.log('pexipClient', pexipClient);
    this.__setPexipClient(pexipClient);

    this.pexipClient.onSetup = this.onSetup;
    this.pexipClient.onConnect = this.onConnect;
    this.pexipClient.onDisconnect = this.onDisconnect;
    this.pexipClient.onLayoutUpdate = this.onLayoutUpdate;
    this.pexipClient.onConferenceUpdate = this.onConferenceUpdate;
    this.pexipClient.onParticipantCreate = this.onParticipantCreate;
    this.pexipClient.onParticipantUpdate = this.onParticipantUpdate;
    this.pexipClient.onParticipantDelete = this.onParticipantDelete;
    this.pexipClient.onRosterList = this.onRosterList;
    this.pexipClient.onChatMessage = this.onChatMessage;
    this.pexipClient.onError = this.onError;

    this.pexipClient.onScreenshareConnected = this.onScreenshareConnected; // local
    this.pexipClient.onScreenshareStopped = this.onScreenshareStopped; // local
    this.pexipClient.onPresentation = this.onPresentation; // far-end
    this.pexipClient.onPresentationConnected = this.onPresentationConnected; // far-end
    this.pexipClient.onPresentationDisconnected = this.onPresentationDisconnected; // far-end

    const videoDeviceId =
      this.devices.selectedCameraDeviceId &&
      this.supportedMedia.isOutgoingVideoSupportedByCallType ?
        this.devices.selectedCameraDeviceId :
        false;
    const audioDeviceId = this.devices.selectedMicrophoneDeviceId ?? false;
    const speakerDeviceId = this.devices.selectedSpeakerDeviceId ?? false;

    const tempStream = await this.createStreamFromIds(
        videoDeviceId,
        audioDeviceId,
        speakerDeviceId,
    );
    this.__setSelfStream(tempStream);
    this.pexipClient.user_media_stream = tempStream;
  }

  /**
   * start live streaming the currently active conference
   *
   * @param {{name: string, url: string, key: string, ack: boolean}} a
   * @memberof PexipStore
   */
  @Action
  async startLiveStream(a: {
    name: string;
    url: string;
    key: string;
    ack: boolean;
  }) {
    if (!a.ack) throw new Error(`Acknowledgement not given.`);
    // check url is valid
    let url = a.url;
    if (!new RegExp(/rtmp(|s):\/\//).test(url)) throw new Error('URL Is Invalid');
    // check if the url has a slash at the end
    // if it doesn't, add it to the end of the url
    url = url.endsWith('/') ? url : url + '/';

    url = url + a.key;

    return this.dialOut({
      role: 'guest',
      destination: url,
      protocol: 'rtmp',
      params: {
        remote_display_name: a.name,
        streaming: true,
        keep_conference_alive: 'keep_conference_alive_never',
      },
    }).then((p) => {
      // set it as active
      this.set_live_stream({active: true, uuid: p[0]});
    });
  }

  /**
   * Stop the live stream
   *
   * @memberof PexipStore
   */
  @Action
  async stopLiveStream() {
    const p = this.findParticipant(this.actions.liveStream.uuid);
    if (!p) throw new Error(`Participant Not Found`);
    this.__setLiveStreamAck(true);
    return this.disconnectParticipant(p);
  }

  // ********************************************************

  // Pexip Methods
  /**
   * Pexip library call back function
   * @param {any} stream - unused
   * @param {string} pinStatus - status of whether a pin is required
   * @param {any} conferenceExtension - Unused
   */
  onSetup(stream: any, pinStatus: string, conferenceExtension: any) {
    const pin = this.call.pin || '';

    if (pinStatus === 'required' && pin.length === 0) {
      this.__redirectToPrev('Please enter a PIN');
    } else {
      this.pexipClient.connect(pin);
    }
  }

  /**
   * Pexip library callback function
   * @param {any} stream - the incoming video stream from the node
   */
  onConnect(stream: any) {
    if (!this.hasCalledConferenceStatus && (this.pexipClient && this.pexipClient.current_service_type === 'conference' || this.pexipClient && this.pexipClient.current_service_type === 'waiting_room')) {
      this.__setHasCalledConferenceStatus(true);
      this.conferenceParticipantStatus('conference_started', null, null);
    }
    this.__setConnectingState(false);
    this.__setReconnectingState(false);
    this.__setConnectedState(true);
    this.__setMyUuid(this.pexipClient.uuid);
    this.__setIsHost(this.pexipClient.role === 'HOST');

    this.__setVideoStream(stream);

    this.__setUpdateMeetingDurationInterval();

    this.getRemainTime();
    this.__setResetIdleState(true);
    this.__setResetIdleState(false);
    this.applyPendingLayoutChange();
    // Re-send audio/visual streams to ensure PEXIP receives them correctly
    if (this.connection.initialConnection) {
      this.renegotiate({
        callType: this.call.type,
        maximumBandwidthKbps: this.call.maximumBandwidthKbps,
        microphoneDeviceId: this.devices.selectedMicrophoneDeviceId,
        speakerDeviceId: this.devices.selectedSpeakerDeviceId,
        cameraDeviceId: this.devices.selectedCameraDeviceId,
      });
      this.__setInitialConnectionState(false);
    }

    this.pexipClient.setConferenceLock(this.call.isAlwaysLocked);
  }

  /**
   *
   *
   * @param {boolean} setting - if the presentation has started or stopped
   * @param {string} presenter
   * @param {string} uuid
   * @notes Will fire on each participant join. Listen for `setting` prop.
   * @memberof PexipStore
   */
  onPresentation(setting: boolean, presenter: string, uuid: string) {
    if (setting) {
      this.pexipClient.getPresentation();
    }
  }

  // eslint-disable-next-line valid-jsdoc
  /**
   *
   *
   * @private
   * @param {{
   *     stream: MediaStream,
   *     remote?: boolean
   *   }} a
   * @memberof PexipStore
   */
  @Mutation
  private __setPresentationFeed(a: { stream: MediaStream; remote?: boolean }) {
    this.actions.screenShare.stream = a.stream;
    this.actions.screenShare.incoming = a.remote;
    this.actions.screenShare.enabled = true;

    if (this.actions.annotation.enabled) {
      this.actions.annotation.screenShare = true;
    }
  }

  /**
   *
   *
   * @private
   * @memberof PexipStore
   */
  @Mutation
  private __unsetPresentationFeed() {
    this.streams.topVideo = 'video';
    this.actions.screenShare.incoming = false;
    this.actions.screenShare.enabled = false;
    this.actions.screenShare.stream = null;
  }

  /**
   * Mutation that sets the state of whether user is screensharing
   * @param {boolean} state - boolean state to set
   */
  @Mutation
  private __setScreenshareEnabledState(state: boolean) {
    this.actions.screenShare.enabled = state;
  }

  /**
   * Fires when a fer-end particiapnt started presenting their screen
   *
   * @param {MediaStream} stream
   * @memberof PexipStore
   */
  @Action
  async onPresentationConnected(stream: MediaStream) {
    this.__setPresentationFeed({stream, remote: true});
  }

  /**
   * Fires when a remote participant stops their stream
   *
   * @param {string} reason
   * @memberof PexipStore
   */
  @Action
  async onPresentationDisconnected(reason: string) {
    this.__unsetPresentationFeed();
  }

  /**
   * Fires when the local user started presneting their screen
   *
   * @param {MediaStream} stream
   * @memberof PexipStore
   */
  @Action
  async onScreenshareConnected(stream: MediaStream) {
    this.__setPresentationFeed({stream});
  }

  /**
   * Fires when the local user stops their screenshare
   *
   * @param {string} reason
   * @memberof PexipStore
   */
  @Action
  async onScreenshareStopped(reason: string) {
    this.__setScreenshareEnabledState(false);
    if (this.actions.annotation.enabled) {
      this.stopAnnotation();
    }
    if (
      reason === 'Screenshare cancelled' &&
      this.actions.screenShare.incoming
    ) {
      this.pexipClient.getPresentation();
      return;
    }
    this.__unsetPresentationFeed();
  }

  /**
   *
   *
   * @param {{rtmp: string}} a
   * @memberof PexipStore
   */
  @Action
  async startRecording(a: { rtmp: string; name: string }) {
    await this.dialOut({destination: a.rtmp, protocol: 'rtmp', role: 'guest'}).then(() => {
      this.__setIsRecordingState(true);
    });
  }

  /**
   *
   *
   * @param {string} myUuid
   * @memberof PexipStore
   */
  @Action
  async setParticipant(myUuid: string) {
    await axios({url: `set-participant`, data: {myUuid}, method: 'POST'});
  }

  /**
   * Dial out to a thing
   *
   * @param {*} p
   * @memberof PexipStore
   */
  @Action
  async dialOut(p: {
    destination: string,
    protocol: 'sip' | 'h323' | 'rtmp' | 'mssip' | 'auto',
    role: 'guest' | 'chair',
    params?: {
      presentation_uri?: string,
      streaming?: boolean,
      dtmf_sequence?: string,
      keep_conference_alive?: 'keep_conference_alive' | 'keep_conference_alive_if_multiple' | 'keep_conference_alive_never',
      overlay_text?: string,
      remote_display_name?: string
    }
  }) {
    return new Promise<string[]>((resolve, reject) => {
      /**
       * A callback to call when the dial-out request has been processed.
       * This will return an object containing "result", which is an array of uuids of the new participant if dial-out was successfully initiated.
       *
       * @param {*} a
       */
      function cb(a: any) {
        if (!a.result.length) reject();
        else {
          resolve(a.result as string[]);
        }
      }

      if (p.params && p.params.remote_display_name && this.findParticipantByName(p.params.remote_display_name)) throw new Error('Participant Already Exsists With This Name');

      this.pexipClient.dialOut(p.destination, p.protocol, p.role, cb, p.params);
    });
  }

  /**
   * Pexip call back function when disconnected
   * @param {string} msg - reason for disconnect from pexip
   */
  onDisconnect(msg: string) {
    this.conferenceParticipantStatus('conference_end', null, null);
    const callRejected = msg.indexOf('Call rejected') !== -1;
    const gatewayFailed = msg.indexOf('Gateway dial out failed') !== -1;
    const disconnectedByAnotherParticipant =
      msg.indexOf('Conference terminated by another participant') !== -1;

    if (disconnectedByAnotherParticipant && this.call.remainTime <= 1) {
      this.__redirectToAfterCallPage();
      return;
    }

    if (
      (callRejected || gatewayFailed) &&
      this.connection.retryConnecting &&
      this.connection.connectionAttempts <= 15
    ) {
      setTimeout(this.makeCall, 4000);
      this.__incrementConnectionAttempts();
      return;
    }

    this.__setConnectingState(false);
    if (this.isLoggedIn) {
      if (callRejected) {
        this.__redirectToPrev(
            'Room not found. If you recently signed up or changed your room alias please wait a couple of minutes and try again',
        );
      }
      this.__redirectToPrev(msg);
    } else {
      this.__redirectToAfterCallPage();
    }
  }

  /**
   * Pexip call back function when layout is changed
   * @param {any} data - layout update information
   */
  onLayoutUpdate(data: any) {
    // If there is only 1 participant, this hook always gives you "1:0" layout, even if that is not
    // the layout selected by Host.
    // Once another participant joins, this hook suddenly gives you the correct layout selected by Host.
    // So we should only update the layout if there is more than one person online:
    const videoEnabledParticipants = this.call.participants.filter(
        (participant: any) => {
          return participant.is_video_call === 'YES';
        },
    );

    if (videoEnabledParticipants.length > 1) {
      this.__setLayout(data.view);
    }
  }

  /**
   * Pexip call back function when conference is updated
   * @param {any} properties - new conference properties
   */
  onConferenceUpdate(properties: any) {
    this.__setIsLockedState(properties.locked);
    this.__setIsKeypadState(false);
    if (properties.started == false) {
      this.__setInLobbyState(true);
    } else {
      this.__setInLobbyState(false);
    }
  }

  /**
   * Pexip call back function when a participant joins the call
   * @param {any} participant - participant data
   */
  onParticipantCreate(participant: any) {
    if (localStorage.getItem('guestDisplayNameTwo') === participant.display_name) {
      this.conferenceParticipantStatus('participant_connected', participant, null);
    }
    this.__pushNewParticipant(participant);
    if (
      !this.call.isLocked &&
      participant.display_name === this.actions.recording.participantName
    ) {
      this.__setRecordingLockState(false);
    }
    const stream = this.call.participants.find((p) => p.protocol && p.protocol === 'rtmp' && p.display_name !== this.actions.recording.participantName);
    if (stream && this.call.isRoomOwner) {
      const args = {active: true, uuid: stream.uuid};
      this.set_live_stream(args);
    }
    if (!this.call.isLocked && participant.display_name === this.actions.recording.participantName) {
      this.__setRecordingLockState(false);
    }
    const rec = this.call.participants.find((p) => p.display_name === this.actions.recording.participantName);
    if (rec && this.call.isRoomOwner) {
      this.__setIsRecordingState(true);
    }
  }

  /**
   *
   *
   * @param {string} s
   * @memberof PexipStore
   */
  @Mutation
  private __setRecordingParticipantName(s: string) {
    this.actions.recording.participantName = s;
  }

  /**
   * Pexip call back function that is called when a participant is updated
   * @param {any} updatedParticipant - updated participant data
   */
  onParticipantUpdate(updatedParticipant: any) {
    if (!this.previousParticipantStatus && (!this.previousParticipantJson || JSON.stringify(this.previousParticipantJson) !== JSON.stringify(updatedParticipant))) {
      // If there's no previous JSON or the updated JSON is different from the previous one
      this.__setPreviousParticipantStatus(true);
      this.__setPreviousParticipantJson({...updatedParticipant}); // Update the previous JSON with the current one

      // Call the API
      this.conferenceParticipantStatus('participant_updated', updatedParticipant, null);
    }
    const index = this.call.participants.findIndex((participant: any) => {
      return participant.uuid === updatedParticipant.uuid;
    });

    if (index === -1) {
      this.onParticipantCreate(updatedParticipant);
      return;
    }

    this.__updateParticipant({index: index, data: updatedParticipant});

    if (updatedParticipant.uuid === this.call.myUuid) {
      this.__setIsHost(updatedParticipant.role === 'chair');
      this.__setIsMutedRemotely(updatedParticipant.is_muted === 'YES');
      if (this.call.selectedParticipantUuid != '' && !this.call.isHost) {
        this.__setSelectedParticipantUuid('');
      }
    }
  }

  /**
   * Pexip call back function, fires when a participant leaves the conference
   * @param {any} deletedParticipant - deleted participant data
   */
  onParticipantDelete(deletedParticipant: TParticipant) {
    const deletedParticipantData = this.call.participants.filter((participant) => {
      return participant.uuid == deletedParticipant.uuid;
    });
    this.conferenceParticipantStatus('participant_disconnected', deletedParticipantData[0], deletedParticipant);
    const index = this.call.participants.findIndex((participant) => {
      return participant.uuid === deletedParticipant.uuid;
    });

    if (deletedParticipant.uuid === this.actions.liveStream.uuid) this.set_live_stream({active: false, uuid: ''});

    this.__deleteParticipant(index);
  }

  /**
   * Pexip call back function for joining the call, gives all current participant data
   * @param {any} participants - all current participants in the conference
   */
  onRosterList(participants: any) {
    const stream = participants.find((p) => p.protocol && p.protocol === 'rtmp' && p.display_name !== this.actions.recording.participantName);
    if (stream && this.call.isRoomOwner) {
      const args = {active: true, uuid: stream.uuid};
      this.set_live_stream(args);
    }
    const rec = participants.find((p) => p.display_name === this.actions.recording.participantName);
    if (this.call.isRoomOwner && rec) {
      this.__setIsRecordingState(true);
    }
    this.__setParticipantsState(participants);
  }

  /**
   * Pexip call back function when a message is sent in the system
   * @param {any} message - chat message data
   */
  onChatMessage(message: any) {
    this.__appendChatMessage({
      name: message.origin,
      message: message.type === 'text/plain' ? message.payload : message.type,
    });
  }

  /**
   * Method to send a message to the pexip system
   * @param {string} messageText - text of the message to send
   */
  @Action
  async sendChatMessage(messageText: string) {
    this.pexipClient.sendChatMessage(messageText);
    this.__appendChatMessage({
      name: this.call.displayName,
      message: messageText,
    });
  }

  /**
   * Pexip call back function for when an error is thrown
   * @param {string} error - error string from pexip
   */
  onError(error: string) {
    // Instant meeting rooms are not available immediately after they're
    // created. It can take up to 30s to reach them. So we will retry this operation
    // a few times
    const notFound =
      error.indexOf('Neither conference nor gateway found') !== -1;
    const gatewayFailed = error.indexOf('Gateway dial out failed') !== -1;

    if (
      (notFound || gatewayFailed) &&
      this.connection.retryConnecting &&
      this.connection.connectionAttempts <= 15
    ) {
      setTimeout(this.makeCall, 4000);
      this.__incrementConnectionAttempts();
      return;
    }

    this.__setConnectingState(false);
    if (error.indexOf('Invalid PIN') !== -1) {
      // Sometimes it takes Pexip up to 60 seconds to update the PIN.
      // If user updates the PIN and immediately tries to enter their VMR, they might run into "incorrect pin" error.
      // So, if main PIN fails, we will silently try to use the last one.
      if (this.call.previousPin && !this.call.lastPinTried) {
        this.__setLastPinTriedState(true);
        this.continueConnectWithPin(this.call.previousPin);
        return;
      }

      this.__redirectToPrev('Invalid PIN');
    } else {
      this.__redirectToPrev(error);
    }
  }

  /**
   * Method to connect to a conference with a pin
   * @param {any} pin - the pin to conenct with
   */
  continueConnectWithPin(pin: any) {
    this.__setPin(pin);
    this.__setShowPinInput(false);
    this.__setTestingCompleteState(true);
    this.makeCall();
  }

  // ************************************************************

  // Standard Helper Methods
  /**
   * Updates the length of the meeting
   */
  updateMeetingDuration() {
    // @TODO there's a bug - if oldest participant disconnects, then time is updated...

    if (this.call.freePlan && this.call.remainTime <= 1) {
      return;
    }

    let oldestParticipantStartTime = 0;

    this.call.participants.forEach((participant: any) => {
      if (
        !oldestParticipantStartTime ||
        participant.start_time < oldestParticipantStartTime
      ) {
        oldestParticipantStartTime = participant.start_time;
      }
    });

    if (oldestParticipantStartTime) {
      const now = Math.round(new Date().getTime() / 1000);
      const seconds = Math.round(now - oldestParticipantStartTime);

      const fullMinutes = Math.floor(seconds / 60);
      const remainderSeconds = Math.round(seconds % 60);
      const formatted =
        fullMinutes + ':' + remainderSeconds.toString().padStart(2, '0');

      this.__setMeetingDuration(formatted);
    }
  }

  /**
   * Gets the remaining time for the VMR from the API
   */
  getRemainTime() {
    const url = `/time-limit?alias=${callStore.vmr.webrtc.alias}`;
    axios({url: url, method: 'GET'})
        .then((response) => {
          if (response.data.is_active) {
            this.__setConferenceId(response.data.conference_id);
            if (response.data.seconds_left === null) {
              this.__setFreePlanState(false);
              this.__setRemainTime('');
            } else {
              this.__setFreePlanState(true);
              this.__setRemainTime(response.data.seconds_left);

              if (this.intervals.updateRemainDurationInterval) {
                return;
              }

              this.__setUpdateRemainDurationInterval();
            }
          } else {
            setTimeout(this.getRemainTime, 5000);
          }
        })
        .catch((err) => {});
  }
  /**
 * Gets the conference and participant status.
 *
 * @param {string} status - The status of the conference or participant.
 * @param {any} conferenceParticipantData - Data related to the conference or participant.
 * @param {any} deleteParticipantId - The ID of the participant to be deleted.
 */
  conferenceParticipantStatus(status: string, conferenceParticipantData: any, deleteParticipantId: any) {
    switch (status) {
      case 'participant_connected':
        this.participantApi(status, conferenceParticipantData, null);
        break;
      case 'participant_disconnected':
        this.participantApi(status, conferenceParticipantData, deleteParticipantId);
        break;
      case 'participant_updated':
        this.participantApi(status, conferenceParticipantData, null);
        break;
      case 'conference_started':
        this.conferenceApi(status);
        break;
      case 'conference_ended':
        this.conferenceApi(status);
        break;
    }
  }
  /**
   * Gets the conference API call
   * @param {string} status - The status of the conference Api.
   */
  conferenceApi(status) {
    const request_data = {
      data: {
        guests_muted: false,
        is_locked: status === 'conference_started' ? false : true,
        is_started: status === 'conference_started' ? false : true,
        name: this.pexipClient.display_name,
        service_type: 'conference',
        start_time: Math.floor(this.currentDate.getTime() / 1000),
        tag: 'LockedByDefault',
      },
      event: status,
      node: this.pexipClient.node,
      seq: 5589,
      time: Math.floor(this.currentDate.getTime() / 1000),
      version: 1,
    };
    if (status === 'conference_started') {
      axios({
        url: '/pexip-webhooks-started',
        baseURL: (window as any).CONFIG.VUE_APP_CHAT_URL || process.env.VUE_APP_CHAT_URL,
        // baseURL: 'https://chat.svxr.io',
        data: request_data, // Use request_data as payload
        method: 'POST',
      });
    }
    if (status === 'conference_ended') {
      axios({
        url: '/pexip-webhooks-ended',
        baseURL: (window as any).CONFIG.VUE_APP_CHAT_URL || process.env.VUE_APP_CHAT_URL,
        // baseURL: 'https://chat.svxr.io',
        data: request_data, // Use request_data as payload
        method: 'POST',
      });
    }
  }
  /**
 * Gets the participant API call
 * @param {string} status - The status of the participant.
 * @param {Object} participantData - Data related to the participant.
 * @param {string} deleteParticipantId - The ID of the participant to be deleted.
 */
  participantApi(status, participantData, deleteParticipantId) {
    console.log('participantData', participantData.service_type);
    const request_data = {
      data: {
        call_direction: participantData.call_direction,
        // call_id: participantData.uuid,
        // call_tag: participantData.call_tag,
        conference: participantData.local_alias,
        connect_time: participantData.start_time,
        // conversation_id: participantData.uuid,
        destination_alias: participantData.local_alias,
        display_name: participantData.display_name,
        // encryption: participantData.encryption,
        // has_media: participantData.has_media,
        // is_muted: participantData.is_muted,
        // is_presenting: participantData.is_presenting,
        // is_streaming: participantData.is_streaming_conference,
        // ivr_video_complete: false,
        // media_node: '10.0.10.126',
        protocol: participantData.protocol,
        // proxy_node: '10.0.10.201',
        // related_uuids: [participantData.uuid, participantData.uuid],
        // remote_address: '171.76.83.210',
        role: participantData.role,
        // rx_bandwidth: 0,
        // service_tag: participantData.service_tag,
        // service_type: (status === 'participant_connected' && participantData.role === 'guest') ? 'waiting_room' : participantData.service_type,
        service_type: participantData.service_type,
        // signalling_node: '10.0.10.201',
        // source_alias: participantData.display_name,
        // system_location: 'sv-uk-conf',
        // tx_bandwidth: 0,
        uuid: deleteParticipantId ? deleteParticipantId.uuid : participantData.uuid,
        vendor: participantData.vendor,
      },
      event: status,
      node: this.pexipClient.node,
      seq: 5589,
      time: participantData.start_time,
      version: 1,
    };
    if (status === 'participant_connected' ) {
      axios({
        url: '/pexip-webhooks-connected',
        baseURL: (window as any).CONFIG.VUE_APP_CHAT_URL || process.env.VUE_APP_CHAT_URL,
        // baseURL: 'https://chat.svxr.io',
        data: request_data, // Use request_data as payload
        method: 'POST',
      });
    }
    if (status === 'participant_updated' ) {
      axios({
        url: '/pexip-webhooks-updated',
        baseURL: (window as any).CONFIG.VUE_APP_CHAT_URL || process.env.VUE_APP_CHAT_URL,
        // baseURL: 'https://chat.svxr.io',
        data: request_data, // Use request_data as payload
        method: 'POST',
      });
    }
    if (status === 'participant_disconnected' ) {
      axios({
        url: '/pexip-webhooks-disconnected',
        baseURL: (window as any).CONFIG.VUE_APP_CHAT_URL || process.env.VUE_APP_CHAT_URL,
        // baseURL: 'https://chat.svxr.io',
        data: request_data, // Use request_data as payload
        method: 'POST',
      });
    }
  }

  /**
   * Updates the remaining call duration
   * Calls for participants to be disconnected if time limit is exceeded
   */
  updateRemainDuration() {
    if (this.call.freePlan && this.call.remainTime) {
      this.__decrementRemainTime();
      if (this.call.remainTime <= 0) {
        this.__setRemainDuration('0:00');
        this.realDisconnectAllParticipants();
        if (this.intervals.updateRemainDurationInterval) {
          clearInterval(this.intervals.updateRemainDurationInterval);
        }
      } else {
        const remainMinutes = Math.floor(this.call.remainTime / 60);
        const remainSeconds = Math.round(this.call.remainTime % 60);
        this.__setRemainDuration(
            remainMinutes + ':' + remainSeconds.toString().padStart(2, '0'),
        );
      }
    }
  }

  /**
   * Updates the participant times array for the time a participant has been connected
   */
  @Action
  async updateParticipantTimes() {
    const updatedParticipantTimes = [];
    this.call.participants.forEach((participant: any) => {
      const now = Math.round(new Date().getTime() / 1000);
      const seconds = Math.round(now - participant.start_time);

      const fullMinutes = Math.floor(seconds / 60);
      const remainderSeconds = Math.round(seconds % 60);
      const formatted =
        fullMinutes + ':' + remainderSeconds.toString().padStart(2, '0');

      updatedParticipantTimes[participant.uuid] = formatted;
    });

    this.setParticipantTimes(updatedParticipantTimes);
  }

  /**
   * Disconnects all the participants
   */
  realDisconnectAllParticipants() {
    const url = '/time-limit/disconnect';
    const param = {
      conference_id: this.call.conferenceId,
    };
    axios({url: url, data: param, method: 'POST'})
        .then((response) => {
          if (this.call.isHost) {
            this.pexipClient.disconnectAll();
          }

          this.realDisconnectMe();
        })
        .catch((err) => {});
  }

  /**
   * Disconnects user from the call
   */
  realDisconnectMe() {
    this.pexipClient.disconnect();
    this.__clearParticipants();
    this.__setConnectedState(false);
    this.__redirectToAfterCallPage();
  }

  /**
   * Will apply the pending layout that you wish to change to
   */
  applyPendingLayoutChange() {
    if (!this.call.pendingLayoutChange) {
      return;
    }

    this.pexipClient.transformLayout({
      layout: this.call.pendingLayoutChange,
    });

    this.__applyLayoutChange(this.call.pendingLayoutChange);
  }

  /**
   * Changes the settings of the call.
   *
   * Reconnects to the call if call type or bandwidth has been changed.
   * @param {
      {
        callType: string,
        maximumBandwidthKbps: number,
        microphoneDeviceId: string,
        cameraDeviceId: string

      }
    } data
    */
  async renegotiate(data: {
    callType: string;
    maximumBandwidthKbps: number;
    microphoneDeviceId: string;
    speakerDeviceId: string;
    cameraDeviceId: string;
  }) {
    this.__setCamAndMicIds({
      selectedMicrophoneDeviceId: data.microphoneDeviceId || '',
      selectedSpeakerDeviceId: data.speakerDeviceId || '',
      selectedCameraDeviceId: data.cameraDeviceId || '',
    });

    // Reconnect if call type changed
    if (this.call.type !== data.callType) {
      data.maximumBandwidthKbps = this.call.maximumBandwidthKbps; // Don't change the bandwidth
      this.reconnect(data);
      return;
    }

    // Change microphone without reconnecting
    if (data.microphoneDeviceId) {
      const tempStream = await this.createStreamFromIds(
          this.devices.selectedCameraDeviceId,
          this.devices.selectedMicrophoneDeviceId,
          this.devices.selectedSpeakerDeviceId,
      );
      this.__setSelfStream(tempStream);
      this.pexipClient.user_media_stream = this.streams.selfStream;

      this.pexipClient.muteAudio(!this.actions.microphone.enabled);
    } else {
      this.pexipClient.muteAudio(true);
      this.__setMicrophoneActionState(false);
    }

    // Change video without reconnecting
    if (this.call.type === 'video') {
      if (data.cameraDeviceId) {
        const tempStream = await this.createStreamFromIds(
            this.devices.selectedCameraDeviceId,
            this.devices.selectedMicrophoneDeviceId,
            this.devices.selectedSpeakerDeviceId,
        );
        this.__setSelfStream(tempStream);
        this.pexipClient.user_media_stream = this.streams.selfStream;
      } else {
        this.pexipClient.muteVideo(true);
        this.__setCameraActionState(false);
      }
    }

    this.__setShowSettingsState(false);
    this.pexipClient.renegotiate();
  }

  /**
   * Creates a MediaStream from device IDs.
   * @param {string} videoDeviceId
   * @param {string} audioDeviceId
   * @param {string} speakerDeviceId
   * @return {MediaStream}
   */
  async createStreamFromIds(
      videoDeviceId: any,
      audioDeviceId: any,
      speakerDeviceId: any,
  ): Promise<MediaStream> {
    const streamParams = {
      audio: {
        deviceId: audioDeviceId,
      },
    } as any;
    if (!this.devices.hasNoCamera && videoDeviceId) {
      streamParams.video = {
        deviceId: videoDeviceId,
      };
    }
    if (speakerDeviceId) {
      streamParams.audio = {
        deviceId: speakerDeviceId,
      };
    }
    return await navigator.mediaDevices.getUserMedia(streamParams);
  }

  /**
   * Reconnects to the call.
   * @param {any} data - New data about the 'callType',
   *   'maximumBandwidthKbps', 'microphoneDeviceId' and 'cameraDeviceId'.
   */
  reconnect(data: any) {
    this.__setReconnectingState(true);
    this.pexipClient.disconnect();
    this.__clearParticipants();

    this.__setCallType(data.callType);
    this.__setMaxBandwidth(data.maximumBandwidthKbps);
    this.makeCall();

    this.__setShowSettingsState(false);

    this.__setActionStatuses({
      isCameraAllowedByBrowser: this.actions.camera.allowed,
      isMicrophoneAllowedByBrowser: this.actions.microphone.allowed,

      isMicrophoneAllowedByUser: !!data.microphoneDeviceId,
      isCameraAllowedByUser:
        this.call.type === 'video' && !!data.cameraDeviceId,
    });
  }

  /**
   * Sets up the call variables and calls on the pexip client to initalise the call
   */
  async makeCall() {
    this.__setTestingState(false);
    this.setIncomingAndOutgoingMedia();
    this.__setConnectingState(true);
    await this.initialisePexip(this.pexipClient);

    this.pexipClient.makeCall(
        callStore.vmr.webrtc.conferencing_node,
        callStore.vmr.webrtc.alias,
        this.call.displayName,
        this.call.maximumBandwidthKbps,
        this.call.type,
    );
  }

  /**
   * Sets what audio and video types are supported given the user's call type
   */
  setIncomingAndOutgoingMedia() {
    this.__setIncomingAndOutgoingMedia();
  }

  /**
   * Runs when the testing of camera and mic have been completed
   * @param {any} data - setting data for camera and mic allowances from testing phase
   */
  async onTestingComplete(data: any) {
    // check feature availabilty
    if (this.isLoggedIn) {
      userStore.getUserProfile().then(() => {
        this.__setRecordingAvailable({
          isRecordingAvailable: userStore.profileData.is_recording_available,
          isRecordingAvailableMessage:
            userStore.profileData.is_recording_available_message,
        });
      });
    }
    this.__setTestingState(false);
    this.__setCamAndMicIds({
      selectedCameraDeviceId: data.selectedCameraDeviceId,
      selectedMicrophoneDeviceId: data.selectedMicrophoneDeviceId,
      selectedSpeakerDeviceId: data.selectedSpeakerDeviceId,

    });
    const tempStream = await this.createStreamFromIds(
        data.selectedCameraDeviceId,
        data.selectedMicrophoneDeviceId,
        data.selectedSpeakerDeviceId,
    );
    this.__setSelfStream(tempStream);
    this.pexipClient.user_media_stream = tempStream;
    this.__setAllowedCamAndMic(
        {
          isMicrophoneAllowedByBrowser: data.isMicrophoneAllowedByBrowser,
          isCameraAllowedByBrowser: data.isCameraAllowedByBrowser,
        },
    );

    this.__setMaxBandwidth(data.maximumBandwidthKbps);
    this.__setCallType(data.callType);

    this.makeCall();
    this.__setActionStatuses(data);
  }

  /**
   * Raise a hand
   */
  raiseHand() {
    this.pexipClient.setBuzz();
  }

  /**
   * Lower a hand
   * @param {string} uuid - user uid
   */
  lowerHand(uuid: string) {
    this.pexipClient.clearBuzz(uuid);
  }

  // ******************************************************************

  // Getters

  /**
   *
   *
   * @readonly
   * @memberof PexipStore
   */
  get __GETTERselectedVideo() {
    if (this.streams.topVideo === 'video') {
      return this.streams.videoStream;
    }
    return this.actions.screenShare.stream;
  }

  /**
   *
   *
   * @readonly
   * @memberof PexipStore
   */
  get __GETTERisRecordingAvailable() {
    return this.actions.recording.allowed;
  }

  /**
   *
   *
   * @readonly
   * @memberof PexipStore
   */
  get __GETTERisRecordingLock() {
    return this.actions.recording.locked;
  }

  /**
   *
   *
   * @readonly
   * @memberof PexipStore
   */
  get __GETTERisRecording() {
    return this.actions.recording.enabled;
  }

  /**
   *
   *
   * @readonly
   * @memberof PexipStore
   */
  get __GETTERisRoomOwner() {
    return this.call.isRoomOwner;
  }

  /**
   *
   */
  get __GETTERisLiveStreamingActive() {
    return this.actions.liveStream.active;
  }

  /**
   *
   */
  get __GETTERshowEndStreamAck() {
    return this.actions.liveStream.showEndStreamAck;
  }

  /**
   *
   */
  get __GETTERconnectedState() {
    return this.connection.connected && !this.call.inLobby;
  }

  /**
   *
   */
  get __GETTERmyUuid() {
    return this.call.myUuid;
  }

  // HELPERS

  /**
   * Find a participant by uuid
   *
   * @param {string} uuid
   * @return {*}  {(void | TParticipant)}
   * @memberof PexipStore
   */
  findParticipant(uuid: string) : void | TParticipant {
    return this.call.participants.find((p) => p.uuid == uuid);
  }
  /**
   * Find a participant by name
   *
   * @param {string} name
   * @return {*}
   * @memberof PexipStore
   */
  findParticipantByName(name: string): void | TParticipant {
    return this.call.participants.find((p) => p.display_name == name);
  }
}

export const pexipStore = new PexipStore({store, name: 'pexipStore'});
