<template>
  <div
    id="modal-calendar-integration"
    class="f-modal"
  >
    <div class="f-modal-table">
      <div class="f-modal-cell align-middle">
        <div class="f-modal-content">
          <div
            class="f-modal-close"
            @click="$emit('close')"
          />
          <div class="modal-popup">
            <div class="modal-popup-content">
              <div class="my-4">
                <img
                  v-if="showPremium"
                  src="@/assets/img/icons/ic_premium.svg"
                >
                <i
                  v-else
                  class="fas fa-exclamation-triangle fa-5x"
                />
              </div>
              <h6>
                {{ headerText }}
              </h6>
              <p>
                {{ mainText }}
              </p>
              <div v-if="!hideButton">
                <button
                  v-if="canAuthUserAccessAdminFeatures"
                  class="btn btn-primary"
                  @click="unlockThisFeature"
                >
                  {{ buttonText }}
                </button>
                <p v-else>
                  Account admin can unlock this feature
                </p>
              </div>
              <div>
                <a
                  class="btn mt-1"
                  @click.prevent="$emit('close')"
                >
                  {{ hideButton ? 'Close' : 'Not now' }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PlanMixin from '@/mixins/PlanMixin';

// PremiumPopup Type should be moved to here from __VIEW_Profile when this file is converted to Typescript

export default {
  mixins: [PlanMixin],
  props: {
    headerText: {
      type: String,
      default: 'You discovered a Premium feature!',
    },
    mainText: {
      type: String,
      default: 'This feature is not included in your current plan',
    },
    buttonText: {
      type: String,
      default: 'Unlock this feature',
    },
    hideButton: Boolean,
    prevRoute: {
      type: String,
      default: null,
    },
    // This is used to change between the diamond icon and a warning icon
    // If true shows diamond else shows warning
    showPremium: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    // eslint-disable-next-line
    $('body').addClass('f-modal-is-open')
  },
  destroyed() {
    // eslint-disable-next-line
    $('body').removeClass('f-modal-is-open')
  },
  methods: {
    unlockThisFeature() {
      this.$emit('hide-premium-feature-popup');
      this.$router.push({name: 'account-plan', params: {prevRoute: this.prevRoute}});
    },
  },
};
</script>
