export default {
  data() {
    return {
      sufficientPassword: false,
      showPasswordStrengthError: false,
    };
  },
  methods: {
    updatePasswordStrength(bool) {
      this.sufficientPassword = bool;
      if (this.sufficientPassword) {
        this.showPasswordStrengthError = false;
      }
    },
  },
};
