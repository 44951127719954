<template>
  <div class="ongoing-meeting">
    <div
      v-show="!connected"
      class="connecting-page"
    >
      <Loading />
      <p>
        Loading &hellip;
      </p>
    </div>
    <div
      v-show="connected"
      class="meeting-cover"
    >
      <div class="meeting-container">
        <meeting-side-bar
          v-show="isShowSidebar"
          ref="sidebar"
          :class="{
            'hide': (isMobile() || !toggleSidebar),
          }"
        />

        <div class="meeting-screen">
          <firefox-header
            v-if="showFirefoxHeader"
            @hide="showFirefoxHeader = false"
          />
          <div
            class="meeting-header call-control"
            :class="{ 'fade-hide': (!isHoverCallControl && !showCallControls) }"
            @mouseenter="isHoverCallControl = true"
            @mouseleave="isHoverCallControl = false"
          >
            <div
              v-if="thinDesktop()"
              class="header-layer pb-3"
            >
              <div class="header-left distribute-horizontal">
                <div
                  v-if="!isMobile()"
                  class="meeting-action"
                  @click.prevent="toggleSidebar"
                >
                  <img
                    v-if="isShowSidebar"
                    src="@/assets/img/icons/ic_hide_sidebar.svg"
                  >
                  <img
                    v-else
                    src="@/assets/img/icons/ic_show_sidebar.svg"
                  >
                </div>
                <div>
                  <div
                    class="meeting-action"
                    :class="{ 'bg-white': isShowSettings }"
                    @click.prevent="toggleSettings"
                  >
                    <img
                      v-if="isShowSettings"
                      src="@/assets/img/icons/ic_settings_lg_hover.svg"
                    >
                    <img
                      v-else
                      src="@/assets/img/icons/ic_settings_lg.svg"
                    >
                  </div>
                  <settings-container
                    v-if="isShowSettings"
                    :is-host="isHost"
                    :initial-call-type="callType"
                    :initial-layout="layout"
                    :initial-maximum-bandwidth-kbps="maximumBandwidthKbps"
                    :preselected-camera-device-id="selectedCameraDeviceId"
                    :preselected-microphone-device-id="selectedMicrophoneDeviceId"
                    :preselected-speaker-device-id="selectedSpeakerDeviceId"
                    class="on-top"
                    @renegotiate="renegotiate"
                    @apply-room-settings="applyRoomSettings"
                  />
                </div>
              </div>

              <div class="header-right">
                <div
                  v-if="isHost"
                  class="meeting-action-dropdown"
                  :class="{ 'fade-hide': (!isHoverCallControl && !showCallControls) }"
                >
                  <div
                    ref="ignore"
                    v-tooltip="'Show open AI chatbot'"
                    class="meeting-action"
                    :class="{ 'bg-white': isShowChatBot }"
                    @click.prevent="isShowChatBot = !isShowChatBot"
                  >
                    <img
                      v-if="isShowChatBot"
                      src="@/assets/img/icons/ic_speech_bubble.svg"
                    >
                    <img
                      v-else
                      src="@/assets/img/icons/ic_speech_bubble.svg"
                    >
                  </div>
                  <chat-bot
                    v-if="isShowChatBot"
                    v-click-outside="{exclude: ['ignore'],}"
                    class="on-top"
                    @close-chatbot="isShowChatBot=false"
                  />
                </div>
                <div
                  v-if="isHost || inLobby"
                  v-tooltip="isKeyPad ? 'Hide keypad' : 'Show keypad'"
                  class="meeting-action"
                  :class="{
                    'fade-hide': !showCallControls,
                    'mr-3': !isMobile()
                  }"
                  @click="isKeyPad = !isKeyPad"
                >
                  <img
                    src="@/assets/img/icons/ic_dial_white.svg"
                    class="w-50"
                  >
                </div>
                <div v-if="showKeypad">
                  <keypad
                    v-if="isKeyPad"
                    :participants="participants"
                    @send-dtmf="sendDTMF"
                  />
                </div>

                <div
                  v-if="isHost"
                  class="meeting-action-dropdown"
                  :class="{ 'fade-hide': (!isHoverCallControl && !showCallControls) }"
                >
                  <div
                    ref="ignore"
                    v-tooltip="'Invite someone'"
                    class="meeting-action"
                    :class="{ 'bg-white': isShowSendInvite }"
                    @click.prevent="isShowSendInvite = !isShowSendInvite"
                  >
                    <img
                      v-if="isShowSendInvite"
                      src="@/assets/img/icons/ic_plus_hover.svg"
                    >
                    <img
                      v-else
                      src="@/assets/img/icons/ic_plus.svg"
                    >
                  </div>
                  <send-invite
                    v-if="isShowSendInvite"
                    v-click-outside="{exclude: ['ignore'], handler: 'hideShareInvite'}"
                    :webrtc-alias="webrtcAlias"
                    class="on-top"
                    @send-emails="sendEmails"
                    @dial-endpoint="dialInEndpoint"
                    @close-invite="isShowSendInvite=false"
                  />
                </div>
              </div>
            </div>

            <div class="header-layer">
              <div class="header-left">
                <div class="meeting-title">
                  <h6 v-if="meetingRoom && meetingRoom.webrtc">
                    Room {{ meetingRoom.webrtc.alias }}
                    <small
                      v-if="freePlan"
                      class="ml-2"
                    >Time Left: {{ remainDuration }}</small>
                  </h6>
                  <p v-if="meetingDuration">
                    Meeting duration: {{ meetingDuration | timeInHoursMinsSeconds }}
                  </p>
                </div>
              </div>

              <div class="header-right">
                <a
                  v-if="!isMobile()"
                  class="fullscreen-button meeting-action"
                  :class="{ 'fade-hide': (!isHoverCallControl && !showCallControls) }"
                  @click.prevent="toggleFullscreen()"
                >
                  <img
                    src="@/assets/img/icons/ic_full_screen.svg"
                    alt="Fullscreen"
                  >
                </a>
              </div>
            </div>
          </div>

          <div class="meeting-content">
            <div
              v-show="(isIncomingVideoSupportedByCallType && !isReconnecting)"
              class="video-container w-100"
            >
              <div class="position-relative w-100 video-top-half align-items-center">
                <annotation-canvas
                  v-if="showCanvas"
                  ref="canvas"
                  class="canvas-container"
                />
                <annotation-screenshot-selector
                  v-if="showScreenshot"
                  class="canvas-container"
                />
                <video
                  v-show="topVideo == 'video'"
                  id="participants"
                  ref="participants"
                  class="w-100"
                  playsinline
                  autoplay
                  disablePictureInPicture
                  :class="{hide : showScreenshot || showCanvas}"
                  @dblclick="toggleFullscreen()"
                />
                <video
                  v-show="(localPresentationFeed || remotePresentationFeed) && topVideo == 'screen'"
                  id="presentation"
                  ref="presentation"
                  class="w-100"
                  playsinline
                  autoplay
                  disablePictureInPicture
                  @dblclick="toggleFullscreen()"
                />
              </div>
              <template v-if="isMobileDevice()">
                <div class="video-bottom-half">
                  <SelfView
                    v-if="callType === 'video' && actions.camera.enabled"
                    :video-stream="selfStream"
                    :show-call-controls="showCallControls"
                    :muted="true"
                    :type="'camera'"
                  />
                </div>
              </template>
            </div>

            <div
              v-show="!isReconnecting && !isIncomingVideoSupportedByCallType"
            >
              <div
                class="h-100 text-white d-flex align-items-center justify-content-center text-center"
              >
                <h3>Audio only</h3>
              </div>
            </div>

            <div v-show="isReconnecting">
              <div
                class="h-100 w-100 on-top text-white d-flex align-items-center justify-content-center text-center"
              >
                <h3 class="on-top">
                  <span class="fa fa-cog fa-spin" /> Reconnecting...
                </h3>
              </div>
            </div>
          </div>

          <div
            class="meeting-footer call-control"
            @mouseenter="isHoverCallControl = true"
            @mouseleave="isHoverCallControl = false"
          >
            <div
              v-show="!isMobileDevice()"
              class="footer-left"
              :class="{ 'fade-hide': (!isHoverCallControl && !showCallControls) }"
            >
              <div
                v-if="!thinDesktop()"
                class="meeting-action"
                @click.prevent="toggleSidebar"
              >
                <img
                  v-if="isShowSidebar"
                  src="@/assets/img/icons/ic_hide_sidebar.svg"
                >
                <img
                  v-else
                  src="@/assets/img/icons/ic_show_sidebar.svg"
                >
              </div>
              <div>
                <div
                  v-show="!thinDesktop()"
                  class="meeting-action"
                  :class="{ 'bg-white': isShowSettings }"
                  @click.prevent="isShowSettings = !isShowSettings"
                >
                  <img
                    v-if="isShowSettings"
                    src="@/assets/img/icons/ic_settings_lg_hover.svg"
                  >
                  <img
                    v-else
                    src="@/assets/img/icons/ic_settings_lg.svg"
                  >
                </div>
                <settings-container
                  v-if="isShowSettings"
                  :is-host="isHost"
                  :initial-call-type="callType"
                  :initial-layout="layout"
                  :initial-maximum-bandwidth-kbps="maximumBandwidthKbps"
                  :preselected-camera-device-id="selectedCameraDeviceId"
                  :preselected-microphone-device-id="selectedMicrophoneDeviceId"
                  :preselected-speaker-device-id="selectedSpeakerDeviceId"
                  class="on-top"
                  @renegotiate="renegotiate"
                  @apply-room-settings="applyRoomSettings"
                />
              </div>
            </div>
            <div
              class="footer-body"
              :class="{ 'fade-hide': (!isHoverCallControl && !showCallControls) }"
            >
              <toggle-mic
                :selected-microphone-device-id="selectedMicrophoneDeviceId"
                :allowed="actions.microphone.allowed"
                :enabled="actions.microphone.enabled"
                :muted-remotely="actions.microphone.mutedRemotely"
                @toggle="toggleMyAudio"
                @open-settings="isShowSettings = true"
              />

              <toggle-video
                :selected-camera-device-id="selectedCameraDeviceId"
                :call-type="callType"
                :allowed="actions.camera.allowed"
                :enabled="actions.camera.enabled"
                @toggle="toggleMyVideo"
                @open-settings="isShowSettings = true"
              />

              <screen-share
                v-if="!isMobile()"
                :call-type="callType"
                :enabled="localPresentationFeed ? true : false"
                :disabled="remotePresentationFeed ? true : false"
                @toggle="toggleScreenShare"
                @open-settings="isShowSettings = true"
              />

              <div class="action-divider" />

              <div
                v-if="localPresentationFeed || remotePresentationFeed"
                v-tooltip="
                  topVideo === 'video'
                    ? 'Show presentation'
                    : 'Show attendees'
                "
                class="meeting-action"
                @click="toggleCameraVsScreenshare"
              >
                <img
                  v-if="topVideo === 'video'"
                  src="@/assets/img/icons/ic_switch_video.svg"
                >
                <img
                  v-else
                  src="@/assets/img/icons/ic_users.svg"
                >
              </div>
              <div
                v-tooltip="isHandRising ? 'Lower a hand' : 'Raise a hand'"
                class="meeting-action"
                :class="{ 'fade-hide': (!isHoverCallControl && !showCallControls) }"
                @click.prevent="raiseHand"
              >
                <img
                  v-if="isHandRising"
                  src="@/assets/img/icons/ic_raised_hand_c.svg"
                  class="img-width"
                >
                <img
                  v-else
                  src="@/assets/img/icons/ic_raise_hand.svg"
                  class="img-width"
                >
              </div>
              <!-- <div
                                v-if="(isHost && isRoomOwner) || (isRemoteExpert && !isRemoteExpertRecord) || (authInfo && authInfo.id && !isRemoteExpertRecord) || (!isLoggedIn && !isRemoteExpertRecord)
                v-tooltip="recordingTooltip"
                class="meeting-action"
                @click="isRecording ? stopRecording() : startRecording()"
              > -->
              <div
                v-if="(isHost && isRoomOwner)"
                v-tooltip="recordingTooltip"
                class="meeting-action"
                @click="isRecording ? stopRecording() : startRecording()"
              >
                <img
                  v-if="isRecording"
                  src="@/assets/img/icons/ic_recording_stop.svg"
                >
                <img
                  v-else
                  src="@/assets/img/icons/ic_record.svg"
                  class="w-50"
                >
              </div>
              <div
                v-if="isHost && !isAlwaysLocked"
                v-tooltip="isLocked ? 'Unlock' : 'Lock'"
                class="meeting-action position-relative"
                @click="toggleMeetingLock"
              >
                <img
                  v-if="isLocked"
                  class="img-width"
                  src="@/assets/img/icons/ic_locked_meeting_white.svg"
                >
                <img
                  v-else
                  class="img-width"
                  src="@/assets/img/icons/ic_unlocked_meeting_white.png"
                >
              </div>
              <div
                v-if="(isHost && (!isAlwaysLocked || isRoomOwner)) || remotePresentationFeed"
                class="action-divider"
              />
              <div
                class="meeting-action bg-danger"
                @click.prevent="disconnectMe"
                @mouseenter="isHoverEndCall = true"
                @mouseleave="isHoverEndCall = false"
              >
                <img
                  v-if="isHoverEndCall"
                  src="@/assets/img/icons/ic_end_call_hover.svg"
                >
                <img
                  v-else
                  src="@/assets/img/icons/ic_end_call.svg"
                >
              </div>
            </div>

            <div
              v-show="!isMobileDevice()"
              class="footer-right"
            >
              <div
                v-show="!thinDesktop()"
                class="meeting-action-dropdown"
                :class="{ 'fade-hide': (!isHoverCallControl && !showCallControls) }"
              >
                <div
                  ref="ignore"
                  v-tooltip="'Chatbot'"
                  class="meeting-action"
                  :class="{ 'bg-white': isShowChatBot }"
                  @click.prevent="isShowChatBot = !isShowChatBot"
                >
                  <img
                    v-if="isShowChatBot"
                    src="@/assets/img/icons/ic_speech_bubble_hover.svg"
                  >
                  <img
                    v-else
                    src="@/assets/img/icons/ic_speech_bubble.svg"
                  >
                </div>
                <chat-bot
                  v-if="isShowChatBot"
                  v-click-outside="{exclude: ['ignore'],}"
                  class="on-top"
                  @close-chatbot="isShowChatBot=false"
                />
              </div>
              <div
                v-show="!thinDesktop()"
                v-if="isHost"
                v-tooltip="'Show phonebook'"
                class="meeting-action"
                :class="{ 'fade-hide': (!isHoverCallControl && !showCallControls) }"
                @click.prevent="openPhonebook"
              >
                <img
                  src="@/assets/img/icons/ic_phonebook.svg"
                  class="w-50"
                >
              </div>

              <div
                v-show="!thinDesktop()"
                v-if="isHost"
                class="meeting-action-dropdown"
                :class="{ 'fade-hide': (!isHoverCallControl && !showCallControls) }"
              >
                <div
                  ref="ignore"
                  v-tooltip="'Invite someone'"
                  class="meeting-action"
                  :class="{ 'bg-white': isShowSendInvite }"
                  @click.prevent="isShowSendInvite = !isShowSendInvite"
                >
                  <img
                    v-if="isShowSendInvite"
                    src="@/assets/img/icons/ic_plus_hover.svg"
                  >
                  <img
                    v-else
                    src="@/assets/img/icons/ic_plus.svg"
                  >
                </div>
                <send-invite
                  v-if="isShowSendInvite"
                  v-click-outside="{exclude: ['ignore'], handler: 'hideShareInvite'}"
                  :webrtc-alias="webrtcAlias"
                  class="on-top"
                  @send-emails="sendEmails"
                  @dial-endpoint="dialInEndpoint"
                  @close-invite="isShowSendInvite=false"
                />
              </div>

              <div
                v-show="!thinDesktop()"
                v-if="isHost || inLobby"
                v-tooltip="isKeyPad ? 'Hide keypad' : 'Show keypad'"
                class="meeting-action"
                :class="{
                  'fade-hide': (!isHoverCallControl && !showCallControls),
                  'mr-3': !thinDesktop()
                }"
                @click="isKeyPad = !isKeyPad"
              >
                <img
                  src="@/assets/img/icons/ic_dial_white.svg"
                  class="w-50"
                >
              </div>
              <keypad
                v-if="isKeyPad"
                :participants="participants"
                @send-dtmf="sendDTMF"
              />

              <SelfView
                v-if="callType === 'video' && actions.camera.enabled && !hasNoCamera"
                :video-stream="selfStream"
                :show-call-controls="showCallControls"
                :muted="true"
                :type="'camera'"
              />
              <SelfView
                v-show="presentationFeed && topVideo == 'video'"
                id="right-view"
                :video-stream="presentationFeed"
                :show-call-controls="showCallControls"
                :muted="true"
                :type="'screen'"
              />
              <SelfView
                v-show="videoStream && topVideo == 'screen'"
                id="right-view"
                :video-stream="videoStream"
                :show-call-controls="showCallControls"
                :muted="true"
                :type="'screen'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-dialog
      v-if="isShowDisconnectConfirm"
      :title="confirmData.title"
      :description="confirmData.description"
      :in-call-centre="isShowSidebar"
      @no="isShowDisconnectConfirm = false"
      @yes="yesConfirm"
    />
    <end-stream-ack v-if="showEndStreamAck" />
    <markup-canvas v-if="showMarkupCanvas" />
    <chat-notification v-if="showNotification" />
  </div>
</template>

<script>
import Vue from 'vue';
import {authStore} from '@/store/__STORE_auth';
import {callStore} from '@/store/__STORE_call';
import {brandingStore} from '@/store/__STORE_branding';
import {pexipStore} from '@/store/__STORE_pexip';
import {mainStore} from '@/store/__STORE_main';
import {userStore} from '@/store/__STORE_user';
import {messageStore} from '@/store/__STORE_message';
import {meetingStore} from '@/store/__STORE_meetings';
import {expertStore} from '@/store/__STORE_expert';

import ToggleVideo from '@/components/call/actions/ToggleVideo.vue';
import ToggleMic from '@/components/call/actions/ToggleMic.vue';
import ScreenShare from '@/components/call/actions/ScreenShare.vue';
import SelfView from '@/components_v2/intelligent/video/__COMP_SelfView.vue';
import MeetingSideBar from '@/components_v2/intelligent/call/__COMP_MeetingSideBar.vue';
import SettingsContainer from '@/components/call/settings/SettingsContainer.vue';
import SendInvite from '@/components/SendInvite.vue';
import ChatBot from '@/components/OpenAIChatBot.vue';
import Keypad from '@/components/Keypad.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import ResponsiveMixin from '@/mixins/ResponsiveMixin';
import AnnotationCanvas from '@/components_v2/intelligent/annotation/__COMP_AnnotationCanvas.vue';
import AnnotationScreenshotSelector from '@/components_v2/intelligent/annotation/__COMP_AnnotationScreenshotSelector.vue';
import Loading from '@/components/Loading.vue';
import FirefoxHeader from '@/components/call/FirefoxHeader.vue';
import EndStreamAck from '@/components_v2/intelligent/call/__COMP_EndStreamAcknowledgement.vue';
import MarkupCanvas from '@/components_v2/intelligent/annotation/__COMP_MarkupCanvas.vue';
import ChatNotification from '@/components_v2/generic/call/__COMP_ChatNotification.vue';

export default {
  components: {
    MeetingSideBar,
    SettingsContainer,
    SendInvite,
    SelfView,
    ToggleMic,
    ToggleVideo,
    Keypad,
    ScreenShare,
    ConfirmDialog,
    AnnotationCanvas,
    AnnotationScreenshotSelector,
    Loading,
    FirefoxHeader,
    EndStreamAck,
    MarkupCanvas,
    ChatNotification,
    ChatBot,
  },
  mixins: [
    ResponsiveMixin,
  ],
  props: {
    testingCompleted: {
      type: Boolean,
      default: false,
    },
    settingsData: {
      type: Object,
      default: () => {},
    },
    webrtcAlias: {
      type: String,
      default: null,
    },
    preloadedMeetingRoom: {
      type: Object,
      default: () => {},
    },
    preloadedDisplayName: {
      type: String,
      default: '',
    },
    preloadedCallType: {
      type: String,
      default: 'video',
    },
    preloadedPin: {
      type: String,
      default: '',
    },
    retryConnecting: {
      type: Boolean,
      default: false,
    }, // Set this to true for instant meeting rooms and false for others
    isRoomOwner: {
      type: Boolean,
      default: false,
    },
    hasNoCamera: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return this.initData();
  },
  computed: {
    isLoggedIn: () => authStore.__GETTERisUserLoggedIn,
    meetingRoom: () => callStore.vmr,
    actions: () => pexipStore.actions,
    callType: () => pexipStore.call.type,
    isTestingCompleted: () => pexipStore.connection.isTestingCompleted,
    previousRoute: () => mainStore.previousRoute,
    // isShowSettings: () => pexipStore.isShowSettings,
    isRecording: () => pexipStore.__GETTERisRecording,
    isRecordingLock: () => pexipStore.__GETTERisRecordingLock,
    // isKeyPad: () => pexipStore.isKeyPad,
    isRecordingAvailable: () => pexipStore.__GETTERisRecordingAvailable,
    isRecordingAvailableMessage: () => pexipStore.actions.recording.availableMessage,
    isLocked: () => pexipStore.call.isLocked,
    isAlwaysLocked: () => pexipStore.call.isAlwaysLocked,
    selectedCameraDeviceId: () => pexipStore.devices.selectedCameraDeviceId,
    selectedMicrophoneDeviceId: () => pexipStore.devices.selectedMicrophoneDeviceId,
    selectedSpeakerDeviceId: () => pexipStore.devices.selectedSpeakerDeviceId,
    selfStream: () => pexipStore.streams.selfStream,
    meetingDuration: () => pexipStore.call.meetingDuration,
    isTesting: () => pexipStore.connection.isTesting,
    pin: () => pexipStore.call.pin,
    previousPin: () => pexipStore.call.previousPin,
    lastPinTried: () => pexipStore.call.lastPinTried,
    showPinInput: () => pexipStore.showPinInput,
    invalidPin: () => pexipStore.call.invalidPin,
    isConnecting: () => pexipStore.connection.isConnecting,
    connected: () => pexipStore.connection.connected,
    myUuid: () => pexipStore.__GETTERmyUuid,
    participants: () => pexipStore.call.participants,
    selectedParticipantUuid: () => pexipStore.call.selectedParticipantUuid,
    recordingParticipantName: () => pexipStore.actions.recording.participantName,
    isHost: () => pexipStore.call.isHost,
    inLobby: () => pexipStore.call.inLobby,
    maximumBandwidthKbps: () => pexipStore.call.maximumBandwidthKbps,
    freePlan: () => pexipStore.call.freePlan,
    remainDuration: () => pexipStore.call.remainDuration,
    // topVideo: () => pexipStore.call.topVideo,
    isReconnecting: () => pexipStore.connection.isReconnecting,
    initialConnection: () => pexipStore.connection.initialConnection,
    showKeypad: () => pexipStore.showKeypad,
    isIncomingVideoSupportedByCallType: () => pexipStore.supportedMedia.isIncomingVideoSupportedByCallType,
    isOutgoingVideoSupportedByCallType: () => pexipStore.supportedMedia.isOutgoingVideoSupportedByCallType,
    resetIdle: () => pexipStore.resetIdle,
    muteStatus: () => pexipStore.call.muteStatus,
    // isScreenStream: () => pexipStore.call.isScreenStream,
    videoStream: () => pexipStore.streams.videoStream,
    recordingTooltip: () => {
      // if (!pexipStore.isLoggedIn) {
      //   return 'Recording is available for registered users';
      // }

      if (!pexipStore.__GETTERisRecordingAvailable) {
        return pexipStore.actions.recording.availableMessage;
      }

      return pexipStore.__GETTERisRecording ? 'Stop recording' : 'Start recording';
    },
    layout: () => pexipStore.call.layout,
    isShowSidebar: () => pexipStore.call.isShowSidebar,
    screenShareActivated: () => pexipStore.actions.screenShare.enabled,
    localPresentationFeed: () => {
      return pexipStore.actions.screenShare.stream && !pexipStore.actions.screenShare.incoming ? pexipStore.actions.screenShare.stream : null;
    },
    remotePresentationFeed: () => {
      return pexipStore.actions.screenShare.stream && pexipStore.actions.screenShare.incoming ? pexipStore.actions.screenShare.stream : null;
    },
    redirectBack: () => pexipStore.redirectBack,
    redirectBackError: () => pexipStore.redirectBackError,
    redirectAfterCall: () => pexipStore.redirectAfterCall,
    showScreenshot: () => pexipStore.actions.annotation.showScreenshot,
    showCanvas: () => pexipStore.actions.annotation.showCanvas,
    topVideo() {
      if (!this.presentationFeed) return 'video';
      if (this.topVideo__OVERRIDE) return this.topVideo__OVERRIDE;
      if (this.remotePresentationFeed) return 'screen';
      else return 'video';
    },
    presentationFeed() {
      return this.localPresentationFeed || this.remotePresentationFeed;
    },
    showEndStreamAck: () => pexipStore.__GETTERshowEndStreamAck,
    showMarkupCanvas: () => messageStore.__GETTERshowMarkupCanvas,
    showNotification: () => messageStore.__GETTERshowNotificationState,
    authInfo: () => userStore.__GETTERuserProfile,
    isRemoteExpert: () => userStore.__GETTERuserProfile?.is_remote_expert,
    callData: () => callStore.__GETTERcallData,
  },
  watch: {
    resetIdle(value) {
      if (value) {
        this.resetIdleTime();
      }
    },
    videoStream: {
      deep: true,
      immediate: true,
      handler() {
        if (this.videoStream) {
          const video = document.getElementById('participants');
          video.srcObject = pexipStore.streams.videoStream;
          video.play();
        }
      },
    },
    isShowSidebar: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.openSidebar();
        } else {
          this.closeSidebar();
        }
      },
    },
    localPresentationFeed: {
      deep: true,
      handler() {
        if (this.localPresentationFeed) {
          const video = document.getElementById('presentation');
          video.srcObject = this.localPresentationFeed;
        }
      },
    },
    remotePresentationFeed: {
      deep: true,
      handler() {
        if (this.remotePresentationFeed) {
          const video = document.getElementById('presentation');
          video.srcObject = this.remotePresentationFeed;
        }
      },
    },
    redirectAfterCall: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.redirectToAfterCallPage();
        }
      },
    },
    redirectBack: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.redirectBackFunc();
        }
      },
    },
    isHost: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.isKeyPad = false;
        }
      },
    },
    inLobby: {
      handler(value) {
        if (!value) {
          this.isKeyPad = false;
        }
      },
    },
    callData: {
      handler(value) {
        const fullName = value?.user?.name && value?.user?.last_name ? `${value.user.name} ${value.user.last_name} ` : '';
        if (value?.status === 'reject') {
          this.$toast.error(`User ${fullName}has rejected the call`);
        }
      },
      deep: true,
    },
    myUuid(val) {
      if (val) pexipStore.setParticipant(val);
    },
  },
  mounted: async function() {
    if (!this.testingCompleted) {
      if (authStore.__GETTERisUserLoggedIn) {
        await userStore.getUserProfile();
        if (userStore.__GETTERuserVmrAlias === this.webrtcAlias) {
          this.$router.push({
            name: 'host',
            params: {
              webrtcAlias: this.webrtcAlias,
            },
          });
        } else {
          this.$router.push({
            name: 'welcome-guest',
            params: {
              webrtcAlias: this.webrtcAlias,
            },
          });
        }
      } else {
        this.$router.push({
          name: 'welcome-guest',
          params: {
            webrtcAlias: this.webrtcAlias,
          },
        });
      }
    }
    if ((this.isRemoteExpert) || (this.authInfo && this.authInfo.id)) {
      await userStore.getUserProfile().then(() => {
        callStore.getCallFromPhonebook({id: this.authInfo.id, isCall: false}).then((res) => {
          this.pusher = res;
          const channel = this.pusher.subscribe(`private-record.${callStore.__GETTERvmr.host_id}`);
          channel.bind(`record.${callStore.__GETTERvmr.host_id}`, (data) => {
            if (!this.isRecording) {
              this.startRecording(data.data.userId);
            }
          });
        });
        this.timeoutMissed = setTimeout(() => {
          if (!this.callData?.status && callStore.__GETTERcalledUser?.id) {
            const fullName = `${callStore.__GETTERcalledUser.name} ${callStore.__GETTERcalledUser.last_name}`;
            this.$toast.error(`User ${fullName} has missed the call`);
            callStore.__setCalledUser(null);
          }
        }, 26000);
      });
      await expertStore.setBusy({
        busy: true,
        time: '20',
        manual: false,
      });
      this.timeout = setInterval(() => expertStore.setBusy({
        busy: true,
        time: '20',
        manual: false,
      }), 15000);
    }
    await pexipStore.setCallType(this.preloadedCallType);
    await pexipStore.setIsTestingComplete(this.testingCompleted);
    await mainStore.setPrevRoute({
      name: 'welcome-guest',
      params: {
        webrtcAlias: this.webrtcAlias,
      },
    });

    await pexipStore.setDisplayName(this.preloadedDisplayName);

    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (iOS) {
      const ua = window.navigator.userAgent;
      const webkit = !!ua.match(/WebKit/i);
      const isSafari = webkit && !ua.match(/CriOS/i) && !ua.match(/OPiOS/i);
      if (!isSafari) {
        const error = 'On Apple iOS, only Safari browser is supported.';
        this.redirectBackFunc(error);
        return;
      }
    }

    this.$emit('enable-fullscreen');
    if (screen.width > 576) {
      await pexipStore.setIsShowSidebar(this.isShowSidebar);
    }

    if (!this.webrtcAlias) {
      const routeName = this.isLoggedIn ? 'dashboard' : 'join-meeting';
      this.$router.push({name: routeName});
    }

    this.loadMeetingRoom();
  },
  created() {
    const firefox = /Mozilla/.test(navigator.userAgent) && /Firefox/.test(navigator.userAgent);

    if (firefox && !this.isMobile()) {
      if (localStorage.getItem('showHeader') == null) {
        this.showFirefoxHeader = true;
      } else {
        this.showFirefoxHeader = false;
      }
    }

    window.addEventListener('beforeunload', this.closeBrowser);

    // Fade out on idle
    document.addEventListener('mousemove', this.resetIdleTime);
    document.addEventListener('keypress', this.resetIdleTime);
    this.idleTimer = setInterval(this.incrementIdleTimer, 1000);
  },
  beforeDestroy() {
    if (this.isRemoteExpert || (this.authInfo && this.authInfo.id)) {
      if (this.pusher) this.pusher.unsubscribe(`private-user.${this.authInfo.id}`);
      clearInterval(this.timeout);
      clearInterval(this.timeoutMissed);
      expertStore.setBusy({
        busy: false,
        time: '20',
        manual: false,
      });
    }
    this.$emit('set-room-brand', null);
    window.addEventListener('beforeunload', this.closeBrowser);

    // Fade out on idle
    document.removeEventListener('mousemove', this.resetIdleTime);
    document.removeEventListener('keypress', this.resetIdleTime);
    clearInterval(this.idleTimer);
  },
  destroyed() {
    // todo
    if (this.updateMeetingDurationInterval) {
      clearInterval(this.updateMeetingDurationInterval);
    }
    if (this.updateRemainDurationInterval) {
      clearInterval(this.updateRemainDurationInterval);
    }
    this.closeBrowser();
  },
  methods: {
    initData() {
      return {
        showFirefoxHeader: false,

        topVideo__OVERRIDE: '',

        isShowSendInvite: false,
        isShowChatBot: false,
        isShowAdditionalOptions: false,
        isShowSettings: false,
        isHoverEndCall: false,

        isMaximize: false,

        isKeyPad: false,
        isScreenStream: false,

        // remove
        updateMeetingDurationInterval: null,
        updateRemainDurationInterval: null,

        isShowDisconnectConfirm: false,
        confirmData: {
          title: '',
          description: '',
          callbackType: 0,
          attachData: '',
        },

        isFullscreen: false,

        // Fade out on idle
        idleTimer: null,
        idleTime: 0,
        showCallControls: true,
        isHoverCallControl: false,
        isHandRising: false,
        timeout: null,
        timeoutMissed: null,
        pusher: null,
        isRemoteExpertRecord: false,
      };
    },
    hideShareInvite() {
      this.isShowSendInvite = false;
    },
    resetIdleTime() {
      if (this.isHoverCallControl === false) {
        this.idleTime = 0;
        this.showCallControls = true;
        const parent = document.getElementsByClassName('ongoing-meeting');
        parent[0].style.cursor = 'auto';
      }
    },
    incrementIdleTimer() {
      if (this.idleTime < 3) {
        this.idleTime += 1;
        return;
      }

      if (this.isLandscape()) {
        this.showCallControls = false;
        const parent = document.getElementsByClassName('ongoing-meeting');
        parent[0].style.cursor = 'none';
      }
    },
    // First Method to Run
    async loadMeetingRoom() {
      // eslint-disable-next-line
      await pexipStore.initialisePexip(new PexRTC());
      if (this.meetingRoom.brand) {
        this.$emit('set-room-brand', this.meetingRoom.brand);
        await brandingStore.setLogo({
          logo: this.meetingRoom.brand.logo,
          level: this.meetingRoom.brand.level,
        });
        await brandingStore.setGuestWelcomeMessage(
            this.meetingRoom.brand.guest_welcome_message,
        );
        this.onMeetingRoomLoaded();
        return;
      }

      callStore.getVMR(this.webrtcAlias)
          .then(() => {
            this.$emit('set-room-brand', this.meetingRoom.brand);
            this.onMeetingRoomLoaded();
          })
          .catch(() => {
            this.redirectBackFunc();
          });
    },
    async onMeetingRoomLoaded() {
      await callStore.setLock(this.meetingRoom.is_locked);
      await pexipStore.setIsAlwaysLocked(this.meetingRoom.is_locked);
      await pexipStore.setPin(this.meetingRoom.webrtc.pin);
      await pexipStore.setPreviousPin(this.meetingRoom.webrtc.previous_pin);
      await pexipStore.setAllowedAnnotation(this.meetingRoom.has_xr_license);

      if (this.preloadedDisplayName) {
        await pexipStore.setDisplayName(this.preloadedDisplayName);
      }

      if (this.pin) {
        // PIN is only returned from backend for authorised users. So this is host.

        if (!pexipStore.displayName) {
          await pexipStore.setDisplayName(this.meetingRoom.host.name + ' ' + this.meetingRoom.host.last_name);
        }

        await mainStore.setPrevRoute(
            {
              name: 'host',
              params: {webrtcAlias: this.webrtcAlias},
            },
        );
      } else {
        await pexipStore.setPin(this.preloadedPin);
      }

      // If the testingCompleted param is is false i.e you navigated straight to the ongoing meeting, this will kick you back into the join screen.
      // The call is here rather than higher up so that the pin check occurs.
      if (!this.isTestingCompleted) {
        this.redirectBackFunc();
      }

      if (!pexipStore.displayName) {
        await pexipStore.setDisplayName(localStorage.getItem('guestDisplayNameTwo') || '');
      }

      if (this.isTestingCompleted) {
        await pexipStore.onTestingComplete(this.settingsData);
      }
    },

    toggleMyAudio() {
      const shouldMute = this.actions.microphone.enabled && !this.actions.microphone.mutedRemotely;

      pexipStore.toggleAudio(shouldMute);

      this.isShowSettings = false;
    },
    toggleMyVideo() {
      pexipStore.toggleVideo();

      this.isShowSettings = false;
    },
    toggleScreenShare() {
      if (!this.remotePresetationFeed) {
        pexipStore.toggleScreenShare();
      }
    },

    /**
     * Opens the sidebar
     */
    openSidebar() {
      pexipStore.openSidebar();
    },
    /**
     * Closes the side bar
     *
     * Will hide the keypad if it is visible in a mobile view
     */
    closeSidebar() {
      pexipStore.closeSidebar();
    },
    toggleSidebar() {
      if (this.isShowSidebar) {
        this.closeSidebar();
      } else {
        this.openSidebar();
      }
    },
    async renegotiate(s) {
      await pexipStore.renegotiate(s);
      this.isShowSettings = false;
    },
    /**
     * Applys the settings to the VMR
     *
     * - If iOS then this needs to happen to force self feed back
     *
     * @param {RoomSettings} data - Room settings to be applied
     * @example
     *
     */
    async applyRoomSettings(data) {
      await pexipStore.setCallType(data.callType || this.callType);
      if (this.isMobile()) {
        pexipStore.renegotiate({
          'callType': this.callType,
          'microphoneDeviceId': this.selectedMicrophoneDeviceId,
          'speakerDeviceId': this.selectedSpeakerDeviceId,
          'cameraDeviceId': this.selectedCameraDeviceId,
        });
      }
      if (this.isHost && (this.layout !== data.layout)) {
        pexipStore.setPendingLayout(data.layout);
      }

      // If bandwidth has been changed, a full reconnect is required.
      if (this.maximumBandwidthKbps !== data.maximumBandwidthKbps) {
        // The only thing that gets changed during reconnect is Bandwidth. CallType/mic/camera stay the same.
        pexipStore.reconnect({
          maximumBandwidthKbps: data.maximumBandwidthKbps,
          callType: this.callType,
          microphoneDeviceId: this.selectedMicrophoneDeviceId,
          speakerDeviceId: this.selectedSpeakerDeviceId,
          cameraDeviceId: this.selectedCameraDeviceId,
        });
      }

      this.isShowSettings = false;
      await pexipStore.applyPendingLayoutChange();
    },
    disconnectMe() {
      this.confirmData.title = 'Are you sure you want to end the call?';
      this.confirmData.callbackType = 1;
      this.isShowDisconnectConfirm = true;
    },
    realDisconnectMe() {
      pexipStore.realDisconnectMe();
    },
    disconnectParticipant(participant) {
      this.confirmData.title = 'Are you sure you want to disconnect current participant?';
      this.confirmData.callbackType = 2;
      this.confirmData.attachData = participant;
      this.isShowDisconnectConfirm = true;
    },
    realDisconnectParticipant() {
      const participant = this.confirmData.attachData;
      if (participant.uuid === this.myUuid) {
        this.realDisconnectMe();
        return;
      }

      pexipStore.disconnectParticipant(participant);
    },

    redirectBackFunc(error) {
      const params = Vue.util.extend({}, mainStore.previousRoute.params);
      if (error) {
        params.error = error;
      } else {
        params.error = this.redirectBackError;
      }
      this.$router.push({
        name: mainStore.previousRoute.name,
        params: params,
      });
    },

    // Recorder gets added as another participant
    // Instead of talking/listening like normal human would,
    // it will record the stream and send it to S3
    startRecording(userId) {
      // for non-register guest user
      // if (!this.isRecordingLock && !this.isHost && !this.isRemoteExpert && !userId && !this.isLoggedIn) {
      //   pexipStore.setRecordingLockState(true);
      //   messageStore.sendMessage('Requesting meeting recording')
      //       .then(() => this.isRemoteExpertRecord = true)
      //       .catch(() => {
      //         pexipStore.setIsRecordingState(false);
      //         pexipStore.setRecordingLockState(false);
      //       });
      // }

      // if user logged in isRecordingAvailable true.
      if (!this.isRecordingAvailable) {
        return false;
      }

      if (!this.isRecordingLock && this.isHost && !userId) {
        pexipStore.setRecordingLockState(true);
        callStore.startRecording().catch(() => {
          this.$toast.error('Recording Was Not Started');
          pexipStore.setIsRecordingState(false);
          pexipStore.setRecordingLockState(false);
        });
      }

      if (!this.isRecordingLock && !this.isHost && this.isRemoteExpert && !userId) {
        pexipStore.setRecordingLockState(true);
        callStore.expertRecording(callStore.__GETTERvmr.host_id)
            .catch(() => {
              pexipStore.setIsRecordingState(false);
              pexipStore.setRecordingLockState(false);
            });
      }
      if (!this.isRecordingLock && !this.isHost && !this.isRemoteExpert && !userId && this.authInfo && this.authInfo.id) {
        pexipStore.setRecordingLockState(true);
        callStore.expertRecording(callStore.__GETTERvmr.host_id)
            .catch(() => {
              pexipStore.setIsRecordingState(false);
              pexipStore.setRecordingLockState(false);
            });
      }


      if (userId) {
        pexipStore.setRecordingLockState(true);
        callStore.startRecording(userId).catch(() => {
          pexipStore.setIsRecordingState(false);
          pexipStore.setRecordingLockState(false);
        });
      }
    },

    stopRecording(userId) {
      // if (this.isHost || this.isRemoteExpert || (this.authInfo && this.authInfo.id)) {
      if (this.isHost) {
        callStore.stopRecording();
        this.isRemoteExpertRecord = false;
      }
    },

    toggleMeetingLock() {
      pexipStore.toggleMeetingLock();
    },

    sendDTMF(digit, uuid) {
      pexipStore.sendDTMF({
        digit: digit,
        uuid: uuid,
      });
    },
    toggleCameraVsScreenshare() {
      this.topVideo__OVERRIDE = this.topVideo == 'video' ? 'screen' : 'video';
    },
    closeBrowser() {
      pexipStore.closeBrowser();
    },
    redirectToAfterCallPage() {
      pexipStore.clearRedirect();
      this.$emit('set-room-brand', null);
      localStorage.setItem('isOwner', this.isRoomOwner);
      this.$router.push({
        name: 'after-call',
        params: {
          reloadRequired: true,
        },
      });
    },
    yesConfirm() {
      localStorage.removeItem('guest_pin');
      localStorage.removeItem('voiceMessages');
      this.isShowDisconnectConfirm = false;
      switch (this.confirmData.callbackType) {
        case 1:
          this.realDisconnectMe();
          break;
        case 2:
          this.realDisconnectParticipant();
          break;
      }
    },
    openFullscreen() {
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        /* Firefox */
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        /* IE/Edge */
        element.msRequestFullscreen();
      }
      this.isFullscreen = true;
    },
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
      this.isFullscreen = false;
    },
    toggleFullscreen() {
      if (!this.isMobile()) {
        if (this.isFullscreen) {
          this.closeFullscreen();

          if (!this.isMobile()) {
            this.openSidebar();
          }
        } else {
          this.openFullscreen();

          if (!this.isMobile()) {
            this.closeSidebar();
          }
        }
      }
    },
    /**
     * Toggle the visiblity of the settings panel on the page
     */
    toggleSettings() {
      this.isShowSettings = !this.isShowSettings;
    },
    sendEmails(emailArray) {
      this.isShowSendInvite = false;
      const payload = {
        emails: emailArray,
        alias: this.webrtcAlias,
      };
      callStore.sendEmailInvites(payload)
          .then(() => {
            this.$toast.success('Email Invites Sent!');
          })
          .catch(() => {
            this.$toast.error('Unable to send invites, something went wrong!');
          });
    },
    dialInEndpoint(details) {
      this.isShowSendInvite = false;
      const payload = {
        alias: this.webrtcAlias,
        address: details.address,
      };

      if (details.address.match(/@simply.video/g) || details.address.match(/@simplydemo.me/g)) {
        const subStrings = details.address.split('@');
        const prefix = subStrings[0];
        if (prefix == this.webrtcAlias) {
          this.$toast.error('ERROR: You cannot dial the room you are in.');
          return;
        }
      }

      callStore.dialInEndpoint(payload)
          .then((resp) => {
            this.$toast.success('Endpoint Called');
            const newID = resp.data.data.participant_id;
            setTimeout( () => {
              if (this.participants.filter((e) => e.uuid == newID).length == 0) {
                this.$toast.error('Endpoint unreachable or did not pick up, please try again.');
              }
            }, 2000);
          })
          .catch(() => {
            this.$toast.error('Unable to dial endpoint, something went wrong!');
          });
    },
    checkIfEndpointDialled(uuid) {
      return this.participants.filter((e) => e.uuid == uuid).length > 0;
    },
    openPhonebook() {
      window.$('#phonebookModal').modal('show');
      setTimeout(() => {
        meetingStore.setPhonebookModalState(true);
      }, 100);
    },
    raiseHand() {
      this.isHandRising = !this.isHandRising;
      this.isHandRising ? pexipStore.raiseHand() : pexipStore.lowerHand(this.myUuid);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/__variables";
@import "@/assets/scss/__meeting_action";
@import '~bootstrap/scss/mixins';

.hide {
  display: none;
}

.ongoing-meeting {
  background-color: $color-body;
  .connecting-page {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    > p {
      color: $white;
      position: relative;
      top: 6rem;
    }
  }
}

.on-top {
  z-index:10;
}

.img-width {
  width: 40%;
}

.meeting-cover {
  display: flex;
  height: 100%;
  min-height: stretch;
  min-height: -webkit-fill-available;
  overflow: hidden;

  .meeting-container {
    display: flex;
    flex: 1;

    .meeting-screen {
      background-color: $black;
      flex: 1;
      flex-direction: column;
      position: relative;
      width: 100%;

      .call-control {
        transition: opacity 0.125s;
        div img {
          position: relative;
          top: -2px
        }
      }
      .meeting-header {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        position: absolute;
        top: 0;
        width: stretch;
        width: -webkit-fill-available;
        z-index: 10;

        .header-layer {
          align-items: center;
          display: flex;
          width: 100%;
        }

        .header-left {
          align-items: center;
          display: flex;

          .distribute-horizontal {
            flex-direction: row;
          }

          @media all and (max-width: $mobileLandscapeMax) {
            .meeting-title {
              background-color: $secondary-color-darker;
              border-radius: 0 5px 5px 0;
              margin: 0 0 5px;
              padding: 0 4px;
            }
          }

          .meeting-title {
            color: $white;
            margin-left: 1rem;

            h6 {
              font-size: 1.95rem;
            }

            p {
              color: $meeting-text;
              margin: 0;
            }
          }
        }

        .header-right {
          display: flex;
          flex-direction: row;
          margin-left: auto;

          .fullscreen-button {
            cursor: pointer;
          }
        }

        #right-view {
          left: auto;
          right: 17px;
        }
      }

      .meeting-content {
        display: flex;
        height: 100%;
        justify-content: center;

        @media all and (max-width: $ongoingMeetingMax) {
          .video-container {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
          }
        }

        @media all and (min-width: $ongoingMeetingMin) {
          .video-top-half {
            align-items: flex-start;
            display: flex;
            height: 100%;
            width: 100%;
          }
        }

        video {
          flex: 1;
          max-height: 100%;
        }
        .text-white {
          color: $white !important;
        }
      }

      .meeting-footer {
        align-items: flex-end;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        position: absolute;
        width: stretch;
        width: -webkit-fill-available;
        z-index: 15;

        .footer-left {
          display: flex;
          flex: 1 1 200px;
        }

        .footer-body {
          align-items: center;
          display: flex;
          flex: 1 1 auto;
          justify-content: center;
          padding-right: 20px;

          .action-divider {
            border-left: 1px solid;
            height: 40px;
            margin: 10px;

            @include media-breakpoint-down(sm) {
              margin: 5px;
            }
          }
        }

        .footer-right {
          align-items: flex-end;
          display: flex;
          flex: 1 1 200px;
          justify-content: flex-end;
        }

        @include media-breakpoint-down(xs) {
          .footer-body {
            padding-right: 0px;
          }
          .meeting-footer {
            padding: 1vw;
          }
        }

      }

      .meeting-action-dropdown {
        position: relative;
      }
    }
  }
}

.canvas-container {
  z-index: 9999;
}
</style>
