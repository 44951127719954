
import Vue from 'vue';
import Component from 'vue-class-component';

import {pexipStore} from '@/store/__STORE_pexip';

@Component
/**
 * Intelligent component to select a screenshot for the annotation flow
 */
export default class AnnotationScreenshotSelector extends Vue {
  name: 'Annotation Screenshot Selector';

  background = null;
  showPreview = false;

  /**
   * Fires when the component gets mounted to the DOM
   */
  mounted() {
    const video = document.getElementById('screenshot-video') as any;
    video.srcObject = pexipStore.__GETTERselectedVideo;
    video.play();
  }

  /**
   * Takes the screenshot and shows the preview
   */
  takeScreenshot() {
    const video = document.getElementById('screenshot-video') as any;
    const tempCanvas = document.createElement('canvas');
    const context = tempCanvas.getContext('2d');
    tempCanvas.width = video.videoWidth;
    tempCanvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    this.background = tempCanvas.toDataURL('image/png');
    this.showPreview = true;
  }

  /**
   * Accepts the current screenshot and continues the annotation flow
   */
  acceptScreenshot() {
    pexipStore.setAnnotationUrl(this.background);
  }

  /**
   * Resets the state so that the screenshot can be retaken
   */
  retakeScreenshot() {
    this.showPreview = false;
    this.background = null;
  }

  /**
   * Cancels the annotation flow
   */
  cancelAnnotation() {
    pexipStore.cancelAnnotation();
  }

  /**
   * Getter to determine text to show the user for prompt
   */
  get textToShow() {
    if (this.showPreview) {
      return 'Are you happy with this image?';
    }
    return 'Press the button to choose an image to annotate';
  }

  /**
   * Getter for the background image from the local state
   */
  get backgroundImage() {
    return this.background;
  }
}
