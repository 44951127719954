
import Component from 'vue-class-component';
import {PropSync} from 'vue-property-decorator';
import Vue from 'vue';

@Component
/**
 * Perfectly generic logo image rendering component
 */
export default class Logo extends Vue {
  name: 'Logo'

  @PropSync('logo', {type: String, default: null}) readonly __PROPlogo: string
}
