<template>
  <div
    class="firefox-header-container"
  >
    <span
      class="logo-container"
    >
      <img src="@/assets/img/firefox_logo.png">
    </span>
    <div
      class="text-container"
    >
      <p>
        <strong>Please note:</strong> SimplyVideo does not support Picture-in-Picture mode and its use should be avoided.
      </p>
    </div>
    <div
      class="button-container"
    >
      <button
        type="submit"
        class="btn btn-sm btn-block btn-primary"
        @click.prevent="hide"
      >
        Do not show again
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FirefoxHeader',
  methods: {
    hide() {
      localStorage.setItem('showHeader', false);
      this.$emit('hide');
    },
  },
};
</script>

<style lang="scss" scoped>
  .firefox-header-container {
    align-items: center;
    background-color: #e6e6e6;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 30px;
    position: absolute;
    width: 100%;
    z-index: 9999;

    .logo-container {
      img {
        height: auto;
        width: 200px;
      }
    }

    .text-container {
      font-size: 18px;
      text-align: center;

      p {
        margin: 0 20px;
      }
    }

    .button-container {
      button {
        white-space: nowrap;
      }
    }
  }
</style>
