
import Vue from 'vue';
import Component from 'vue-class-component';
import {PropSync} from 'vue-property-decorator';
import LinkPreVue from '@/components_v2/generic/call/__COMP_LinkPreVue.vue';

@Component({
  components: {
    LinkPreVue,
  },
})
/**
 * Generic component to display a chat message
 */
export default class LinkPreview extends Vue {
  name: 'Link Preview'

  @PropSync('linkUrl', {type: String, default: '', required: true}) readonly __PROPlinkURL: string
}
