
import Vue from 'vue';
import Component from 'vue-class-component';
import {PropSync, Watch} from 'vue-property-decorator';

@Component
/**
 *
 */
export default class LinkPreVue extends Vue {
  name: 'Link Pre Vue';

  response = null;
  validUrl = false;
  apiUrl = 'https://urlpreviewapi.simplyvideo.net/';

  @PropSync('url', {type: String, default: '', required: true}) readonly __PROPurl: string;
  @PropSync('cardWidth', {type: String, default: '400px'}) readonly __PROPcardWidth: string;
  @PropSync('showButton', {type: Boolean, default: true}) readonly __PROPshowButton: boolean;

  /**
   * Watch the url and update the preview if it changes
   */
  @Watch('__PROPurl')
  updateURL(): void {
    this.response = null;
    this.getLinkPreview();
  }

  /**
   * Fired when the componet gets rendered
   */
  created() {
    this.getLinkPreview();
  }

  /**
   * Open link in new tab
   */
  viewMore() {
    const win = window.open(this.__PROPurl, '_blank');
    win.focus();
  }

  /**
   * Method to check if URL is valid
   * @param {string} url
   * @return {string}
   */
  isValidUrl(url: string) {
    const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
    this.validUrl = regex.test(url);
    return this.validUrl;
  }

  /**
   * Get the link preview from the API
   */
  getLinkPreview() {
    if (this.isValidUrl(this.__PROPurl)) {
      this.httpRequest((response: any) => {
        this.response = JSON.parse(response);
      },
      () => {
        this.response = null;
        this.validUrl = false;
      });
    }
  }

  /**
   * Function that makes the request to the api
   * @param {any} success
   * @param {any} error
   */
  httpRequest(success: any, error: any) {
    const http = new XMLHttpRequest();
    const params = 'url=' + this.__PROPurl;
    http.open('POST', this.apiUrl, true);
    http.setRequestHeader(
        'Content-type',
        'application/x-www-form-urlencoded',
    );
    http.onreadystatechange = function() {
      if (http.readyState === 4 && http.status === 200) {
        success(http.responseText);
      }
      if (http.readyState === 4 && http.status === 500) {
        error();
      }
    };
    http.send(params);
  }
}
